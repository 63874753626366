import React, { Fragment, useEffect, useState } from 'react';
import '../Counselling/counselling.scss';
import { useTranslation } from 'react-i18next';
import Table from 'react-bootstrap/Table';
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import SelfTest from '../Counselling/selfTest';
import { useDispatch } from 'react-redux';
import { getReportList, getReport, resetReport } from '../../../Redux/ActionCreators/User/counsellingAction';
import { useSelector } from 'react-redux';
import { convertDate, formatAMPM, getCodes, goFullScreen, exitFullscreen, getInterest } from '../../../utils/miscellneous';
import TestReport from '../Counselling/test-report';

export default function CareerAssessment() {
    const [test, setTest] = useState(false);
    const [id, setId] = useState('');
    const [isReport, setIsReport] = useState(false);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { reportList, report, loading, testReport } = useSelector(state => state.counselling);


    useEffect(() => {
        const firstRender = async () => {

            const res = await dispatch(getReportList());
            if (res.payload?.data?.length > 0) {
                dispatch(getReport())
            }
        }
        firstRender();
    }, [])

    const viewReport = async () => {
        window.scrollTo(0, 0);
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        };
        await dispatch(resetReport());
        setId(null);
        setIsReport(true);
    }

    const getOneReports = async (id) => {
        window.scrollTo(0, 0);
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        };
        await dispatch(resetReport());
        setId(id);
        setIsReport(true);
    }



    const takeTest = () => {
        setTest(true);
        goFullScreen();
    }

    const closeFullScreen = async () => {
        await setTest(false);
        await setIsReport(true);
        await exitFullscreen();
        await dispatch(resetReport());
        await dispatch(getReport());
    }

    const back = async () => {
        await setIsReport(false);
        await dispatch(resetReport());
        await dispatch(getReport());
        await dispatch(getReportList());
    }

    return (
        <Fragment>
            {!isReport ?
                <div className='counselling-holder'>
                    {reportList?.data?.length > 0 ?
                        <div className={window.innerWidth > 767 ? 'counselling-introduction' : 'counselling-introduction flex-wrap flex-column-reverse'}>
                            <div className='introduction' style={{ marginTop: window.innerWidth > 767 ? '' : '10px', width: window.innerWidth > 767 ? '33%' : '100%' }}>
                                {window.innerWidth > 767 && <h3>{t('Your trop trait is')}  {testReport?.top3.slice(0, 1)?.map(el => el.type)}</h3>}
                                {window.innerWidth > 767 &&
                                    <p>{t('"Interests" are those activities you do for fun or enjoyment, including what you like to read, the TV shows and movies you like to watch, events you like to attend, and the kind of people you admire and like to be with')}</p>}
                                <div className='report-btns'>
                                    <button onClick={() => viewReport()} >{t('View reports')}</button>
                                    <button onClick={takeTest} >{t('Re-take assessment')}</button>
                                </div>
                            </div>
                            <div className='career-codes'>
                                {
                                    report && report.slice(0, 1)?.map((el, i) => {
                                        return (
                                            <div className='career-code' style={{ backgroundColor: el.type === 'Realistic' ? "#F1FEEB" : el.type === 'Invetigative' ? '#EBFAFE' : el.type === 'Artistic' ? "#EBF4FE" : el.type === 'Social' ? "#FFF3F3" : el.type === 'Enterprising' ? '#FFEBE1' : "#FDF8DF", }}>
                                                <div style={{ width: '60%' }}>
                                                    <h6 style={{ color: el.type === 'Realistic' ? "#38A071" : el.type === 'Invetigative' ? '#72b7d3' : el.type === 'Artistic' ? "#1d86a3" : el.type === 'Social' ? "#dc5e5f" : el.type === 'Enterprising' ? '#ee824c' : "#dec02e" }}>{el.type} - {el.value}%</h6>
                                                    <p className='mt-3'>{getInterest(el.type, 'interest')}</p>
                                                </div>
                                                <div className="progress-circular">
                                                    <CircularProgressbarWithChildren
                                                        value={el.value}
                                                        maxValue={100}
                                                        minValue="0"
                                                        styles={{
                                                            root: {
                                                                width: "100%",
                                                                fontSize: 10,
                                                                borderRadius: 150,
                                                                marginTop: 0,
                                                                background: "#fff",
                                                                verticalAlign: "middle",
                                                                position: "relative",
                                                            },
                                                            path: {
                                                                strokeLinecap: "square",
                                                                stroke: el.type === 'Realistic' ? "#38A071" : el.type === 'Invetigative' ? '#72b7d3' : el.type === 'Artistic' ? "#1d86a3" : el.type === 'Social' ? "#dc5e5f" : el.type === 'Enterprising' ? '#ee824c' : "#dec02e",
                                                                strokeWidth: 8,
                                                            },
                                                            trail: {
                                                                // Trail color
                                                                stroke: "#F2f2f3",
                                                                strokeWidth: 8,
                                                                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                                strokeLinecap: "round",
                                                            },
                                                        }}
                                                    >
                                                        <div
                                                            className="text-center"
                                                        >
                                                            <p className='text-center text-uppercase' style={{ fontWeight: 600, fontSize: 24, color: el.type === 'Realistic' ? "#38A071" : el.type === 'Invetigative' ? '#72b7d3' : el.type === 'Artistic' ? "#1d86a3" : el.type === 'Social' ? "#dc5e5f" : el.type === 'Enterprising' ? '#ee824c' : "#dec02e", marginBottom: 0 }}>
                                                                {el.type.split('')[0]}
                                                            </p>
                                                        </div>
                                                    </CircularProgressbarWithChildren>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            {window.innerWidth < 767 && <h3 className='mb-3'>{t('Your trop trait is')}  {testReport?.top3.slice(0, 1)?.map(el => el.type)}</h3>}

                        </div> :
                        <div className='counselling-introduction'>
                            <div className='introduction'>
                                <h3>{t('Explore career paths with our comprehensive assessment test!')}</h3>
                                {window.innerWidth > 767 &&
                                    <p>{t('Uncover hidden talents, identify suitable career paths, and gain valuable insights to shape your future. Let the assessment be your compass on the road to a fulfilling and successful career. Start the adventure today!')}</p>}
                                <div className='counselling-btn'>
                                    {window.innerWidth > 767 &&
                                        <button onClick={takeTest} >{t('Take a career assessment test')}</button>}

                                </div>
                            </div>
                            <div className='counselling-img' >
                                {window.innerWidth > 767 ?
                                    <img src='/images/career-assesment-img.png' width='398px' alt='' /> :
                                    <img src='/images/coun-img4.svg' alt='' />
                                }
                            </div>
                        </div>
                    }
                    <div className='how-to-works'>
                        <h4>{t('How Does it works ?')}</h4>
                        <div className={window.innerWidth > 767 ? 'row' : 'row flex-nowrap overflow-auto'}>
                            <div className='col-md-4'>
                                <img src='/images/assess-img1.png' alt='' />
                                <h5>{t('Take The Assessment')}</h5>
                                <p>{t('Tests only take 20 mins to complete')}</p>
                            </div>
                            <div className='col-md-4'>
                                <img src='/images/assess-img2.png' alt='' />
                                <h5>{t('Answer questions')}</h5>
                                <p>{t('Empower your professional journey by booking a personalized career counseling session')}</p>
                            </div>
                            <div className='col-md-4'>
                                <img src='/images/assess-img3.png' alt='' />
                                <h5>{t('Receive Instant Results')}</h5>
                                <p>{t('100% digital, confidential, and science-based custom report')}</p>
                            </div>
                        </div>
                    </div>

                    <div className='why-counselling'>
                        {window.innerWidth > 767 &&
                            <div className='counselling-img' >
                                <img src='/images/coun-img5.png' alt='' width={445} height={305} />
                            </div>}
                        <div className='introduction'>
                            <p>{t('Reasons to Consider Taking a Career Assessment')}</p>
                            <p style={{ color: '#272727', fontWeight: 400, lineHeight: 1.5 }}>{t(`As you move beyond high school and into the next chapter of your life, you'll encounter a series of complex and highly significant decisions to navigate.`)} </p>
                            <div className='counselling-points'>
                                <div className='d-flex align-items-center'>
                                    <img src='/images/tick.svg' alt='' />
                                    <h6>
                                        {t('Gain self-awareness')}
                                    </h6>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <img src='/images/tick.svg' alt='' />
                                    <h6>
                                        {t('Discover new career options')}
                                    </h6>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <img src='/images/tick.svg' alt='' />
                                    <h6>
                                        {t('Narrow down your options')}
                                    </h6>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <img src='/images/tick.svg' alt='' />
                                    <h6>
                                        {t('Develop a personalized action plan')}
                                    </h6>
                                </div>
                            </div>
                        </div>

                    </div>

                    {reportList && reportList?.data?.length > 0 ?
                        <div className='report-table'>
                            <h3>Reports</h3>
                            <Table responsive>
                                <thead>
                                    <th>Text taken on</th>
                                    <th>Time</th>
                                    <th>Code</th>
                                    <th></th>
                                </thead>
                                <tbody className="table-body">
                                    {
                                        reportList && reportList?.data?.map(el => {
                                            return (
                                                <tr>
                                                    <td>{convertDate(el.createdAt)}</td>
                                                    <td>{formatAMPM(el.createdAt)}</td>
                                                    <td style={{ textTransform: 'capitalize', }}>{getCodes(el.riasec)}</td>
                                                    <td >
                                                        <p style={{ cursor: 'pointer', color: '#333269' }} onClick={() => { getOneReports(el._id) }}>View Detailed report</p></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                        :
                        null
                    }
                    <div className="self-assessment-test-view" style={{ zIndex: window.innerWidth > 767 ? 8 : 9, display: test ? "block" : "none", top: 0 }}>
                        <SelfTest
                            test={test}
                            closeFullScreen={closeFullScreen}
                        />
                    </div>
                </div> :
                <TestReport
                    loading={loading}
                    id={id}
                    back={back}
                />
            }
        </Fragment>
    )
}
