/* eslint-disable no-unused-vars */
import { deleteApiCall, getApiCall, postApiCall, putApiCall } from '../../../utils/request';
import {
    GET_SCHOOLS, GET_STATE_DATA, GET_SCORE_TREND, GET_LOGIN_TREND, GET_STUDENT_DATA
} from '../../Types/types';
import { returnErrors } from '../Common/errorAction';

export const getSchool = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`superadmin/state/schools?state_id=${id}`);
        return dispatch({ type: GET_SCHOOLS, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const getStateData = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`superadmin/report/state/engagement?state_id=${id}`);
        return dispatch({ type: GET_STATE_DATA, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const getStateScoreTrend = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`superadmin/report/state/engagement/graph?state_id=${id}`);
        return dispatch({ type: GET_SCORE_TREND, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const getStateLoginTrend = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`superadmin/report/state/login/graph?state_id=${id}`);
        return dispatch({ type: GET_LOGIN_TREND, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const getStudentDetails = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`superadmin/report/school/students?school_id=${id}`);
        return dispatch({ type: GET_STUDENT_DATA, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};