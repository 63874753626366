import {
    GET_SCHOOLS, GET_STATE_DATA, GET_SCORE_TREND, GET_LOGIN_TREND, GET_STUDENT_DATA
} from "../../Types/types";


let intialState = {
    schools: []
}


export default function reportReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_SCHOOLS:
            stateCopy.stateSchools = action.payload;
            return stateCopy;
        case GET_STATE_DATA:
            stateCopy.stateData = action.payload.map(el => [{ name: el.name, total_students: el.total_students, total_time: el.total_time, average_time: el.average_time, active_students: el.active_students, _id: el._id }][0]);
            return stateCopy;
        case GET_SCORE_TREND:
            stateCopy.scoreTrend = action.payload;
            return stateCopy;
        case GET_LOGIN_TREND:
            stateCopy.loginTrend = action.payload;
            return stateCopy;
        case GET_STUDENT_DATA:
            stateCopy.studentData = action.payload;
            return stateCopy;
        default:
            return stateCopy;
    }
}

