import { deleteApiCall, getApiCall, postApiCall, putApiCall } from '../../../utils/request';
import { returnErrors } from '../Common/errorAction';
import {
    GET_COUN_QUESTION, GET_ONE_QUESTION, SELECT_COUN_ANSWER, ASSESMENT_SUBMIT, GET_INTERESTS, SUBMIT_ANSWER,
    GET_REPORT_LIST, GET_REPORT, GET_ONE_REPORT, RESET_REPORT,
    RESET_LIST_LOADER, GET_SLOT_LIST, ADD_SLOTS, EDIT_SLOTS, CHANGE_SLOT_STATUS, DELETE_SLOT, GET_ALL_BOOKINGS, GET_BOOKINGS, GET_BOOKING_SLOTS, BOOK_SLOTS
} from '../../../Redux/Types/types';

export const getQuestions = () => async (dispatch) => {
    try {
        const response = await getApiCall('student/counselling/questions');
        return dispatch({ type: GET_COUN_QUESTION, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getQuestion = (ques) => async (dispatch) => {
    try {

        return dispatch({ type: GET_ONE_QUESTION, payload: ques });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const selectAnswer = (id, qid) => async (dispatch) => {
    try {

        return dispatch({ type: SELECT_COUN_ANSWER, payload: { id, qid } });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const assessmentSubmit = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall('student/counselling/submit', obj);
        return dispatch({ type: ASSESMENT_SUBMIT, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getInterests = (id, edu) => async (dispatch) => {
    try {
        const response = await getApiCall(`student/counselling/interests?report_id=${id}&education=${edu}`);
        return dispatch({ type: GET_INTERESTS, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const submitAnswer = (id, edu, interest) => async (dispatch) => {
    try {
        const response = await getApiCall(`student/counselling/job?report_id=${id}&education=${edu}&interest=${interest}`);
        return dispatch({ type: SUBMIT_ANSWER, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

//career assessment

export const getReportList = () => async (dispatch) => {
    try {
        const response = await getApiCall('student/counselling/report/list');
        return dispatch({ type: GET_REPORT_LIST, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getReport = () => async (dispatch) => {
    try {
        const response = await getApiCall('student/counselling/report');

        return dispatch({ type: GET_REPORT, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getOneReport = (id) => async (dispatch) => {
    try {
        dispatch({ type: GET_ONE_REPORT, payload: id });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const resetReport = () => async (dispatch) => {
    try {
        dispatch({ type: RESET_REPORT });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

//Counselling slots
export const resetListLoading = () => async (dispatch) => {
    try {
        return dispatch({ type: RESET_LIST_LOADER });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getSlotList = () => async (dispatch) => {
    try {
        const response = await getApiCall('admin/counselling/slot');
        return dispatch({ type: GET_SLOT_LIST, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const addSlots = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall('admin/counselling/slot', obj);
        if (response.data.success) {
            returnErrors(dispatch, response.data.message, 200);
            return dispatch({ type: ADD_SLOTS, payload: response.data });
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const editSlots = (obj) => async (dispatch) => {
    try {
        const response = await putApiCall('admin/counselling/slot/session', obj);
        if (response.data.success) {
            returnErrors(dispatch, response.data.message, 200);
            return dispatch({ type: EDIT_SLOTS, payload: response.data });
        } else {
            returnErrors(dispatch, response.data.message, response.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const changeSlotStatus = (data) => async (dispatch) => {
    let obj = {
        slot_id: data?._id
    }
    try {
        const response = await putApiCall('admin/counselling/slot/toggle', obj);
        if (response.data.success) {
            returnErrors(dispatch, data.active ? 'Slot has been deactivated' : 'Slot has been activated', 200);
            return dispatch({ type: CHANGE_SLOT_STATUS, payload: data });
        } else {
            returnErrors(dispatch, response.data.message, response.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const confirmDeleteSlot = (obj) => async (dispatch) => {

    try {
        const response = await deleteApiCall('admin/counselling/slot', obj);
        if (response.data.success) {
            returnErrors(dispatch, 'Slot has been deleted', 200);
            return dispatch({ type: DELETE_SLOT, payload: obj?.slot_id });
        } else {
            returnErrors(dispatch, response.data.message, response.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getAllBookings = () => async (dispatch) => {
    try {
        const response = await getApiCall('admin/counselling/slot/booked');
        return dispatch({ type: GET_ALL_BOOKINGS, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

//students

export const getBookings = () => async (dispatch) => {
    try {
        const response = await getApiCall('student/counselling/slot/booked');
        return dispatch({ type: GET_BOOKINGS, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getBookingSlots = (date, month, year) => async (dispatch) => {
    try {
        const response = await getApiCall(`student/counselling/slot/availability?date=${date}&month=${month}&year=${year}`);
        return dispatch({ type: GET_BOOKING_SLOTS, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const bookSlots = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall('student/counselling/slot/book', obj);
        if (response.data.success) {
            returnErrors(dispatch, response.data.message, 200);
            return dispatch({ type: BOOK_SLOTS, payload: response.data });
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}