/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useState } from 'react'
import './account.scss'
import Table from 'react-bootstrap/Table';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import { resetLoading, getAccountDetails, getSchools, updateSchoolStatus, getStates, updateStateStatus, confirmDelete, getFacultyList, savedAccount, openAddCenter, backToMain1, getColorCodes, applyTheme } from '../../../Redux/ActionCreators/Admin/accountAction';
import { clearErrors } from '../../../Redux/ActionCreators/Common/errorAction';
import AddSchool from './addSchool';
import { TopicLoader } from '../../../Loader/loader';
import Alert from '@mui/material/Alert';
import { CompactPicker } from 'react-color';
import Switch from 'react-switch';
import ConfirmModal from '../../../utils/confirmModal';
import { getSubjectList } from '../../../Redux/ActionCreators/Admin/manageContentAction';

function Account() {
    const [activeBtn, setActivebtn] = React.useState('profile');
    const [campus, setCampus] = React.useState('');
    const [imageUrl, setImageUrl] = React.useState('/images/empty-image.svg');
    const [nameError, setNameError] = React.useState('')
    const [successMessageText, setsuccessMessageText] = React.useState('');
    const [successMsg, setSuccessMsg] = React.useState(false)
    const [image, setImage] = React.useState('')
    const [imageName, setImageName] = React.useState('')
    const [update, setUpdate] = React.useState(false)
    const [imageError, setImageError] = React.useState('')
    const [edit, setEdit] = React.useState(false)
    const [details, setDetails] = React.useState({});
    const [sidebarBgColor, setSidebarBgColor] = useState('');
    const [btnColor, setBtnColor] = useState('');
    const [textColor, setTextColor] = useState('');
    const [isConfirm, setIsConfirm] = useState(false);
    const [deleteId, setDeleteId] = useState('');

    const { accountDetails, schools, states, facultyList, isAddSchool, isListLoading } = useSelector(state => state.account)
    const dispatch = useDispatch();


    React.useEffect(() => {
        window.scrollTo(0, 0);
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        };
        dispatch(clearErrors());
        dispatch(getAccountDetails());
        dispatch(getColorCodes());
        setCampus(accountDetails?.name);
        setImage(accountDetails?.logo);
        setImageUrl(accountDetails?.logo);
        const root = document.documentElement;

        root.style.setProperty('--button-color', JSON.parse(localStorage.getItem('theme'))?.btnColor);
        root.style.setProperty('--sidebar-color', JSON.parse(localStorage.getItem('theme'))?.sidebarBgColor);
        setSidebarBgColor(JSON.parse(localStorage.getItem('theme'))?.sidebarBgColor);
        setBtnColor(JSON.parse(localStorage.getItem('theme'))?.btnColor);
        setTextColor(JSON.parse(localStorage.getItem('theme'))?.textColor);
    }, [])


    React.useEffect(() => {
        dispatch(resetLoading());
        if (activeBtn === 'school') {
            dispatch(getSchools());
        }
        else if (activeBtn === 'state') {
            dispatch(getStates());
        }
        else if (activeBtn === 'profile') {
            dispatch(getAccountDetails());
        }
        else if (activeBtn === 'faculty') {
            dispatch(getFacultyList());
        }
    }, [activeBtn])







    const changeCampusName = (val) => {
        setCampus(val)
        setNameError('')
    }

    const changeImage = (e) => {
        if (e.target.files[0].size > 2097152) {
            setSuccessMsg(true);
            setsuccessMessageText("The file size cannot exceed 2MB")
        }
        else {
            const file = e.target.files[0]
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageUrl(reader.result);
            }
            if (file) {
                reader.readAsDataURL(file);
                setImageUrl(reader.result);
                setImageName(file.name);
                setImage(file)
                setUpdate(true)
            }
            else {
                setImageUrl('');
                setImageName('');
                setUpdate(false);
            }
        }

    }

    const editCampus = async () => {
        if (campus === "") {
            setNameError('Please enter Campus name')

        } else if (image === '') {
            setImageError('Pleser upload image for logo')
        }
        else {
            if (update) {
                let obj = {
                    name: campus,
                    image: image,
                }
                await dispatch(savedAccount(obj));
                setSuccessMsg(true);
                setsuccessMessageText("Profile has been successfully updated")
            }
            else {
                let obj = {
                    name: campus,
                }
                await dispatch(savedAccount(obj));
                setSuccessMsg(true);
                setsuccessMessageText("Profile has been successfully updated")
            }
        }
    }

    const addSchool = () => {
        setEdit(false);
        dispatch(openAddCenter());
        (activeBtn === 'school' || activeBtn === 'faculty') && dispatch(getStates());
        (activeBtn === 'faculty') && dispatch(getSubjectList(1, ''));
    }

    const handleChange = (el, checked) => {
        let obj = {
            state_id: el?._id
        }
        dispatch(updateStateStatus(obj, !checked));
    }

    const handleSchoolChange = (el, checked) => {
        let obj = {
            school_id: el?._id
        }
        dispatch(updateSchoolStatus(obj, !checked));
    }

    const goToEdit = (details) => {
        setEdit(true);
        setDetails(details);
        dispatch(openAddCenter());
        activeBtn === 'school' && dispatch(getStates());
    }


    const openDelete = (detail) => {
        setIsConfirm(true);
        setDeleteId(detail._id);
    }


    const backToMain = () => {
        dispatch(backToMain1());
    }

    const snackClosed = () => {
        setSuccessMsg(false);
        setsuccessMessageText('')
    }

    const applyColor = async () => {
        const root = document.documentElement;
        root.style.setProperty('--button-color', btnColor);
        root.style.setProperty('--sidebar-color', sidebarBgColor);
        let theme = { sidebarBgColor: sidebarBgColor, btnColor: (btnColor || '#5855d6'), textColor: ('#fff') }
        await dispatch(applyTheme(theme));
        await dispatch(getColorCodes());

    }

    const resetColor = async () => {
        if (sidebarBgColor === '#333269' && btnColor === '#5855d6' && textColor === '#fff') {
            this.setState({
                successMsg: true,
                successMessageText: 'Default colors already set'
            })
        }
        else {
            setSidebarBgColor('#333269');
            setBtnColor('#5855d6');
            setTextColor('#fff');
            let theme = { sidebarBgColor: '#333269', btnColor: '#5855d6', textColor: '#fff' }
            const root = document.documentElement;
            root.style.setProperty('--button-color', btnColor);
            root.style.setProperty('--sidebar-color', sidebarBgColor);
            localStorage.setItem('theme', JSON.stringify(theme))
            await dispatch(applyTheme(theme));
            await dispatch(getColorCodes());
        }
    }


    return (
        <div className='account-module'>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={successMsg}
                onClose={snackClosed}
                autoHideDuration={4000}
            >
                <Alert onClose={snackClosed} severity="success" sx={{ width: '100%' }}>
                    {successMessageText}
                </Alert>
            </Snackbar>


            {<div className="mobile-coming">
                <div className="mobile-inner-hide">
                    <img src="/images/mobile-error.svg" alt='' />
                    <h4>Please visit the website on your Desktop/Laptop</h4>
                </div>

            </div>}
            {
                !isAddSchool ?
                    <Fragment>
                        <h3>Account</h3>
                        <div className='users-button'>
                            <button className={activeBtn === 'profile' ? "active-btn" : null} onClick={() => setActivebtn('profile')}>Profile</button>
                            {localStorage.getItem('role') === 'admin' ? null : <button className={activeBtn === 'school' ? "active-btn" : null} onClick={() => setActivebtn('school')}>Schools</button>}
                            {(localStorage.getItem('role') === 'admin' || localStorage.getItem('role').includes('state-admin')) ? null : <button className={activeBtn === 'state' ? "active-btn" : null} onClick={() => setActivebtn('state')}>States</button>}
                            <button className={activeBtn === 'faculty' ? "active-btn" : null} onClick={() => setActivebtn('faculty')}>Faculty</button>
                            <button className={activeBtn === 'color' ? "active-btn" : null} onClick={() => setActivebtn('color')}>Colors</button>
                        </div>
                        {
                            activeBtn === 'profile' ?
                                <div className='profile-holder'>
                                    <h3>Profile</h3>
                                    <p className='mt-3'>Fields marked * are mandatory</p>
                                    <div className='form-group mt-2'>
                                        <label>Campus Name*
                                        </label>
                                        <input className='form-control mt-2' value={campus} onChange={(e) => changeCampusName(e.target.value)} pattern="[A-Za-z]" />
                                        {nameError}
                                    </div>
                                    <div className='mt-3'>
                                        <label>Campus Logo*</label>
                                        <p>This is the default image. </p>
                                        <div className='d-flex align-items-center '>
                                            {
                                                image ?
                                                    <div className='program-image'>
                                                        <img src={imageUrl} alt='' width='139px' height='78px' />
                                                    </div> :
                                                    <div className='program-image'>
                                                        <img src={imageUrl} alt='' />
                                                    </div>
                                            }

                                            <div className='d-flex flex-column mt-3 position-relative'>
                                                {/* <span><img src='/images/gallery-icon.svg' width='24px' alt='' /> &nbsp;Select From Gallery</span> */}
                                                <span> <img src='/images/upload-icon.svg' alt='' /> &nbsp;Upload Image &nbsp; <small>{imageName}</small>
                                                    <input type='file' className='file-upload'
                                                        name="selectedFile" accept="image/png, image/jpeg" onChange={changeImage} />
                                                </span>
                                            </div>
                                        </div>
                                        {imageError}
                                    </div>
                                    <hr />
                                    <div className='save-btn'>
                                        <button onClick={editCampus}>Save Changes</button>
                                        {/* <button onClick={(handleModal)}>Cancel</button> */}
                                    </div>
                                </div>
                                : activeBtn === 'school' ?
                                    <div className='school-list'>
                                        <div className='users-header'>
                                            <div className='d-flex justify-content-between align-items-center' style={{ width: '63%' }}>
                                                <button onClick={addSchool}><img src='/images/plus-white.svg' alt='' /> &nbsp;&nbsp; Add School</button>
                                            </div>
                                        </div>
                                        {
                                            isListLoading ?
                                                <TopicLoader />
                                                :
                                                <div className="table-admin" style={{ marginTop: -43 }}>
                                                    <Table responsive >
                                                        <thead>
                                                            <tr className="header-table">
                                                                <th>No.
                                                                    {/* <input type='checkbox' style={{ width: 16, height: 16 }} /> */}
                                                                </th>
                                                                <th>School Name</th>
                                                                <th>Affiliation</th>
                                                                <th>City</th>
                                                                <th>State</th>
                                                                <th>Status</th>
                                                                <th>Action</th>
                                                            </tr>
                                                            <hr />
                                                        </thead>
                                                        <div className='showing-results' >
                                                            <p>Showing {schools?.length} Schools</p>
                                                        </div>
                                                        <tbody className="table-body">
                                                            {
                                                                (schools !== "" && schools !== undefined && schools?.length > 0 ?
                                                                    schools.map((el, index) => {

                                                                        return (
                                                                            <tr key={el._id} style={{ borderTop: index === 0 ? '35px solid #F7F7FA' : null }}>
                                                                                <td>{index + 1}
                                                                                    {/* <input type='checkbox' value={el._id} style={{ width: 16, height: 16 }} onChange={this.getCheckedId} /> */}
                                                                                </td>
                                                                                <td className='text-el'>{el.name}</td>
                                                                                <td>{el.affiliation?.name}</td>
                                                                                <td >{el.city}</td>
                                                                                <td className='text-el'>{el.state?.name}</td>
                                                                                <td>  <p style={{ color: '#696873' }} onClick={(e) => {
                                                                                    e.preventDefault()
                                                                                    e.stopPropagation()
                                                                                    handleSchoolChange(el, el.active)
                                                                                }}> <Switch
                                                                                        onChange={(e) => { console.log(e) }}
                                                                                        width={30}
                                                                                        height={16}
                                                                                        uncheckedIcon={false}
                                                                                        checkedIcon={false}
                                                                                        onColor='#00B24B'
                                                                                        checked={el.active} /><br />
                                                                                    {el.active ? 'Active' : 'Inactive'}</p>
                                                                                </td>
                                                                                <td className='action'>
                                                                                    <img src='/images/edit.svg' alt='' onClick={() => goToEdit(el)} />&nbsp;&nbsp;
                                                                                    {el.active ? null :
                                                                                        <img src='/images/delete.svg' alt='' onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            e.stopPropagation()
                                                                                            openDelete(el)
                                                                                        }} />}
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                    :
                                                                    <tr style={{ height: schools?.length === 0 ? 380 : '' }}>
                                                                        <td colSpan={5}>
                                                                            <div className='empty-list'>
                                                                                <img src='/images/empty.png' alt='' />
                                                                                <p>You have not added any centers yet.</p>
                                                                            </div>
                                                                        </td>
                                                                    </tr>)
                                                            }


                                                        </tbody>
                                                    </Table>

                                                </div>
                                        }
                                    </div>
                                    :
                                    activeBtn === 'state' ?
                                        <div className='school-list'>
                                            <div className='users-header'>
                                                <div className='d-flex justify-content-between align-items-center' style={{ width: '63%' }}>
                                                    <button onClick={addSchool}><img src='/images/plus-white.svg' alt='' /> &nbsp;&nbsp; Add State</button>
                                                </div>
                                            </div>
                                            {
                                                isListLoading ?
                                                    <TopicLoader />
                                                    :
                                                    <div className="table-admin" style={{ marginTop: -43 }}>
                                                        <Table responsive >
                                                            <thead>
                                                                <tr className="header-table">
                                                                    <th>No.
                                                                        {/* <input type='checkbox' style={{ width: 16, height: 16 }} /> */}
                                                                    </th>
                                                                    <th>State Name</th>
                                                                    <th>Contact Name</th>
                                                                    <th>Contact Email</th>
                                                                    <th>Status</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                                <hr />
                                                            </thead>
                                                            <div className='showing-results' >
                                                                <p>Showing {states.length} States</p>
                                                            </div>
                                                            <tbody className="table-body">
                                                                {
                                                                    (states !== "" && states !== undefined && states.length > 0 ?
                                                                        states.map((el, index) => {

                                                                            return (
                                                                                <tr key={el._id} style={{ borderTop: index === 0 ? '35px solid #F7F7FA' : null }}>
                                                                                    <td>{index + 1}
                                                                                        {/* <input type='checkbox' value={el._id} style={{ width: 16, height: 16 }} onChange={this.getCheckedId} /> */}
                                                                                    </td>
                                                                                    <td className='text-center'>{el.name}</td>
                                                                                    <td className='text-center'>{el.contact_name}</td>
                                                                                    <td className='text-center'>{el.contact_email}</td>
                                                                                    <td>  <p style={{ color: '#696873' }} onClick={(e) => {
                                                                                        e.preventDefault()
                                                                                        e.stopPropagation()
                                                                                        handleChange(el, el.active)
                                                                                    }}> <Switch
                                                                                            onChange={(e) => { console.log(e) }}
                                                                                            width={30}
                                                                                            height={16}
                                                                                            uncheckedIcon={false}
                                                                                            checkedIcon={false}
                                                                                            onColor='#00B24B'
                                                                                            checked={el.active} /><br />
                                                                                        {el.active ? 'Active' : 'Inactive'}</p>
                                                                                    </td>
                                                                                    <td className='action'>
                                                                                        <img src='/images/edit.svg' alt='' onClick={() => goToEdit(el)} />&nbsp;&nbsp;
                                                                                        {el.active ? null :
                                                                                            <img src='/images/delete.svg' alt='' onClick={(e) => {
                                                                                                e.preventDefault();
                                                                                                e.stopPropagation()
                                                                                                openDelete(el)
                                                                                            }} />}
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                        :
                                                                        <tr style={{ height: states?.length === 0 ? 380 : '' }}>
                                                                            <td colSpan={5}>
                                                                                <div className='empty-list'>
                                                                                    <img src='/images/empty.png' alt='' />
                                                                                    <p>You have not added any centers yet.</p>
                                                                                </div>
                                                                            </td>
                                                                        </tr>)
                                                                }


                                                            </tbody>
                                                        </Table>

                                                    </div>
                                            }
                                        </div> :
                                        activeBtn === 'faculty' ?
                                            <div className='school-list'>
                                                <div className='users-header'>
                                                    <div className='d-flex justify-content-between align-items-center' style={{ width: '63%' }}>
                                                        <button onClick={addSchool}><img src='/images/plus-white.svg' alt='' /> &nbsp;&nbsp; Add Faculty</button>
                                                    </div>
                                                </div>
                                                {
                                                    isListLoading ?
                                                        <TopicLoader />
                                                        :
                                                        <div className="table-admin" style={{ marginTop: -43 }}>
                                                            <Table responsive >
                                                                <thead>
                                                                    <tr className="header-table">
                                                                        <th>No.
                                                                            {/* <input type='checkbox' style={{ width: 16, height: 16 }} /> */}
                                                                        </th>
                                                                        <th>Faculty Name</th>
                                                                        <th>Email</th>
                                                                        <th>Subject</th>
                                                                        <th>Status</th>
                                                                        <th>Action</th>
                                                                    </tr>
                                                                    <hr />
                                                                </thead>
                                                                <div className='showing-results' >
                                                                    <p>Showing {facultyList?.length} Faculties</p>
                                                                </div>
                                                                <tbody className="table-body">
                                                                    {
                                                                        (facultyList !== undefined && facultyList.length > 0 ?
                                                                            facultyList.map((el, index) => {

                                                                                return (
                                                                                    <tr key={el._id} style={{ borderTop: index === 0 ? '35px solid #F7F7FA' : null }}>
                                                                                        <td>{index + 1}
                                                                                            {/* <input type='checkbox' value={el._id} style={{ width: 16, height: 16 }} onChange={this.getCheckedId} /> */}
                                                                                        </td>
                                                                                        <td className='text-center'>{el.name}</td>
                                                                                        <td className='text-center'>{el.email}</td>
                                                                                        <td className='text-center' style={{ width: '40%' }}>{el.subjects?.map(el => el).join(' , ')}</td>
                                                                                        <td>  <p style={{ color: '#696873' }} onClick={(e) => {
                                                                                            e.preventDefault()
                                                                                            e.stopPropagation()
                                                                                            handleChange(el, el.active)
                                                                                        }}> <Switch
                                                                                                onChange={(e) => { console.log(e) }}
                                                                                                width={30}
                                                                                                height={16}
                                                                                                uncheckedIcon={false}
                                                                                                checkedIcon={false}
                                                                                                onColor='#00B24B'
                                                                                                checked={el.active} /><br />
                                                                                            {el.active ? 'Active' : 'Inactive'}</p>
                                                                                        </td>
                                                                                        <td className='action'>
                                                                                            <img src='/images/edit.svg' alt='' onClick={() => goToEdit(el)} />&nbsp;&nbsp;
                                                                                            {el.active ? null :
                                                                                                <img src='/images/delete.svg' alt='' onClick={(e) => {
                                                                                                    e.preventDefault();
                                                                                                    e.stopPropagation()
                                                                                                    openDelete(el)
                                                                                                }} />}
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                            :
                                                                            <tr style={{ height: states?.length === 0 ? 380 : '' }}>
                                                                                <td colSpan={5}>
                                                                                    <div className='empty-list'>
                                                                                        <img src='/images/empty.png' alt='' />
                                                                                        <p>You have not added any centers yet.</p>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>)
                                                                    }


                                                                </tbody>
                                                            </Table>

                                                        </div>
                                                }
                                            </div> :
                                            <div className='profile-holder'>
                                                <h3>Profile</h3>
                                                <p className='mt-3'>Update Color details here</p>
                                                <div className='d-flex flex-wrap'>
                                                    <div className='mt-3'>
                                                        <label>For Header</label>
                                                        <div className='mt-2'>
                                                            <CompactPicker
                                                                color={sidebarBgColor}
                                                                onChangeComplete={(e) => setSidebarBgColor(e.hex)}
                                                                colors={['#4D4D4D', '#999999', '#F44E3B', '#FE9200', '#FCDC00', '#DBDF00', '#A4DD00', '#68CCCA', '#73D8FF', '#AEA1FF', '#FDA1FF', '#333333', '#808080', '#cccccc', '#D33115',
                                                                    '#E27300', '#FCC400', '#B0BC00', '#68BC00', '#16A5A5', '#009CE0', '#7B64FF', '#FA28FF', '#000000', '#666666',
                                                                    '#B3B3B3', '#9F0500', '#C45100', '#FB9E00', '#808900', '#194D33', '#0C797D', '#0062B1', '#653294', '#AB149E']}
                                                            />
                                                        </div>
                                                    </div>&nbsp;&nbsp;&nbsp;
                                                    {/* <div className='mt-3'>
                                            <h5>For Sidebar text</h5>
                                            <div className='mt-2'>
                                                <CompactPicker
                                                    color={textColor}
                                                    onChangeComplete={this.handleChangeTextComplete}
                                                    colors={['#999999', '#fff',
                                                        '#808080', '#cccccc',
                                                        '#666666', '#B3B3B3',]}
                                                />
                                            </div>
                                        </div> */}
                                                </div>
                                                <div className='mt-3'>
                                                    <label>For Button</label>
                                                    <div className='mt-2'>
                                                        <CompactPicker
                                                            color={btnColor}
                                                            onChangeComplete={(e) => setBtnColor(e.hex)}
                                                            colors={['#4D4D4D', '#999999', '#F44E3B', '#FE9200', '#FCDC00', '#DBDF00', '#A4DD00', '#68CCCA', '#73D8FF', '#AEA1FF', '#FDA1FF',
                                                                '#333333', '#808080', '#cccccc', '#D33115', '#E27300', '#FCC400', '#B0BC00', '#68BC00', '#16A5A5', '#009CE0', '#7B64FF',
                                                                '#FA28FF', '#000000', '#666666', '#B3B3B3', '#9F0500', '#C45100', '#FB9E00', '#808900', '#194D33', '#0C797D', '#0062B1',
                                                                '#653294', '#AB149E']}
                                                        />
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className='save-btn'>
                                                    <button onClick={applyColor}>Save Changes</button>
                                                    <button onClick={resetColor}>Reset to default</button>
                                                </div>
                                            </div>

                        }
                    </Fragment>
                    :
                    <AddSchool
                        type={activeBtn}
                        details={details}
                        backToMain={backToMain}
                        edit={edit}
                        isAddSchool={isAddSchool}
                        getSchools={() => dispatch(getSchools())}
                        getStates={() => dispatch(getStates())}
                    />
            }


            {/* ################## Confirm Delete modal ############################ */}
            <ConfirmModal
                isConfirm={isConfirm}
                confirmDelete={async () => {
                    await dispatch(confirmDelete(deleteId, activeBtn))
                    await setIsConfirm(false)
                }}
                closeModal={() => setIsConfirm(false)}
                type={activeBtn === 'school' ? 'School' : 'State'}
            />

        </div >
    )
}

export default Account;