/* eslint-disable no-unused-vars */
import { deleteApiCall, getApiCall, postApiCall, putApiCall } from '../../../utils/request';
import {
    BACK_TO_ACCOUNT, EDIT_ACCOUNT, GET_ACCOUNT_DETAILS, GET_STATE_LIST,
    ADD_STATE, UPDATE_STATE_STATUS, UPDATE_STATE, GET_SCHOOL_LIST, ADD_SCHOOL, UPDATE_SCHOOL_STATUS, UPDATE_SCHOOL, DELETE_STATE_OR_SCHOOL,
    GET_FACULTY_LIST, ADD_USER,
    IS_ADD_SCHOOL, RESET_LIST, GET_COLORS, APPLY_THEME,
} from '../../Types/types';
import { returnErrors } from '../Common/errorAction';


export const getAccountDetails = () => async (dispatch) => {
    return dispatch({ type: GET_ACCOUNT_DETAILS })
}

export const resetLoading = () => async (dispatch) => {
    return dispatch({
        type: RESET_LIST
    })
}

export const getStates = () => async (dispatch) => {
    try {
        const response = await getApiCall(`superadmin/state`);
        return dispatch({ type: GET_STATE_LIST, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};



export const addState = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`superadmin/state`, obj);
        if (response.data.success) {
            returnErrors(dispatch, 'State added', response?.status);
            return dispatch({ type: ADD_STATE, payload: response.data.data });
        } else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const updateStateStatus = (obj, checked) => async (dispatch) => {
    try {
        const response = await putApiCall(`superadmin/state/toggle`, obj);
        if (response.data.success) {
            returnErrors(dispatch, checked ? 'State activated' : 'State deactivated', response?.status);
            dispatch({ type: UPDATE_STATE_STATUS, payload: { obj, checked } });
        } else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const updateState = (obj) => async (dispatch) => {
    try {
        const response = await putApiCall(`superadmin/state`, obj);
        if (response.data.success) {
            returnErrors(dispatch, 'State updated', response?.status);
            return dispatch({ type: UPDATE_STATE, payload: response.data.data });
        } else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const getSchools = () => async (dispatch) => {
    try {
        const response = await getApiCall(`superadmin/school`);
        return dispatch({ type: GET_SCHOOL_LIST, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const addSchool = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`superadmin/school`, obj);
        if (response.data.success) {
            returnErrors(dispatch, 'School added', response?.status);
            return dispatch({ type: ADD_SCHOOL, payload: response.data.data });
        } else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const updateSchoolStatus = (obj, checked) => async (dispatch) => {
    try {
        const response = await putApiCall(`superadmin/school/toggle`, obj);
        if (response.data.success) {
            returnErrors(dispatch, checked ? 'School activated' : 'School deactivated', response?.status);
            dispatch({ type: UPDATE_SCHOOL_STATUS, payload: { obj, checked } });
        } else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};


export const updateSchool = (obj) => async (dispatch) => {
    try {
        const response = await putApiCall(`superadmin/school`, obj);
        if (response.data.success) {
            returnErrors(dispatch, 'School updated', response?.status);
            return dispatch({ type: UPDATE_SCHOOL, payload: response.data.data });
        } else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};
export const confirmDelete = (id, type) => async (dispatch) => {
    let obj = type === 'state' ? { state_id: id } : { school_id: id }
    try {
        let response
        if (type === 'state') {
            response = await deleteApiCall(`superadmin/state`, obj);
        } else {
            response = await deleteApiCall(`superadmin/school`, obj);
        }
        if (response.data.success) {
            returnErrors(dispatch, type === 'state' ? 'State deleted' : 'School Deleted', response?.status);
            dispatch({ type: DELETE_STATE_OR_SCHOOL, payload: { id, type } });
        } else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

//Faculty

export const getFacultyList = () => async (dispatch) => {
    try {
        const response = await getApiCall(`superadmin/faculty`);
        return dispatch({ type: GET_FACULTY_LIST, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const addFaculty = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`superadmin/user`, obj);
        if (response.data.success) {
            returnErrors(dispatch, 'Faculty added', response?.status);
            return dispatch({ type: ADD_USER, payload: response.data.data });
        } else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};



export const savedAccount = (obj) => async (dispatch) => {
    let data = new FormData()
    data.append("name", obj.name)
    obj.image !== undefined && data.append("image", obj.image)
    try {
        // const response = await postApiCall(`admin/account/update-campus`, data);
        // if (response.data.success) {
        dispatch({ type: EDIT_ACCOUNT });
        returnErrors(dispatch, 'Campus details updated', '200');
        // }
        // else {
        //     returnErrors(dispatch, response?.data?.message, response?.status);
        // }

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const openAddCenter = () => async (dispatch) => {
    return dispatch({ type: IS_ADD_SCHOOL })
}

export const backToMain1 = () => async (dispatch) => {
    return dispatch({ type: BACK_TO_ACCOUNT })
}





export const getColorCodes = () => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/colors`);
        dispatch({ type: GET_COLORS, payload: response.data.data.colors });

    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

export const applyTheme = (theme) => async (dispatch) => {
    try {
        const response = await putApiCall(`admin/colors`, theme);
        if (response.data.success) {
            dispatch({ type: APPLY_THEME, payload: theme })
            returnErrors(dispatch, 'Colors updated', '200');
        } else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }

}