import React, { useState, useEffect } from 'react';
import './counselling.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getQuestions, getQuestion, selectAnswer, assessmentSubmit, getInterests, submitAnswer } from '../../../Redux/ActionCreators/User/counsellingAction';
import { useTranslation } from 'react-i18next';
import { dataURLtoFile, goFullScreen } from '../../../utils/miscellneous';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';
import Webcam from "react-webcam";


const emojis = [{ id: 1, emoji: '/images/strong-dislike.png', text: 'Strongly Dislike', color: '#FC4A4A' },
{ id: 2, emoji: '/images/dislike.png', text: 'Dislike', color: '#D86B52' },
{ id: 3, emoji: '/images/not-sure.png', text: 'Not Sure', color: '#EDB325' },
{ id: 4, emoji: '/images/likes.png', text: 'Like', color: '#B9BB5E' },
{ id: 5, emoji: '/images/strong-like.png', text: 'Strongly Like', color: '#5EBB97' }
]



export default function SelfTest(props) {
    const [activePage, setActivePage] = useState('instruction');
    const [active, setActive] = useState('');
    const [isWeb, setIsWeb] = useState(false);
    const [count, setCount] = useState(0);
    const [image, setImage] = useState('');
    const [imageFile, setImageFile] = useState('');
    const [btnLoading, setBtnLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [successMessageText, setSuccessMessageText] = useState('');
    const [activeQuestion, setActiveQuestion] = useState('0');
    const [answer, setAnswer] = useState('');
    const [testId, setTestId] = useState('');
    const [interest, setInterests] = useState('');
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const webcamRef = React.useRef(null);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            const message = 'Are you sure you want to leave?';
            event.returnValue = message; // Standard for most browsers
            return 'Are you sure you want to leave?'; // For some older browsers
        };
        if (props.test) {
            // Add the event listener when the component mounts
            window.addEventListener('beforeunload', handleBeforeUnload);
        }

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        dispatch(getQuestions());
    }, [])

    useEffect(() => {
        props.test ? setIsWeb(true) : setIsWeb(false);
    }, [props.test])


    const { questions, question, interests, match } = useSelector(state => state.counselling);



    useEffect(() => {
        if (props.test) {
            document.addEventListener('contextmenu', function (e) {
                e.preventDefault();
            });

            // Prevent Ctrl+S, Ctrl+C & Ctrl+V
            document.onkeydown = function (e) {
                e = e || window.event; //Get event
                if (e.ctrlKey) {
                    var c = e.code; //Get key code
                    if (["KeyS", "KeyC", "KeyV"].includes(c)) {
                        e.preventDefault();
                        e.stopPropagation();
                        setSuccessMessage(true);
                        setSuccessMessageText('Ctrl + S, Ctrl+C, Ctrl+V not allowed');
                        // setCount(count + 1);
                    }
                }
                if (e.keyCode === 123) {
                    return false;
                }

                // disable I key
                if (e.ctrlKey && e.shiftKey && e.keyCode === 73) {
                    return false;
                }

                // disable J key
                if (e.ctrlKey && e.shiftKey && e.keyCode === 74) {
                    return false;
                }

                // disable U key
                if (e.ctrlKey && e.keyCode === 85) {
                    return false;
                }
            };

            // detect tab switching
            document.addEventListener("visibilitychange", (event) => {
                if (document.visibilityState !== "visible" && count + 1 !== questions?.length) {
                    setSuccessMessage(true);
                    setSuccessMessageText('Tab switching not allowed');
                    // setCount(count + 1);
                }
            });

            // full screen detection - chrome
            document.addEventListener("webkitfullscreenchange", function () {
                // full screen disabled
                if (!document.fullscreenElement && count + 1 !== questions?.length) {
                    setSuccessMessage(true);
                    setSuccessMessageText('Exiting Full Screen not allowed');
                    // setCount(count + 1);
                    goFullScreen();
                }
            });
        }



        // }

        return (() => {
            if (props.test) {
                document.removeEventListener("visibilitychange", (event) => {
                    if (document.visibilityState !== "visible" && count + 1 !== questions?.length) {
                        setSuccessMessage(true);
                        setSuccessMessageText('Tab switching not allowed');
                        //   setCount(count + 1);
                    }
                });
                document.removeEventListener("webkitfullscreenchange", function () {
                    // full screen disabled
                    if (!document.fullscreenElement && count + 1 !== questions?.length) {
                        setSuccessMessage(true);
                        setSuccessMessageText('Exiting Full Screen not allowed');
                        //   setCount(count + 1);
                    }
                    goFullScreen();
                });
            }
        })
    });

    const videoConstraints = {
        width: 220,
        height: 155,
        facingMode: "user"
    };

    const capture = async () => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImage(imageSrc);
        var file = dataURLtoFile(imageSrc, 'user.png');
        setImageFile(file);
        setIsWeb(false)
    }

    const changeActive = async (e, qid) => {
        setActive(e);
        await dispatch(selectAnswer(e, qid))
    }

    const getNext = (cnt) => {
        setCount(cnt + 1);
        setActive('');
        dispatch(getQuestion(questions[count + 1]));
    }


    const assessmentSubmited = async () => {
        await setBtnLoading(true);
        const answer = questions.filter(el => el.score !== null).map(el => [{ _id: el._id, type: el.type, score: el.score }][0]);
        const data = new FormData();
        data.append('answers', JSON.stringify(answer));
        data.append('image', imageFile);
        const res = await dispatch(assessmentSubmit(data));
        if (res?.type === 'ASSESMENT_SUBMIT') {
            setIsWeb(false)
            setTestId(res?.payload?._id);
            setActivePage('complete')
        }
        setTimeout(() => {
            setBtnLoading(false);
        }, 1000);
    }

    const getNextQues = async (e) => {
        setActive('complete')
        await setActiveQuestion(e);
        e === '2' && await dispatch(getInterests(testId, answer));
    }

    const selectInterest = (e) => {
        setInterests(e.interest);
        if (!match) {
            setAnswer(e.education);
        }
    }

    const submit = async () => {
        const res = await dispatch(submitAnswer(testId, answer, interest))
        if (res?.type === 'SUBMIT_ANSWER') {
            await props.closeFullScreen();
        }
    }

    const snackClosed = () => {
        setSuccessMessage(false);
        setSuccessMessageText('');
    }




    return (
        <div style={{ background: '#fff', marginTop: 100 }} >
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
                open={successMessage}
                onClose={() => snackClosed()}
                autoHideDuration={4000}
            >
                <Alert onClose={snackClosed} severity="error" sx={{ width: '100%' }}>
                    {successMessageText}
                </Alert>
            </Snackbar>

            {activePage === 'instruction' ?
                <div class="container" style={{ width: window.innerWidth > 767 ? '80%' : "" }}>
                    <div class="instruction-holder">
                        <div className={window.innerWidth > 767 ? 'col-md-6' : "col-md-6 mt-3"}>
                            <h3>Hello <strong>{localStorage.getItem('name')}</strong>,</h3>
                            <p className='mt-2'>Welcome to career assessment test!</p>
                            <p className='mt-3'>Completing it will give you a chance to uncover hidden talents, identify suitable career paths, and gain valuable insights to shape your future
                                <br />
                                <br />

                                Good luck!
                            </p>
                            <div className='mt-3'>
                                {
                                    image ?
                                        <div>
                                            <img src={image} alt='' width={window.innerWidth > 767 ? 380 : 310} height={window.innerWidth > 767 ? 280 : 250} />
                                        </div> :
                                        <div >
                                            {isWeb ?
                                                <Webcam
                                                    audio={false}
                                                    height={window.innerWidth > 767 ? 280 : 190}
                                                    ref={webcamRef}
                                                    screenshotFormat="image/jpeg"
                                                    width={window.innerWidth > 767 ? 380 : 250}
                                                    videoConstraints={videoConstraints}
                                                /> :
                                                null
                                            }
                                            <br />
                                            <button className='capture-btn' onClick={capture}>Capture</button>
                                        </div>
                                }

                                {window.innerWidth < 767 &&
                                    <div className='mt-3'>
                                        <button disabled={image === ''} onClick={() => setActivePage('test')} className='form-control'>Start test</button>
                                    </div>}
                            </div>
                        </div>
                        <div className='col-md-5 offset-md-1'>
                            <h3 style={{ fontWeight: 700 }}>A few things before your start</h3>
                            <ul>
                                <li>The Career assessment test has questions about work activities and occupations that some people do. </li>
                                <li>Read each question carefully and decide how you would feel about doing each type of activity and occupation.<br />
                                    <img src='/images/instruction-img.png' alt='' />
                                </li>
                                <li>Try not to think about how much money you would make doing the specific work activity or occupation</li>
                            </ul>
                            {window.innerWidth > 767 &&
                                <div className='d-flex justify-content-end mt-5'>
                                    <button disabled={image === ''} onClick={() => setActivePage('test')}>Start test</button>
                                </div>
                            }
                        </div>


                    </div>
                </div> :
                activePage === 'complete' ?
                    (activeQuestion === '0' ?
                        <div class="container" style={{ width: window.innerWidth > 767 ? '50%' : "100%" }}>
                            <div className='d-flex justify-content-center'>
                                <div className='ques-header'>
                                    Almost done 🎯
                                </div>

                            </div>
                            <div className='question-div'>
                                <h4 className='ps-5 pe-5'>We have just a few more questions left to personalize your report.</h4>

                            </div>
                            <div class="container mt-4">
                                <div class="row justify-content-center">
                                    <div class="col-md-12">
                                        <div className='action-btns d-flex justify-content-between align-items-center'>
                                            {
                                                <button style={{ visibility: 'hidden' }}>{t('Prev')}</button>
                                            }

                                            <button disabled={activeQuestion === ''} onClick={() => {
                                                getNextQues('1')
                                            }
                                            } >{t('Next')}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> :
                        activeQuestion === '1' ?
                            <div class="container" style={{ width: window.innerWidth > 767 ? '50%' : "100%" }}>
                                <div className='d-flex justify-content-center'>
                                    <div className='ques-header'>
                                        Almost done 🎯
                                    </div>

                                </div>
                                <div className='question-div'>

                                    <h4 className='mt-5 ps-5 pe-5'>What is the highest level of education,
                                        you want to pursue?</h4>
                                    <div class="container">
                                        <div class="row justify-content-center align-items-center">
                                            <div class="col-md-9">
                                                <ul className='options'>
                                                    <li onClick={() => setAnswer('10th')} className={answer === '10th' ? 'col-md-7 col-7 option ans-color' : 'option col-md-7 col-7'}>10th</li>
                                                    <li onClick={() => setAnswer('12th')} className={answer === '12th' ? 'col-md-7 col-7 option ans-color' : 'option col-md-7 col-7'}>12th</li>
                                                    <li onClick={() => setAnswer('graduation')} className={answer === 'graduation' ? 'col-md-7 col-7 option ans-color' : 'option col-md-7 col-7'}>Graduation</li>
                                                    <li onClick={() => setAnswer('post-graduation')} className={answer === 'post-graduation' ? 'col-md-7 col-7 option ans-color' : 'option col-md-7 col-7'}>Post Graduation</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="container mt-4">
                                    <div class="row justify-content-center">
                                        <div class="col-md-12">
                                            <div className='action-btns d-flex justify-content-between align-items-center'>
                                                {
                                                    <button style={{ visibility: 'hidden' }}>{t('Prev')}</button>
                                                }
                                                <button disabled={activeQuestion === ''} onClick={() => {
                                                    getNextQues('2')
                                                }
                                                } >{t('Next')}</button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> :
                            <div class="container" style={{ width: window.innerWidth > 767 ? '50%' : "100%" }}>
                                <div className='d-flex justify-content-center'>
                                    <div className='ques-header'>
                                        Almost done 🎯
                                    </div>

                                </div>
                                <div className='question-div'>
                                    <h4 className='mt-5'>What are your preffered interest areas?</h4>
                                    <div class="container">
                                        <div class="row justify-content-center align-items-center">
                                            <div class="col-md-8">
                                                <div className='question-options mt-3 d-flex flex-wrap' >
                                                    {
                                                        interests && interests?.map((el, i) => {
                                                            return (
                                                                <div className={interest === el.interest ? 'skill active-opt' : 'skill'} onClick={() => selectInterest(el)}>
                                                                    <p>{el.interest}</p>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="container mt-4">
                                    <div class="row justify-content-center">
                                        <div class="col-md-12">
                                            <div className='action-btns d-flex justify-content-between align-items-center'>
                                                {
                                                    <button style={{ visibility: 'hidden' }} >{t('Prev')}</button>
                                                }
                                                {
                                                    <button disabled={active === ''} onClick={() => {
                                                        submit()
                                                    }
                                                    } >{t('Submit')}</button>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    )
                    :

                    <div class="container" style={{ width: window.innerWidth > 767 ? '50%' : "100%" }}>
                        <div className='d-flex justify-content-center'>
                            <div className='ques-header'>
                                Question {count + 1}/{questions?.length}
                            </div>

                        </div>
                        <div className='question-div'>
                            <h5>{question.occupation ?
                                'Choose the option that best describes how much the below occupation interests or appeals to you.' :
                                'Choose the option that best describes how much you would enjoy the below activity.'
                            }</h5>
                            <h4>{question?.question}</h4>
                            <div class="container">
                                <div class="row justify-content-center align-items-center">
                                    <div class="col-md-7">
                                        <div className='question-options' style={{ overflow: 'unset' }}>
                                            {
                                                emojis && emojis?.map((el, i) => {
                                                    return (
                                                        <div className={active === el.id ? 'question-option active' : 'question-option'} onClick={() => changeActive(el.id, question?._id)}>
                                                            <img src={el.emoji} alt='' />
                                                            <div className='circle' style={{ background: el.color, opacity: active === el.id ? 1 : 0.2 }}></div>
                                                            <p style={{ fontWeight: active === el.id ? 700 : 400 }}>{el.text}</p>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="container mt-4">
                            <div class="row justify-content-center">
                                <div class="col-md-12">
                                    <div className='action-btns d-flex justify-content-between align-items-center'>
                                        {
                                            <button style={{ visibility: 'hidden' }}>{t('Prev')}</button>
                                        }
                                        {count + 1 === questions?.length ? null :
                                            <button disabled={active === ''} onClick={() => {
                                                getNext(count)
                                            }
                                            } >{t('Next')}</button>
                                        }
                                        {count + 1 === questions?.length ?
                                            <button disabled={btnLoading} onClick={() => assessmentSubmited()} >{t('Submit')}</button>
                                            : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }

        </div >
    )
}
