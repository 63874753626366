/* eslint-disable no-unused-vars */
import { returnErrors } from '../Common/errorAction';
import { getApiCall, postApiCall } from '../../../utils/request';
import {
    GET_STUDENT_CLASSROOM, GET_DEFAULT_SUBJECTS, GET_SUBJECTS, GET_OTHER_SUBJECTS, GET_SUBJECT_CHAPTERS, GET_STUDENT_SUBJECT_BRIEF, GET_CHAPTER_TOPICS, RESET_TOPIC_LOADING,
    RESET_SUBJECT, GET_TOPIC_RESOURCES, GET_INDIVIDUAL_RESOURCE, LIKE_RESOURCE, GET_NOTE, SAVE_NOTE,
    GET_QUIZ_DATA, GET_QUESTION, GET_ANSWER, QUIZ_SUBMIT, CLOSE_QUIZ, RESET_RESOURCE, ANALYTIC_CAPTURE, BACK_TO_TOPIC,


    GET_ACTIVE_SEM, GET_OTHER_PROGRAMS, GET_SEMESTERS, GET_STUDENT_DETAILS,

} from '../../Types/types';


export const getStudentClassrooms = () => async (dispatch) => {
    try {
        const response = await getApiCall('student/learning/classrooms');
        return dispatch({ type: GET_STUDENT_CLASSROOM, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getDefaultSubjects = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`student/learning/classrooms/subjects?classroom_id=${id}`);
        return dispatch({ type: GET_DEFAULT_SUBJECTS, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getSubjects = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`student/learning/classrooms/subjects?classroom_id=${id}`);
        return dispatch({ type: GET_SUBJECTS, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getOtherSubjects = () => async (dispatch) => {
    try {
        const response = await getApiCall(`student/learning/classrooms/other/subjects`);

        return dispatch({ type: GET_OTHER_SUBJECTS, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getSubjectChapters = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`student/learning/classrooms/subjects/chapters?subject_id=${id}`);
        dispatch({ type: GET_SUBJECT_CHAPTERS, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getChapterTopics = (id, subid) => async (dispatch) => {
    try {
        const response = await getApiCall(`student/learning/classrooms/subjects/chapters/topics?subject_id=${subid}&chapter_id=${id}`);
        return dispatch({ type: GET_CHAPTER_TOPICS, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const resetTopicLoading = () => {
    return {
        type: RESET_TOPIC_LOADING
    };
}

export const getSubjectBrief = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`student/learning/classrooms/subject/brief?subject_id=${id}`);
        dispatch({ type: GET_STUDENT_SUBJECT_BRIEF, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}


export const getTopicResources = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`student/learning/classrooms/subjects/chapters/resources?chapter_id=${id}`);
        const data = response.data.data.sort((a, b) => { return a.index - b.index })
        return dispatch({ type: GET_TOPIC_RESOURCES, payload: { data, id } });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}


export const getResource = (data) => async (dispatch) => {
    try {
        const response = await getApiCall(`student/learning/resource?resource_id=${data?._id}&type=${data?.type}`);
        const res = data.type === 'Quiz' ? response.data.data[0] : response.data.data
        const obj = { ...data, ...res }
        return dispatch({ type: GET_INDIVIDUAL_RESOURCE, payload: obj });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const resetIndex = () => async () => {
    return {
        type: RESET_RESOURCE
    };
}


export const likeResource = (obj) => async (dispatch) => {
    try {
        await postApiCall('student/learning/resource/like', obj);
        returnErrors(dispatch, obj.like ? `${obj.type} has been liked` : `${obj.type} has been unliked`, 200);
        dispatch({ type: LIKE_RESOURCE, payload: obj });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}
export const getNote = (id) => async (dispatch) => {

    try {
        const response = await getApiCall(`student/learning/resource/note?resource_id=${id}`);
        dispatch({ type: GET_NOTE, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const savedNote = (save) => async (dispatch) => {

    try {
        await postApiCall('student/learning/resource/note', save);
        dispatch({ type: SAVE_NOTE, payload: save });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getQuizdata = (data) => async (dispatch) => {
    try {
        const response = await getApiCall(`student/learning/classrooms/test/questions?_id=${data?._id}`);
        const res = response.data.data;
        const obj = { ...data, questions: res }
        dispatch({ type: GET_QUIZ_DATA, payload: obj });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}


export const getQuestion = (ques) => async (dispatch) => {
    return dispatch({
        type: GET_QUESTION,
        payload: ques
    });
}


export const selectAnswer = (qid, aid) => async (dispatch) => {
    const answer = {
        question_id: qid,
        answer_id: aid
    }
    try {
        const response = await postApiCall('student/learning/classrooms/test/question', answer);
        const data = response.data;
        dispatch({ type: GET_ANSWER, payload: { data, aid, qid } });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const quizSubmitted = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall('student/learning/classrooms/test', obj);
        dispatch({ type: QUIZ_SUBMIT, payload: response.data.correct });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }

}

export const closeQuiz = () => async (dispatch) => {
    dispatch({ type: CLOSE_QUIZ })
}


export const viewAnalytic = (analytic) => async (dispatch) => {
    try {
        const response = await postApiCall('student/learning/analytic', analytic);
        dispatch({ type: ANALYTIC_CAPTURE, payload: { ...response.data.data, ...analytic } });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }

}

export const clearindex = () => {
    return {
        type: 'Reset_index'
    };

}

export const resetSubjects = () => {
    return {
        type: RESET_SUBJECT
    };
}

export const backToTopic = () => {
    return {
        type: BACK_TO_TOPIC
    };
}



export const reTake = (_id) => async (dispatch) => {
    const retakeId = {
        _id
    }
    try {
        const response = await postApiCall('campus/resource/quiz/retake', retakeId);
        dispatch({ type: "Retake_Quiz", payload: _id });
    } catch (error) {
        returnErrors(dispatch, error.response !== undefined && error.response.data.message, error.response !== undefined && error.response.status);

    }
}


export const handleClose = () => {
    return {
        type: 'Close_Modal'
    };
}





















export const getStudentDetails = () => async (dispatch) => {
    try {
        // const response = await getApiCall('student/dashboard/list');
        return dispatch({ type: GET_STUDENT_DETAILS });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getSemsesters = (sem, id) => async (dispatch) => {
    try {
        // const response = await getApiCall(`student/my-classroom/sem-subjects?semester=${sem}&_id=${id}`);
        // const data = response.data.data
        dispatch({ type: GET_SEMESTERS });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}


export const getActiveSem = (num, sem, id) => async (dispatch) => {
    try {
        // const response = await getApiCall(`student/my-classroom/sem-subjects?semester=${num}&_id=${id}`);
        // const data = response.data.data;
        dispatch({ type: GET_ACTIVE_SEM });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getOtherPrograms = (campus, id) => async (dispatch) => {
    try {
        // const response = await getApiCall(`student/my-classroom/other-programs?campus=${campus}&program_id=${id}`);
        dispatch({ type: GET_OTHER_PROGRAMS });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}


// export const getBubbles = (code) => async (dispatch) => {
//     try {
//         const response = await getApiCall(`student/semester/subjects/details?_id=${code}`);
//         dispatch({ type: "Get_Bubble_Details", payload: response.data.data });
//         const response1 = await getApiCall(`student/bubble/sem-subjects?_id=${code}`);
//         const data = response1.data.data
//         dispatch({ type: "Get_Bubble", payload: { data, code } });

//     } catch (error) {
//         returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
//     }

// }

// export const getTopicDetails = (id) => async (dispatch) => {
//     try {
//         const response = await getApiCall(`student/semester/subjects/bubbles/detail?_id=${id}`);
//         dispatch({ type: "Get_Topics_Details", payload: response.data.data });
//     } catch (error) {
//         returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
//     }
// }








export const getPrevQuestion = (type, id, prevId) => {
    return {
        type: 'Get_Previous',
        payload: { type, id, prevId }
    };
}



// export const reTake = (_id) => async (dispatch) => {
//     const retakeId = {
//         _id
//     }
//     try {
//         await postApiCall('student/my-classroom/resource/retake-quiz', retakeId);
//         dispatch({ type: "Retake_Quiz", payload: _id });
//     } catch (error) {
//         returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);

//     }
// }

export const resetLoading = () => async (dispatch) => {
    return dispatch({
        type: 'Reset_Count_Loader'
    });
}





export function getPrevIndex(el) {
    return {
        type: "Get_Previous_Index", payload: el
    };
}


// export const shareFeed = (data) => async (dispatch) => {
//     return dispatch({
//         type: 'Share_Popup_Open',
//         payload: data
//     })
// }

// export const shareModalClose = () => async (dispatch) => {
//     return dispatch({
//         type: 'Share_Popup_Close'
//     })
// }

// export const shareAll = (resource) => async (dispatch) => {
//     try {
//         const response = await postApiCall('notice-board/store-data', resource);
//         if (response.data.success) {
//             dispatch({ type: "Share_Done", payload: response.data });
//             returnErrors(dispatch, `${resource.resource_type} is shared`, response.status);
//         }

//     } catch (error) {
//         returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
//     }
// }

// export const getExploreSkills = (page) => async (dispatch) => {
//     try {
//         const response = await getApiCall(`skill-path/explore?page=${page}`);
//         dispatch({ type: "Get_Explore_Skills", payload: response.data.data });
//     } catch (error) {
//         returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
//     }
// }

// export const bookmarkSkill = (bookmark, id) => async (dispatch) => {
//     const obj = {
//         skill_id: id
//     }
//     try {
//         await postApiCall(`skill-path/bookmark`, obj);
//         dispatch({ type: "Skill_Toggle_Bookmarks", payload: { bookmark, id } });
//     } catch (error) {
//         returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
//     }
// }

// export const reorderContent = (result) => async (dispatch) => {
//     return dispatch({ type: "Reorder_Content", payload: result });
// };




