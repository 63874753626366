import React, { Fragment, useEffect, useState } from 'react';
import '../Manage-Topic/manageTopic.scss';
import Switch from 'react-switch';
import Modal from 'react-bootstrap/Modal';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getActiveBtn, backToTopic, showSideBar, getChapters, savedChapter, updateChapter, updateStatusChapter, confirmDeleteChapter, reorderChapter, reorderedChapter, openAddInfo, savedSubjectBrief } from '../../../../Redux/ActionCreators/Admin/manageContentAction';
import { clearErrors } from '../../../../Redux/ActionCreators/Common/errorAction';
import { TopicLoader, BallBeat } from '../../../../Loader/loader';
import Snackbar from '@mui/material/Snackbar';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '@mui/material';
import ConfirmModal from '../../../../utils/confirmModal';
import DynamicInput from '../../../../utils/dynamicInput';
import CreatableSelect from 'react-select/creatable';
import { convertToObjects, convertToObjects1, renameArrProperties } from '../../../../utils/miscellneous';
import SubjectBrief from '../../../User/My-Learning/Chapters/subjectBrief';



function ManageChapter() {
    const [successMsg, setSuccessMsg] = useState(false);
    const [successMessageText, setSuccessMessageText] = useState('');
    const [details, setDetails] = useState(null);
    const [edit, setEdit] = useState(false);
    const [isAddChapter, setIsAddChapter] = useState(false);
    const [imageUrl, setImageUrl] = useState('/images/empty-image.svg');
    const [imageName, setImageName] = useState('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState('');
    const [active, setActive] = useState('');
    const [chars_left, setCharLeft] = useState(75);
    const [isLoading, setIsLoading] = useState(false);
    const [update, setUpdate] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [subjectId, setSubjectId] = useState('');
    const [subject, setSubject] = useState('');
    const [chapterId, setChapterId] = useState('');
    const [nameError, setNameError] = useState('');
    const [descError, setDescError] = useState('');
    const [imageError, setImageError] = useState('');
    const [isConfirm, setIsConfirm] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [fields, setFields] = useState([{ key: 0, title: '', content: [''] }]);
    const [outcomes, setOutcome] = useState([{ key: 0, title: '' }]);
    const [benefits, setBenefits] = useState([{ key: 0, title: '' }]);
    const [opportunity, setOpportunity] = useState([{ key: 0, title: '' }]);
    const [skills, setskills] = useState(null);
    const [companies, setCompanies] = useState(null);
    const [avgSalary, setAvgSalary] = useState('')
    const [descriptionError, setDescriptionError] = useState('');
    const [skillError, setSkillError] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const maxChar = 75
    const error = useSelector(state => state.error);
    const { chapterList, isChapterLoading, isAddInfo, subjectBrief } = useSelector(state => state.manageContent);


    useEffect(() => {
        window.scrollTo(0, 0);

        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        };
        dispatch(clearErrors());
        dispatch(showSideBar());
        setSubject(location.state.title);
        setSubjectId(location.state.id);
        setDetails(location.state.details);
        // sub: location.state.subject,
        dispatch(getChapters(location.state.id));
        // setFields(subjectDescription?.brief?.description);
        // setOutcome(convertToObjects(subjectDescription?.brief?.outcome))

    }, [])



    const handleChange = (detail) => {
        let obj = {
            chapter_id: detail?._id,
            active: !detail.active
        }
        dispatch(updateStatusChapter(obj));
    }


    const backtoBubble = () => {
        if (location.state.subject) {
            dispatch(getActiveBtn('subject'));
        }
        navigate(-1);
    }


    const changeName = (val) => {
        const charCount = val.length;
        const charLength = maxChar - charCount;
        setName(val);
        setNameError('');
        setCharLeft(charLength);
    }

    const changeDescription = (val) => {
        setDescription(val);
        setDescError('');
    }

    const changeImage = (e) => {
        if (e.target.files[0].size > 2097152) {
            setSuccessMsg(true);
            setSuccessMessageText("The file size cannot exceed 2MB");
        } else {
            const file = e.target.files[0];
            const reader = new FileReader();

            reader.onloadend = () => {
                setImageUrl(reader.result);
            };
            if (file) {
                reader.readAsDataURL(file);
                setImageUrl(reader.result);
                setImageError('');
                setImage(file);
                setImageName(file.name);
                setUpdate(true);
            } else {
                setImageUrl('');
                setImageName('');
                setUpdate(false);
            }
        }
    }

    const addChapter = (el, val) => {
        if (el !== null) {
            setName(el.name);
            setCharLeft(maxChar - el.name?.length);
            setDescription(!el?.description ? '' : el?.description);
            setImage(el.image);
            setImageUrl(el.image);
            setChapterId(el._id);
            setActive(el.active);
        }
        else {
            setImageUrl('/images/empty-image.svg');
            setImage('');
        }
        setEdit(val);
        setIsAddChapter(true);
    }


    const saveChapter = async () => {
        if (name === "") {
            setNameError("Please enter Unit name");
        }
        else if (description === '') {
            setDescError('Please enter description');
        }
        else if (image === '') {
            setImageError('Plesee upload image for unit');
        }
        else {
            let obj = {
                name: name,
                description: description,
                subject_id: subjectId,
                image: image,
            }

            setIsLoading(true);
            const res = await dispatch(savedChapter(obj));
            if (res?.type === 'ADD_CHAPTER') {
                setIsAddChapter(false);
                setName('');
                setImage('');
                setDescription('');
                setImageName('');
                setImageError('');
                setImageUrl('/images/empty-image.svg');
                setUpdate(false);
            }
            setIsLoading(false);
        }
    }

    const editChapter = async () => {
        if (name === '') {
            setNameError("Please enter Unit name");
        }
        else if (description === '') {
            setDescError('Please enter description');
        }
        else if (image === '') {
            setImageError('Plesee upload image for unit');
        }
        else {
            let obj
            if (update) {
                obj = {
                    _id: chapterId,
                    description: description,
                    name: name,
                    image: image,
                    active: active
                }
            }
            else {
                obj = {
                    _id: chapterId,
                    name: name,
                    description: description,
                    active: active
                }
            }

            setIsLoading(true);
            const res = await dispatch(updateChapter(obj));
            if (res?.type === 'UPDATE_CHAPTER') {
                setIsAddChapter(false);
                setName('');
                setDescription('');
                setImage('');
                setImageName('');
                setImageError('');
                setImageUrl('/images/empty-image.svg');
                setUpdate(false);
            }
            setIsLoading(false);
        }
    }


    const openDelete = (detail) => {
        setIsConfirm(true);
        setDeleteId(detail._id);
    }

    const handleModal = () => {
        setIsAddChapter(false);
        setName('');
        setDescription('');
        setImage('');
        setImageUrl('');
        setImageError('');
        setNameError('');
        setDescError('');
        setIsLoading(false);
        setCharLeft(75);
    }


    const goToTopics = (id, title, el) => {
        navigate("/manage-content/topic", {
            state: { id: id, title: title, details: el, subjectId: location.state.id, subject: true },
        });
    };


    const handleDragEnd = async (result) => {
        if (!result.destination) return
        const data = await dispatch(reorderChapter(result));
        const contents = Array.from(chapterList);
        const [reorderedItem] = contents.splice(data.payload.source.index, 1);
        contents.splice(data.payload.destination.index, 0, reorderedItem);
        contents.forEach((el, i) => {
            if (el.index !== i + 1) {
                el.index = i + 1;
            }
        });
        await dispatch(reorderedChapter(contents.map(el => [{ _id: el._id, index: el.index }][0])));
    }



    const checkFocus = () => {
        // dummy element
        let dummyEl = document.getElementById('express-form-typeahead');
        // check for focus
        let isFocused = (document.activeElement === dummyEl);
        if (isFocused) {
            setIsFocused(isFocused);
        }
        else {
            setIsFocused(false);
        }
    }

    const isAdd = async () => {
        setFields([{ key: 0, title: '', content: [''] }]);
        const res = await dispatch(openAddInfo(location.state.id));

        if (Object.keys(res.payload?.brief).length) {
            setFields(convertToObjects(res.payload.brief?.description));
            setOutcome(convertToObjects1(res.payload.brief?.outcome));
            setskills(!res.payload.brief?.skills ? [] : renameArrProperties(res.payload.brief?.skills));
            setCompanies(!res.payload.brief?.hiring_companies ? [] : renameArrProperties(res.payload.brief?.hiring_companies))
            setAvgSalary(res.payload.brief.avg_salary);
            setBenefits(convertToObjects1(res.payload.brief?.benefits));
            setOpportunity(convertToObjects1(res.payload.brief?.opportunities))
        }
    }

    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const newFields = [...fields];
        newFields[index][name] = value;
        setFields(newFields);
        setDescriptionError('');
    };

    const handleContentChange = (fieldIndex, contentIndex, event) => {
        const { value } = event.target;
        const newFields = [...fields];
        newFields[fieldIndex].content[contentIndex] = value;
        setFields(newFields);
        setDescriptionError('');
    };

    const addInputField = () => {
        setFields([...fields, { key: fields.length, title: '', content: [''] }]);
    };

    const addContentField = (fieldIndex) => {
        const newFields = [...fields];
        newFields[fieldIndex].content.push('');
        setFields(newFields);
    };

    const removeInput = (idx) => {
        setFields(fields.filter((s, index) => idx !== index));
        fields.forEach(el => {
            if (idx < el.key) {
                el.key = el.key - 1
            }
        })
    };

    const removeContentInput = (idx, contentIndex) => {
        const newFields = [...fields];
        newFields[idx].content.splice(contentIndex, 1);
        setFields(newFields);
    };

    const handleField = (index, event, type) => {
        const { name, value } = event.target;
        if (type === 'outcome') {
            const newFields = [...outcomes];
            newFields[index][name] = value;
            setOutcome(newFields);
        } else if (type === 'benefit') {
            const newFields2 = [...benefits];
            newFields2[index][name] = value;
            setBenefits(newFields2);
        }
        else if (type === 'opportunity') {
            const newFields3 = [...opportunity];
            newFields3[index][name] = value;
            setOpportunity(newFields3);
        }
    };

    const addField = (type) => {
        if (type === 'outcome') {
            setOutcome([...outcomes, { key: outcomes?.length, title: '' }]);
        } else if (type === 'benefit') {
            setBenefits([...benefits, { key: benefits?.length, title: '' }]);
        } else if (type === 'opportunity') {
            setOpportunity([...opportunity, { key: opportunity?.length, title: '' }]);
        }
    };

    const removeField = (idx, type) => {
        if (type === 'outcome') {
            setOutcome(outcomes.filter((s, index) => idx !== index));
            outcomes.forEach(el => {
                if (idx < el.key) {
                    el.key = el.key - 1
                }
            })
        } else if (type === 'benefit') {
            setBenefits(benefits.filter((s, index) => idx !== index));
            benefits.forEach(el => {
                if (idx < el.key) {
                    el.key = el.key - 1
                }
            })
        } else if (type === 'opportunity') {
            setOpportunity(opportunity.filter((s, index) => idx !== index));
            opportunity.forEach(el => {
                if (idx < el.key) {
                    el.key = el.key - 1
                }
            })
        }
    };

    const selectSkills = (e) => {
        setskills(e);
        setSkillError('');
    }

    const selectCompanies = (e) => {
        setCompanies(e)
    }

    const saveSubjectBrief = async () => {
        if (fields.some(el => el.title === '')) {
            setDescriptionError('Please add title and content')
        } else if (skills === null) {
            setSkillError('Please Select skills')
        }
        else {
            let obj = {
                subject_id: location.state.id,
                description: fields.map(el => [{ title: el.title, content: el.content }][0]),
                skills: skills.map(el => [el.value][0]),
                outcome: outcomes?.map(el => [el.title][0]),
                benefits: benefits?.map(el => [el.title][0]),
                opportunities: opportunity?.map(el => [el.title][0]),
                avg_salary: avgSalary,
                hiring_companies: companies.map(el => [el.value][0]),
            }

            await dispatch(savedSubjectBrief(obj));
        }
    }



    const snackClosed = () => {
        setSuccessMsg(false);
        setSuccessMessageText('');
    }

    return (
        <div className="manage-subjects">

            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
                open={successMsg}
                autoHideDuration={3000}
                onClose={snackClosed}
            >
                <Alert onClose={snackClosed} severity="error" sx={{ width: '100%' }}>
                    {successMessageText}
                </Alert>
            </Snackbar>

            <div className='d-flex justify-content-between' style={{ fontSize: 20, cursor: 'pointer' }}>
                <div className='d-flex'>

                    <p style={{ marginTop: 6 }} onClick={() => isAddInfo ? dispatch(backToTopic()) : backtoBubble()}>
                        <img src='/images/back.svg' alt='' width='20px' />
                    </p>&nbsp;&nbsp;
                    <h1>{isAddInfo ? 'Add Info : ' + location.state.title : location.state.title}</h1>
                </div>
            </div>
            <div className='bc-nav' style={{ marginLeft: 3 }}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item" onClick={() => navigate('/manage-content')} >Manage Content</li>
                        <li className="breadcrumb-item" onClick={() => navigate(-1)} >Subjects</li>
                        <li className="breadcrumb-item b-active" >{location.state.title}</li>
                    </ol>
                </nav>
            </div>
            {!isAddInfo ?
                details && <Fragment>
                    <div className='upper-details'>
                        <div>
                            <img src={details?.image} alt='' width='176px' height='120px' />
                        </div>
                        {
                            <Fragment>
                                <div className='content-count'>
                                    {<img src='/images/topic-icon.svg' alt='' />}
                                    <p>{details?.chapters}</p>
                                    <span>Chapters</span>
                                </div>
                                <div className='content-count'>
                                    {<img src='/images/article-icon.svg' alt='' />}
                                    <p>{details?.articles}</p>
                                    <span>Articles</span>
                                </div>
                                <div className='content-count'>
                                    {<img src='/images/video-icon.svg' alt='' />}
                                    <p>{details?.videos}</p>
                                    <span>Videos</span>
                                </div>
                                <div className='content-count'>
                                    {<img src='/images/quiz-icon.svg' alt='' />}
                                    <p>{details?.quizzes}</p>
                                    <span>Quizes</span>
                                </div>
                                <div className='content-count'>
                                    {<img src='/images/document-icon.svg' alt='' />}
                                    <p>{details?.documents}</p>
                                    <span>Documents</span>
                                </div>
                            </Fragment>
                        }
                    </div>
                    <div className='subject-header'>
                        <div className='d-flex'>
                            <button onClick={() => addChapter(null, false)}><img src='/images/plus-white.svg' alt='' /> &nbsp;&nbsp;&nbsp; Add Chapter</button>
                            {localStorage.getItem('role') === 'state-admin' ? null :
                                <button className='schedule' onClick={() => isAdd()}>Add Subject Info</button>
                            }
                        </div>

                        <div class="animated-search d-none" >
                            <input id="express-form-typeahead" autoComplete="off" onBlur={checkFocus} onFocus={checkFocus} spellCheck="false" type="text" />
                            <div class="symbol" >
                                <img src='/images/search1.svg' alt='' />
                            </div>
                            {
                                isFocused ?
                                    <img src='/images/close2.png' alt='' className='close-input' onClick={() => setIsFocused(false)} />
                                    : ''
                            }
                        </div>
                        <div className='content-heading'>
                            <ul>
                                <li>
                                    <div>No.</div>
                                    <div>Chapter</div>
                                    <div>Units</div>
                                    <div>Items</div>
                                    <div>Status</div>
                                    <div>Actions</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {
                        <DragDropContext onDragEnd={handleDragEnd}>
                            <Droppable droppableId={subjectId}>
                                {
                                    (provided) => (
                                        <ul className='subject-list' {...provided.droppableProps} ref={provided.innerRef}>
                                            {isChapterLoading ?
                                                <div>
                                                    <TopicLoader />
                                                </div> :
                                                chapterList && chapterList?.length > 0 ?
                                                    chapterList.map((el, index) => {
                                                        return (
                                                            <Draggable key={el._id} draggableId={el._id} index={index} >
                                                                {
                                                                    (provided) => (
                                                                        <li {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} onClick={() => goToTopics(el._id, el.name, el)}>
                                                                            <div className='subject-num' onClick={(e) => {
                                                                                e.preventDefault()
                                                                                e.stopPropagation()
                                                                            }} >
                                                                                <img src='/images/drag.svg' alt='' />
                                                                            </div>
                                                                            <div className='subject-name'>
                                                                                {/* <div style={{ width: '56px' }}>
                                                                                        <img src={el.image} className='rounded' alt='' width='56px' height='32px' />
                                                                                    </div> &nbsp;&nbsp; */}
                                                                                <p style={{ width: '100%' }}>{el.name}</p>
                                                                            </div>
                                                                            <div className='text-center' >
                                                                                <h3>{el?.topics || 0}</h3>
                                                                            </div>
                                                                            <div className='text-center'>
                                                                                <h3>{(el.articles + el.videos + el.quizzes + el.documents) || 0}</h3>
                                                                            </div>
                                                                            {localStorage.getItem('role') === 'sme' ?
                                                                                <div className="text-center">
                                                                                    <p> {el.active ? "Active" : "Inactive"}</p>
                                                                                </div>
                                                                                :
                                                                                <div className='text-center' >
                                                                                    <p onClick={(e) => {
                                                                                        e.preventDefault()
                                                                                        e.stopPropagation()
                                                                                        handleChange(el)
                                                                                    }}> <Switch
                                                                                            onChange={(e) => { console.log(e) }}
                                                                                            width={30}
                                                                                            height={16}
                                                                                            uncheckedIcon={false}
                                                                                            checkedIcon={false}
                                                                                            onColor='#00B24B'
                                                                                            checked={el.active} /><br />
                                                                                        {el.active ? 'Active' : 'Inactive'}</p>
                                                                                </div>}
                                                                            <div className='text-center'>
                                                                                <img src='/images/edit.svg' alt='' onClick={(e) => {
                                                                                    e.preventDefault()
                                                                                    e.stopPropagation()
                                                                                    addChapter(el, true)
                                                                                }} /> &nbsp;&nbsp;
                                                                                {el.active ? null : <img src='/images/delete.svg' alt='' onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    e.stopPropagation()
                                                                                    openDelete(el)
                                                                                }} />}
                                                                                {/* <img src='/images/menu.svg' alt='' /> */}
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                }
                                                            </Draggable>
                                                        )
                                                    })
                                                    : <div className='empty-list'>
                                                        <img src='/images/empty.png' alt='' />
                                                        <p>You have not added any units  yet.</p>
                                                    </div>
                                            }
                                            {provided.placeholder}
                                        </ul>
                                    )
                                }
                            </Droppable>
                        </DragDropContext>
                    }
                </Fragment> :
                <div className='add-subject-brief'>
                    {Object.keys(subjectBrief?.brief).length !== 0 &&
                        <div className='float-right' style={{ color: '#5855d6', fontWeight: 600, cursor: 'pointer' }} onClick={() => setIsOpen(true)}>Preview</div>
                    }
                    <div className='mt-4'>
                        <div className=''>
                            <div className='col-md-9 border' >
                                <label className='mb-2'>Subject Brief</label>
                                {fields.map((field, index) => (
                                    <div key={index}>
                                        <div className='col-md-12 position-relative'>
                                            <div className='form-group mt-2'>
                                                <input
                                                    className='form-control'
                                                    type="text"
                                                    name='title'
                                                    placeholder="Title"
                                                    value={field.title}
                                                    onChange={(e) => handleInputChange(index, e)}
                                                />

                                            </div>
                                            {
                                                index === 0 ? null : <button
                                                    type="button"
                                                    onClick={() => removeInput(index)}
                                                    className="remove-btn"
                                                ><img src="/images/delete.svg" alt='' /></button>
                                            }
                                        </div>
                                        <div className='col-md-12'>
                                            <div className='form-group mt-2'>
                                                {field.content.map((content, contentIndex) => (
                                                    <div key={contentIndex} className='position-relative'>
                                                        <input
                                                            className='form-control mt-2 '
                                                            type="text"
                                                            placeholder='Description'
                                                            name="content"
                                                            value={content}
                                                            onChange={(event) => handleContentChange(index, contentIndex, event)}
                                                        />
                                                        {
                                                            contentIndex === 0 ? null : <button
                                                                type="button"
                                                                onClick={() => removeContentInput(index, contentIndex)}
                                                                className="remove-btn"
                                                            ><img src="/images/delete.svg" alt='' /></button>
                                                        }
                                                    </div>
                                                ))}

                                                <button className='add-btn'
                                                    disabled={field.title === '' || field.content.some(content => content === '')}
                                                    onClick={() => addContentField(index)}>Add Content</button>
                                            </div>

                                        </div>
                                    </div>
                                ))}
                                <button className='add-btn text-left'
                                    disabled={fields[fields.length - 1].title === '' || fields[fields.length - 1].content.some(content => content === '')}
                                    onClick={addInputField}>Add
                                </button>
                            </div>
                            <span style={{ color: 'red' }}>{descriptionError}</span>
                        </div>
                    </div>
                    <div className='mt-3 border' style={{ width: '75%' }}>
                        <label>Outcome</label>
                        <DynamicInput
                            array={outcomes}
                            addField={() => addField('outcome')}
                            removeField={(idx) => removeField(idx, 'outcome')}
                            handleField={(index, event) => handleField(index, event, 'outcome')}
                        />
                    </div>
                    <div className='mt-3'>
                        <div className='col-md-9'>
                            <label>Skills</label>
                            <div className='mt-2'>
                                <CreatableSelect
                                    // components={{ DropdownIndicator }}
                                    isSearchable
                                    isMulti
                                    placeholder='Add Skills'
                                    value={skills}
                                    onChange={selectSkills}
                                // options={this.props.subTopicsContent}
                                />
                            </div>
                        </div>
                        <span style={{ color: 'red' }}>{skillError}</span>
                    </div>
                    <div className='mt-3 border' style={{ width: '75%' }}>
                        <label>Benefits</label>
                        <DynamicInput
                            array={benefits}
                            addField={() => addField('benefit')}
                            removeField={(idx) => removeField(idx, 'benefit')}
                            handleField={(index, event) => handleField(index, event, 'benefit')}
                        />
                    </div>
                    <div className='mt-3'>
                        <div className='col-md-9'>
                            <label>Companies</label>
                            <div className='mt-2'>
                                <CreatableSelect
                                    // components={{ DropdownIndicator }}
                                    isSearchable
                                    isMulti
                                    placeholder='Add Comapnies'
                                    value={companies}
                                    onChange={selectCompanies}
                                // options={this.props.subTopicsContent}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='mt-3 border' style={{ width: '75%' }}>
                        <label>Career Opportunities</label>
                        <DynamicInput
                            array={opportunity}
                            addField={() => addField('opportunity')}
                            removeField={(idx) => removeField(idx, 'opportunity')}
                            handleField={(index, event) => handleField(index, event, 'opportunity')}
                        />
                    </div>
                    <div className='mt-3 '>
                        <div className='col-md-9'>
                            <label>Average Salary</label>
                            <div className='form-group mt-2'>
                                <input
                                    className='form-control'
                                    type="text"
                                    name='title'
                                    placeholder="Title"
                                    value={avgSalary}
                                    onChange={(e) => setAvgSalary(e.target.value)}
                                />

                            </div>
                        </div>
                    </div>
                    <div className='save-btn'>
                        <button onClick={saveSubjectBrief}>Save</button>
                        <button onClick={() => dispatch(backToTopic())}>Cancel</button>

                    </div>
                </div>
            }

            {/* ############## add and edit chapter modal ############### */}
            <Modal
                show={isAddChapter}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName={"add-topic-modal"}
            >
                <div class="modal-header" style={{ background: '#1D8BCB' }}>
                    <h5 class="modal-title">{edit ? 'Edit Chapter' : 'Add Chapter'} <br /><p>{subject}</p> </h5>

                    <div className="sticky-close" onClick={handleModal}>
                        <img src="/images/close-white.svg" width='15px' alt='' /></div>

                </div>
                <Modal.Body style={{ padding: '22px 40px 22px 40px', }}>

                    <div>
                        <label>Chapter Name*
                            <aside className='float-right'>{chars_left}</aside>
                        </label>
                        <input className='form-control mt-2' value={name} maxLength={maxChar} onChange={(e) => changeName(e.target.value)} />
                    </div>
                    <small>{nameError}</small>
                    <div className='mt-3'>
                        <label>Subject Description*
                        </label>
                        <textarea className='form-control mt-2' onChange={(e) => changeDescription(e.target.value)} value={description} />
                    </div>
                    <small>{descError}</small>
                    {
                        localStorage.getItem('role').includes('super-admin') && edit ?
                            <div className='mt-4'>
                                <label>Chapter Id*
                                    {/* <aside className='float-right'>{chars_left}</aside> */}
                                </label>
                                <input className='form-control mt-2' value={chapterId} disabled />
                            </div>
                            : null
                    }
                    <div className='mt-3'>
                        <label>Chapter Cover Image*</label>
                        <div className='d-flex align-items-center'>
                            {
                                image ?
                                    <div className='program-image'>
                                        <img src={imageUrl} alt='' width='139px' height='78px' />
                                    </div> :
                                    <div className='program-image'>
                                        <img src={imageUrl} alt='' />
                                    </div>
                            }
                            <div className='d-flex flex-column  position-relative mt-3'>
                                {/* <span onClick={() => openLib()}><img src='/images/gallery-icon.svg' width='24px' alt='' /> &nbsp;Select From Gallery</span> */}
                                <span> <img src='/images/upload-icon.svg' alt='' /> &nbsp;Upload Image &nbsp; <small>{imageName}</small>
                                    <input type='file' className='file-upload'
                                        name="selectedFile" accept="image/png, image/jpeg,.pdf,.doc" onChange={changeImage} />
                                </span>
                            </div>
                        </div>
                    </div>
                    <small>{imageError}</small>
                    <div className='save-btn'>
                        {
                            edit ?
                                <button disabled={isLoading} onClick={editChapter} >
                                    {isLoading ? <div className='ballbeat'><BallBeat color="#fff" /></div> : 'Save'}</button>
                                :
                                <button disabled={isLoading} onClick={saveChapter}>
                                    {isLoading ? <div className='ballbeat'><BallBeat color="#fff" /></div> : 'Save'}</button>
                        }
                        <button onClick={handleModal}>Cancel</button>
                    </div>
                </Modal.Body>
            </Modal>

            {/* ################## Confirm Delete modal ############################ */}
            <ConfirmModal
                isConfirm={isConfirm}
                confirmDelete={async () => {
                    await dispatch(confirmDeleteChapter(deleteId))
                    await setIsConfirm(false)
                }}
                closeModal={() => setIsConfirm(false)}
                type='chapter'
            />

            {/* ################## Preview modal ############################ */}
            <SubjectBrief
                isOpen={isOpen}
                setIsOpen={() => setIsOpen(false)}
                subjectDescription={subjectBrief}
            />
        </div >
    )
}

export default (ManageChapter);