import { combineReducers } from "redux";
import authReducer from "./Common/authReducer";
import errorReducer from "./Common/errorReducer";
import myLearningReducer from "./User/myLearningReducer";
import profileReducer from "./Common/profileReducer";
import contentLibraryReducer from "./User/contentLibraryReducer";
import accountReducer from "./Admin/accountReducer";
import manageContentReducer from "./Admin/manageContentReducer";
import practiceTestReducer from "./User/practiceTestReducer";
import sidebarReducer from "./Common/sidebarReducer";
import mentorConnectReducer from "./Common/mentorConnectReducer";
import counsellingReducer from "./User/counsellingReducer";
import reportReducer from "./Admin/reportReducer";




export const rootReducer = combineReducers({
    sidebar: sidebarReducer,
    auth: authReducer,
    error: errorReducer,
    myLearning: myLearningReducer,
    practiceTest: practiceTestReducer,
    profile: profileReducer,
    contentLibrary: contentLibraryReducer,
    account: accountReducer,
    manageContent: manageContentReducer,
    counselling: counsellingReducer,
    mentorConnect: mentorConnectReducer,
    report: reportReducer
});