
import { RESET_SUBJECTS, GET_PRACTICE_SUBJECTS, RESET_TEST_LOADER, GET_TEST_LIST, CHECK_PENDING } from '../../Types/types';

let intialState = {
    isListLoading: false,
    program: {
        "_id": "61335ab9e11e2f5e9098bfc3",
        "code": "CP01",
        "semester": 6,
        "image": "https://campus-bucket.s3.amazonaws.com/Diploma%20In%20Tool%20Engineering%20%26%20Digital%20Manufacturing%20%28CP01%29_1630755510244.png",
        "type": "default",
        "program": "Diploma In Tool Engineering & Digital Manufacturing (CP01)"
    },
    testProgress: [
        {
            "_id": "6133cfe96f40f6f2a706f49d",
            "title": "Mathematics",
            "image": "https://campus-bucket.s3.amazonaws.com/Mathematics_1630808155478.png",
            "taken": 0,
            "total": 2
        },
        {
            "_id": "6133d6023a041c6abb9d31cc",
            "title": "Applied Science",
            "image": "https://campus-bucket.s3.amazonaws.com/Applied%20Science_1630808171118.png",
            "taken": 0,
            "total": 0
        },
        {
            "_id": "6133d6023a041c6abb9d3207",
            "title": "English",
            "image": "https://campus-bucket.s3.ap-south-1.amazonaws.com/English_1630808185990.png",
            "taken": 0,
            "total": 0
        },
        {
            "_id": "6133d6023a041c6abb9d3266",
            "title": "Electrical Engineering - I",
            "image": "https://campus-bucket.s3.amazonaws.com/Electrical%20Engineering%20-%20I_1630808226076.png",
            "taken": 0,
            "total": 0
        },
        {
            "_id": "6133d6033a041c6abb9d329d",
            "title": "Electronics - I",
            "image": "https://campus-bucket.s3.ap-south-1.amazonaws.com/Electronics%20-%20I_1630808284923.png",
            "taken": 0,
            "total": 7
        }
    ],
    isLoading: false,
    testList: []
}


export default function practiceTestReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case RESET_SUBJECTS:
            stateCopy.subjects = [];
            return stateCopy;
        case GET_PRACTICE_SUBJECTS:
            stateCopy.subjects = action.payload;
            return stateCopy;
        case RESET_TEST_LOADER:
            stateCopy.isListLoading = true;
            return stateCopy;
        case GET_TEST_LIST:
            stateCopy.testList = action.payload;
            stateCopy.testListCopy = action.payload;
            stateCopy.isListLoading = false;
            return stateCopy;
        case CHECK_PENDING:
            if (action.payload) {
                const check = stateCopy.testListCopy.filter(el => el.results === 0);
                stateCopy.testList = check;
            }
            else {
                stateCopy.testList = stateCopy.testListCopy;
            }
            return stateCopy;
        default:
            return stateCopy;
    }
}
