import React, { Fragment, useState, useEffect } from 'react';
import './reports.scss';
import Highcharts, { chart } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import TableComponent from './tableComponent';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getStates, getSchools } from '../../../Redux/ActionCreators/Admin/accountAction';
import { getSchool, getStateData, getStateScoreTrend, getStateLoginTrend, getStudentDetails } from '../../../Redux/ActionCreators/Admin/reportAction';

export default function Reports() {
    const [active, setActive] = useState('school');
    const [stateVal, setStateVal] = useState('');
    const [school, setSchool] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        const firstRender = async () => {
            const res = await dispatch(getStates());
            if (res?.type === 'GET_STATE_LIST') {
                setStateVal(res?.payload[0]?._id);
                await dispatch(getSchool(res?.payload[0]?._id));
                await dispatch(getStateData(res?.payload[0]?._id));
            }
            await dispatch(getStateLoginTrend(localStorage.getItem('state_id')));
            await dispatch(getStateScoreTrend(localStorage.getItem('state_id')));
            const res1 = await dispatch(getSchools());
            if (res1?.type === 'GET_SCHOOL_LIST') {
                setSchool(res1?.payload[0]?._id);
                await dispatch(getStudentDetails(res1?.payload[0]?._id));
            }
        }

        firstRender();
    }, [])

    const selectState = async (val) => {
        setStateVal(val);
        await dispatch(getSchools(val));
        await dispatch(getStateData(val));
    }

    const selectSchool = async (val) => {
        setSchool(val);
        await dispatch(getStudentDetails(val));
    }



    const { stateSchools, stateData, loginTrend, scoreTrend, studentData } = useSelector(state => state.report);
    const { states, schools } = useSelector(state => state.account)

    const schoolHead = [{ title: 'name', label: 'School Name' }, { title: 'total_students', label: '#Students', sort: false }, { title: 'total_time', label: 'Total Time Spent', sort: false }, { title: 'average_time', label: 'Avg Time Spent', sort: false }, { title: 'active_students', label: 'Active Users', sort: false }];
    const studentHead = [{ title: 'name', label: 'Student Name' }, { title: 'school', label: 'School Name', sort: false }, { title: 'class', label: 'Class' }, { title: 'score', label: 'Engagement Score(Overall)', sort: false }, { title: 'score_this_month', label: 'Engagement Score(This month)', sort: false }, { title: 'last_login', label: 'Last Login' }];

    const schoolGraph = {
        chart: {
            type: 'column',
            height: 270,
            // width: '100%'
        },
        legend: {
            enabled: false
        },

        title: {
            text: ''
        },

        colors: ['#0080C5', '#3F51B5'],
        xAxis: {
            categories: stateData?.map(el => el.name),
            type: 'schools',
        },
        yAxis: {
            type: 'Counts',
            min: 0,
            visible: false,
            title: {
                text: ''
            },

        },
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        // eslint-disable-next-line eqeqeq
                        if (this.y != 0) {
                            return this.y;
                        }
                    }

                },
                pointWidth: window.innerWidth > 767 ? 25 : 12

            }
        },
        credits: {
            enabled: false
        },
        series:
            [{
                name: 'Engagement-score',
                data: stateData?.map(el => el.total_students),
                enableMouseTracking: false,
            }, {
                name: 'Student Count',
                data: stateData?.map(el => el.total_students),
                enableMouseTracking: false,
            },
            ]

    }
    //student tab graph
    const studentTrend = {
        chart: {
            type: 'line',
            height: 300
        },
        title: {
            text: ''
        },
        subtitle: {
            // text: '3,456 Students'
        },
        xAxis: {
            categories: loginTrend?.dates,
            visible: true
        },
        colors: ['#3F51B5'],
        yAxis: {
            visible: false,
            min: 0,
            // max:100,
            tickInterval: 10,
            title: {
                //   text: 'Temperature (°C)'
            }

        },
        credits: {
            enabled: false
        },
        plotOptions: {
            line: {
                dataLabels: {
                    enabled: true
                },
                showInLegend: false,
                enableMouseTracking: false
            }
        },
        series: [{
            name: 'Tokyo',
            data: loginTrend?.logins
        },
        ]
    }
    const engagementTrend = {
        chart: {
            type: 'line',
            height: 300
        },
        title: {
            text: ''
            // text: 'Last 10 days engagement score trend'
        },
        subtitle: {
            // text: '3,456 Students'
        },
        xAxis: {
            categories: scoreTrend?.dates,
            visible: true
        },
        colors: ['#35996D'],
        yAxis: {
            visible: false,
            min: 0,
            // max:100,
            tickInterval: 10
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            line: {
                dataLabels: {
                    enabled: true
                },
                showInLegend: false,
                enableMouseTracking: false
            }
        },
        series: [{
            name: 'Tokyo',
            data: scoreTrend?.scores
        },
        ]
    }
    return (
        <div className='report-holder'>
            <h2>Reports</h2>
            <div className='report-tabs' >
                <div className={active === 'school' ? 'report-tab active' : 'report-tab'} onClick={() => setActive('school')}>
                    <img src='/images/school.svg' alt='' />&nbsp;&nbsp;Schools
                </div>
                <div className={active === 'student' ? 'report-tab active' : 'report-tab'} onClick={() => setActive('student')}>
                    <img src='/images/student.svg' alt='' />&nbsp;&nbsp;Students
                </div>
                <div className={active === 'subject' ? 'report-tab active' : 'report-tab'} onClick={() => setActive('subject')}>
                    <img src='/images/subject.svg' alt='' />&nbsp;&nbsp;Subjects
                </div>
            </div>
            {
                active === 'school' ?
                    <Fragment>
                        <div className='report-filters'>
                            <select className='form-control' value={stateVal} onChange={(e) => selectState(e.target.value)}>
                                <option hidden>Select State</option>
                                {
                                    states && states?.map(el => {
                                        return (
                                            <option value={el._id}>{el.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className='school-reports mt-2'>
                            <div className='center-graph'>
                                <div className='d-flex justify-content-between'>
                                    <h1>Top 10 schools</h1>
                                    {/* <select>All time</select> */}
                                    <div className='legands'>
                                        <div className='legand-1'>
                                            <div></div>&nbsp; <p>Avg Time Spent</p>
                                        </div>
                                        <div className='legand-1 mt-2'>
                                            <div style={{ background: '#0080c5' }}></div>&nbsp; <p>Student Count</p>
                                        </div>
                                    </div>
                                </div>
                                {
                                    // loadCenter
                                    //     ?
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={schoolGraph}
                                        oneToOne={true}
                                    />
                                    // :
                                    // <div className='text-center mt-2'>
                                    //     <BarChart />
                                    // </div>
                                }

                            </div>
                            <div className='center-table '>
                                <TableComponent
                                    header={schoolHead}
                                    centerList={stateData}
                                    type='school'
                                />
                            </div>
                        </div>
                    </Fragment> :
                    active === 'student' ?
                        <Fragment>

                            <div className='school-reports mt-2'>
                                <div className='program-graph '>
                                    <div className='row ml-0 mr-0'>
                                        <div className='col-md-6'>
                                            <h1>Last 10 days engagement trend</h1>
                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                options={engagementTrend}
                                            />
                                        </div>
                                        <div className='col-md-6'>
                                            <h1>Last 10 days number of logins</h1>
                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                options={studentTrend}
                                            />
                                        </div>

                                    </div>

                                </div>
                                <div className='report-filters'>
                                    <select className='form-control' value={school} onChange={(e) => selectSchool(e.target.value)}>
                                        <option hidden>Select School</option>
                                        {
                                            schools && schools?.map(el => {
                                                return (
                                                    <option value={el._id}>{el.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <select className='form-control'>
                                        <option hidden>Select Class</option>
                                        <option>9</option>
                                        <option>10</option>
                                    </select>
                                </div>
                                <div className='center-table '>
                                    <TableComponent
                                        header={studentHead}
                                        centerList={studentData}
                                        type='school'
                                    // openIndividualItem={props.openIndividualItem}
                                    // getTopCenterPrograms={props.getTopCenterPrograms}
                                    // getCenterProgramDetails={props.getCenterProgramDetails}
                                    // resetCenterDetails={props.resetCenterDetails}
                                    // setShow={(val) => setShow(val)}
                                    // sortCenterList={props.sortCenterList}
                                    />
                                </div>
                            </div>
                        </Fragment>
                        : null
            }

        </div>
    )
}
