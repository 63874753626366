import React, { Fragment, useState, useEffect } from "react";
import "../Manage-Resources/manageResource.scss";
import { googleLinkPreviewSetNull } from "../../../../Redux/ActionCreators/Admin/manageContentAction";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";


function PreviewLink() {
    const [imageUrl, setImageUrl] = useState("/images/empty-image.svg");
    const dispatch = useDispatch();
    const { previewData } = useSelector(state => state.manageContent);

    useEffect(() => {
        return () => {
            dispatch(googleLinkPreviewSetNull());
        }
    }, [])

    return (
        <div className="prevData mt-3 bg-light">
            <div className='card'>
                {
                    previewData ?
                        <Fragment>
                            {(previewData) ?
                                <div className='card-image'>
                                    <img src={previewData.image} onError={(e) => {
                                        if (e.target.src !== 'https://elite-product-images.s3.ap-south-1.amazonaws.com/articles_default_2.png') {
                                            e.target.onerror = "https://elite-product-images.s3.ap-south-1.amazonaws.com/articles_default_2.png";
                                            e.target.src = "https://elite-product-images.s3.ap-south-1.amazonaws.com/articles_default_2.png";
                                        }
                                    }} alt='' width="139px"
                                        height="78px" />
                                </div>
                                :
                                <div className='card-image'>
                                    <img src={previewData.image} alt='' width="139px"
                                        height="78px" />
                                </div>
                            }
                            <div className='card-description pr-0'>
                                <h2>{previewData.title}</h2>
                                <small>
                                    {previewData.description}
                                </small>
                            </div>
                        </Fragment>
                        : 'Please check your url'
                }
            </div>
        </div>
    )
}





export default PreviewLink;
