import React, { Fragment, useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import { BallBeat } from '../../../../Loader/loader';
import AddQuestion from "../Manage-Add-Content/addQuestion";
import AddPracticeTest from "../Manage-Add-Content/addPracticeTest";
import { useDispatch } from 'react-redux';
import { getAffliations } from '../../../../Redux/ActionCreators/Admin/manageContentAction';
import { useSelector } from 'react-redux';
import { Alert } from '@mui/material';

export default function AddModal(props) {
    const [successMsg, setSuccessMsg] = useState(false);
    const [successMessageText, setSuccessMessageText] = useState('');
    const [name, setName] = useState('');
    const [affliation, setAffliation] = useState('');
    const [id, setId] = useState('');
    const [active, setActive] = useState('');
    const [classs, setClass] = useState('');
    const [classroomType, setClassroomType] = useState(false);
    // const [description, setDescription] = useState('');

    const [image, setImage] = useState('');
    const [imageUrl, setImageUrl] = useState('/images/empty-image.svg');
    const [imageName, setImageName] = useState('');
    const [chars_left, setCharLeft] = useState(75);
    const [questions, setQuestions] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [update, setUpdate] = useState(false);
    const [nameError, setNameError] = useState('');
    const [imageErr, setImageError] = useState('');
    const [affliationError, setAffliationError] = useState('');
    const [classError, setClassError] = useState('');
    const [typeError, setTypeError] = useState('');

    const maxChar = 75;
    const max_desc_char = 250;
    const dispatch = useDispatch();
    const affliations = useSelector(state => state.manageContent.affliations);
    const classes = useSelector(state => state.auth.classes);


    useEffect(() => {
        const { items, type } = props
        if (type === 'classroom') {
            dispatch(getAffliations());
            if (items !== '' && items !== null) {
                setName(items.name);
                setId(items._id);
                setActive(items.active);
                setAffliation(items?.affiliation?._id);
                setClass(items?.class);
                setClassroomType(items.default)
                // setDescription(items.description === 'undefined' ? '' : items.description);
                setImage(items?.image);
                setImageUrl(items?.image);
                setCharLeft(maxChar - items.name?.length);
                // setDescCharLeft(items.description === 'undefined' && (250 - items.description?.length));
            }

        }
        else if (type === 'question-bank') {
            items !== undefined && items !== '' &&
                setQuestions(items)
        }

        else {
            setImage('');
            setImageUrl('/images/empty-image.svg');
        }
    }, [])



    const onChangeProgram = (val) => {
        const charCount = val.length;
        const charLength = maxChar - charCount;
        setName(val);
        setNameError('');
        setCharLeft(charLength);
    }

    const changeAffliation = (val) => {
        setAffliation(val);
        setAffliationError('');
    }

    const changeClass = (val) => {
        setClass(val);
        setClassError('');
    }

    const selectType = (val) => {
        setClassroomType(val);
        setTypeError('');
    }

    const changeImage = (e) => {
        if (e.target.files[0].size > 2097152) {
            setSuccessMsg(true);
            setSuccessMessageText("The file size cannot exceed 2MB");
        } else {
            const file = e.target.files[0];
            const reader = new FileReader();

            reader.onloadend = () => {
                setImageUrl(reader.result);
            };
            if (file) {
                reader.readAsDataURL(file);
                setImageUrl(reader.result);
                setImage(file);
                setImageName(file.name);
                setUpdate(true);
            } else {
                setImageUrl('');
                setImageName('');
                setUpdate(false);
            }
        }
    }


    const saveClassroom = async () => {
        if (name === '') {
            setNameError(`Name can't empty`);
        }
        else if (affliation === '') {
            setAffliationError('Please Select Affliation');
        }
        else if (classs === '') {
            setClassError('Please Select Class');
        }
        else if (classroomType === '') {
            setTypeError('Please Select Type');
        }
        else if (image === '') {
            setImageError(`Image can't empty`);
        }
        else {
            setNameError('');
            setAffliationError('');
            setIsLoading(true);
            const data = new FormData();
            data.append('name', name);
            data.append('affiliation_id', affliation);
            data.append('class', classs);
            data.append('default', classroomType);
            data.append('image', image);
            await props.savedClassroom(data);
            await setIsLoading(false);
        }

    }

    const editClassroom = async () => {
        if (name === '') {
            setNameError(`Name can't empty`);
        }
        else if (affliation === '') {
            setAffliationError('Please Select affliation');
        }
        else if (image === '') {
            setImageError(`Image can't empty`);
        }
        else {
            setNameError('');
            setAffliationError('');
            setIsLoading(true);
            let obj = new FormData();
            if (update) {
                obj.append('name', name);
                obj.append('affiliation_id', affliation);
                obj.append('classroom_id', id);
                obj.append('class', classs);
                obj.append('default', classroomType);
                obj.append('image', image);
            } else {
                obj.append('name', name);
                obj.append('affiliation_id', affliation);
                obj.append('classroom_id', id);
                obj.append('class', classs);
                obj.append('default', classroomType);

            }
            await props.updateClassroom(obj);
            await setIsLoading(false);
        }

    }

    const back = () => {
        props.backToMain();
        setQuestions('');
    }


    const snackClosed = () => {
        setSuccessMsg(false);
        setSuccessMessageText('')
    }

    const { type, edit } = props
    return (
        <Fragment>

            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
                open={successMsg}
                autoHideDuration={3000}
                onClose={snackClosed}
            >
                <Alert onClose={snackClosed} severity="error" sx={{ width: '100%' }}>
                    {successMessageText}
                </Alert>
            </Snackbar>
            {
                type === 'classroom' ?
                    <div className='add-subject'>
                        <h2 onClick={back}><img src='/images/back.svg' width='20px' alt='' /> &nbsp;&nbsp;{props.edit ? 'Edit Classroom' : 'Add Classroom'}</h2>
                        <p>Fields marked * are mandatory</p>
                        <div className='form-group mt-2'>
                            <label>Classroom Name*
                                <aside className='float-right'>{chars_left}</aside>
                            </label>
                            <input className='form-control mt-2 ' maxLength={maxChar} value={name} onChange={(e) => onChangeProgram(e.target.value)} />
                            <small>{nameError}</small>
                        </div>
                        {
                            localStorage.getItem('role')?.includes('master') && edit ?
                                <div className='form-group mt-2'>
                                    <label>Classroom Id*
                                        {/* <aside className='float-right'>{chars_left}</aside> */}
                                    </label>
                                    <input className='form-control mt-2 ' disabled maxLength={maxChar} value={id} />
                                </div>
                                : null
                        }

                        <div className='d-flex' style={{ width: '52%' }}>
                            <div style={{ width: '50%' }}>
                                <div className='mt-3'>
                                    <label>Affiliation*
                                    </label>
                                    <select className='form-control mt-2' style={{ border: '1px solid #5855d6' }} value={affliation} onChange={(e) => changeAffliation(e.target.value)}>
                                        <option hidden></option>
                                        {
                                            affliations && affliations?.map(el => {
                                                return (
                                                    <option key={el._id} value={el._id}>{el.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <small>{affliationError}</small>
                            </div>&nbsp;&nbsp;
                            <div style={{ width: '50%' }}>
                                <div className='mt-3'>
                                    <label>Class*
                                    </label>
                                    <select className='form-control mt-2' style={{ border: '1px solid #5855d6', }} value={classs} onChange={(e) => changeClass(e.target.value)}>
                                        <option hidden></option>
                                        {
                                            classes && classes?.map(el => {
                                                return (
                                                    <option key={el.value} value={el.value}>{el.label}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <small>{classError}</small>

                            </div>
                        </div>
                        <div className='mt-3'>
                            <label>Classroom Type*
                            </label>
                            <select className='form-control mt-2' style={{ border: '1px solid #5855d6', width: '25.5%' }} disabled={props.edit} value={classroomType} onChange={(e) => selectType(e.target.value)}>
                                <option hidden></option>
                                <option value={true}>Default</option>
                                <option value={false}>Main</option>
                            </select>
                        </div>
                        <small>{typeError}</small>
                        <div className='mt-3'>
                            <label>Program Cover Image*</label>
                            <p style={{ marginTop: 5 }}>This is the default image. </p>
                            <div className='d-flex align-items-center '>
                                {
                                    image ?
                                        <div className='program-image'>
                                            <img src={imageUrl} alt='' width='139px' height='78px' />
                                        </div> :
                                        <div className='program-image'>
                                            <img src={imageUrl} alt='' />
                                        </div>
                                }

                                <div className='d-flex flex-column mt-3 position-relative'>

                                    <span> <img src='/images/upload-icon.svg' alt='' /> &nbsp;Upload Image &nbsp; <small>{imageName}</small>
                                        <input type='file' className='file-upload'
                                            name="selectedFile" accept="image/png, image/jpeg" onChange={changeImage} />
                                    </span>
                                </div>
                            </div>
                            <small>{imageErr}</small>
                        </div>
                        <hr />
                        <div className='save-btn mt-2'>
                            {
                                props.edit ?
                                    <button disabled={isLoading} onClick={editClassroom} >
                                        {isLoading ? <div className='ballbeat'><BallBeat color="#fff" /></div> : 'Save'}</button>
                                    :
                                    <button disabled={isLoading} onClick={() => saveClassroom()}>
                                        {isLoading ? <div className='ballbeat'><BallBeat color="#fff" /></div> : 'Save'}</button>

                            }
                            <button onClick={back}>Cancel</button>
                        </div>
                    </div>
                    : type === "question-bank" ? (
                        <AddQuestion
                            type={props.type}
                            backToMain={props.backToMain}
                            savedQuestions={props.savedQuestions}
                            editedQuestion={props.editedQuestion}
                            questions={props.items}
                            resetPercentage={props.resetPercentage}
                            // questions={props.editQuestionData}
                            edit={props.edit}
                            selectFromSubject={props.selectFromSubject}
                            getChapterOfSub={props.getChapterOfSub}
                            subjectChapters={props.subjectChapters}
                            subjectTopicList={props.subjectTopicList}
                            getSubTopics={props.getSubTopics}
                            subTopicsContent={props.subTopicsContent}
                            getClubbedQuestionList={props.getClubbedQuestionList}
                        />
                    ) : (
                        <AddPracticeTest
                            type={props.type}
                            selectFromSubject={props.selectFromSubject}
                            deleteAllQuestion={props.deleteAllQuestion}
                            questionListForQuiz={props.questionListForQuiz}
                            backToMain={props.backToMain}
                            savedNewQuiz={props.savedNewQuiz}
                            editPracticeTest={props.editPracticeTest}
                            savedQuestions={props.savedQuestions}
                            successEnable={props.successEnable}
                            successMessageText={props.successMessageText}
                            snackClosed={props.snackClosed}
                            edit={props.edit}
                            test={props.items}
                            resetPercentage={props.resetPercentage}
                            getTopicsOfSub={props.getTopicsOfSub}
                            subjectChapters={props.subjectChapters}
                            subjectTopicList={props.subjectTopicList}
                            getSubTopics={props.getSubTopics}
                            subTopicsContent={props.subTopicsContent}
                            getClubbedQuestionList={props.getClubbedQuestionList}
                        />
                    )
            }
        </Fragment>
    )
}




