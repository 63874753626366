import { RESET_LOADING, GET_MENTOR_LIST, GET_STUDENT_CONVERSATION_LIST, SUBJECT_FILTER, GET_FACULTY_SUBJECTS, RESET_FACULTY_SUBJECTS, ADD_QUERY, GET_QUESTION_DETAIL, REPLY_SENT, CLOSE_QUESTION_DETAIL } from '../../Types/types';
import { getApiCall, postApiCall } from '../../../utils/request';
import { returnErrors } from './errorAction';


export const resetLoading = () => {
    return {
        type: RESET_LOADING,
    };
};

export const getMentorList = (subject, query) => async (dispatch) => {

    try {
        const response = await getApiCall(`student/mentors?subject_id=${subject}&query=${query}`);
        dispatch({ type: GET_MENTOR_LIST, payload: response.data.data });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getQuestionList = (subject, query) => async (dispatch) => {

    try {
        const response = await getApiCall(`student/mentors/conversation?query=${query}&subject_id=${subject}`);
        dispatch({ type: GET_STUDENT_CONVERSATION_LIST, payload: response.data.data });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getSubjectFilter = () => async (dispatch) => {
    try {
        const id1 = localStorage.getItem('classroomId');
        const id2 = localStorage.getItem('defaultClassroomId');
        const apiUrl1 = await getApiCall(`student/learning/classrooms/subjects?classroom_id=${id1}`);
        const apiUrl2 = await getApiCall(`student/learning/classrooms/subjects?classroom_id=${id2}`);

        // Call both APIs using Promise.all
        Promise.all([apiUrl1, apiUrl2])
            .then((responses) => {
                // Concatenate the responses or perform any other operation
                const concatenatedData = responses[0]?.data.data.concat(responses[1]?.data.data);
                dispatch({ type: SUBJECT_FILTER, payload: concatenatedData })
                // Do something with the concatenated data;
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getFacultySubjects = (id) => {
    return {
        type: GET_FACULTY_SUBJECTS, payload: id
    };
};

export const resetFacultySubject = () => {
    return {
        type: RESET_FACULTY_SUBJECTS
    };
};

export const addQuery = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`student/mentors/conversation/start`, obj);
        if (response.data.success) {
            returnErrors(dispatch, 'Query sent successfully', response?.status);
            return dispatch({ type: ADD_QUERY });
        }
        else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getQDetails = (detail, id) => async (dispatch) => {

    try {
        const response = await getApiCall(`student/mentors/conversation/reply?_id=${id}`);
        const data = response.data.data
        dispatch({ type: GET_QUESTION_DETAIL, payload: { detail, data } });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const closeDetails = () => async (dispatch) => {
    return dispatch({
        type: CLOSE_QUESTION_DETAIL

    })
}

export const replySent = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall('student/mentors/conversation/reply', obj);
        if (response.status === 200) {
            returnErrors(dispatch, 'Reply sent', 200);
            dispatch({ type: REPLY_SENT, payload: obj });
        } else {
            returnErrors(dispatch, response.data.message, response.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}


export const markasclosed = (id) => async (dispatch) => {
    let obj = {
        _id: id
    }
    var response
    try {
        response = await postApiCall('student/mentors/conversation/question/close', obj);
        if (response.data.success) {
            returnErrors(dispatch, 'Query marked as closed', response?.status);
            dispatch({ type: "Closed_Conversation", payload: id });
        }
        else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}
