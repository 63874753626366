export function getUniqueObjects(arr, property) {
    return arr.filter((obj, index, array) => {
        return array.findIndex((o) => o[property] === obj[property]) === index;
    });
}

export function mergeArrays(arr1, arr2) {
    const mergedArray = arr1.map(obj1 => {
        const matchingObj2 = arr2.find(obj2 => obj1.topic_id === obj2._id);
        return matchingObj2 ? { ...obj1, index: matchingObj2.index } : obj1;
    });

    return mergedArray.sort((a, b) => { return a.index - b.index });
}
export function fileToBase64URL(file, callback) {
    const reader = new FileReader();

    reader.onloadend = () => {
        callback(reader.result);
    };

    if (file) {
        reader.readAsDataURL(file);
    }
}
export function reverseArr(input) {
    let ret = [];
    for (let i = input.length - 1; i >= 0; i--) {
        ret.push(input[i]);
    }
    return ret;
}

export function validatePhone(txtPhone) {
    let filter = /[6-9]\d{9}/;
    return !!(filter.test(txtPhone));
}

export function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}
        >
            <img src="/images/right-arrow.svg" width='35px' alt='' />
        </div>
    );
}

export function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}
        >
            <img src="/images/left-arrow.svg" width='35px' alt='' />
        </div>
    );
}

export const nFormatter = (num) => {
    let si = [
        { value: 1, symbol: "" },
        { value: 1E3, symbol: "k" },
        { value: 1E6, symbol: "M" },
        { value: 1E9, symbol: "G" },
        { value: 1E12, symbol: "T" },
        { value: 1E15, symbol: "P" },
        { value: 1E18, symbol: "E" }
    ];
    let rx = /\.0+$|(\.\d*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
            break;
        }
    }
    return (num / si[i].value).toFixed(1).replace(rx, "$1") + si[i].symbol;
}
export const renameProperties = (sourceObj, replaceList, destObj) => {
    destObj = destObj || {};
    each(sourceObj, function (key) {
        if (sourceObj.hasOwnProperty(key)) {
            if (typeof sourceObj[key] === 'object') {
                if (replaceList[key]) {
                    let newName = replaceList[key];
                    destObj[newName] = {};
                    renameProperties(sourceObj[key], replaceList, destObj[newName]);
                } else if (!replaceList[key]) {
                    destObj[key] = {};
                    renameProperties(sourceObj[key], replaceList, destObj[key]);
                }
            } else {
                if (replaceList[key]) {
                    let newName = replaceList[key];
                    destObj[newName] = sourceObj[key];
                } else if (!replaceList[key]) {
                    destObj[key] = sourceObj[key];
                }
            }
        }
    });

    return destObj;
}
function each(objOrArr, callBack) {
    if (objOrArr instanceof Array) {
        for (let i = 0; i < objOrArr.length; i++) {
            callBack(i);
        }
    } else if (typeof objOrArr === 'object') {
        for (let prop in objOrArr) {
            // if the property really exist
            if (objOrArr.hasOwnProperty(prop)) {
                callBack(prop);
            }
        }
    }
}

export const renameArrProperties = (arr) => {
    let newArr = [];
    for (const element of arr) {
        let obj = {};
        obj.label = element;
        obj.value = element
        newArr.push(obj);
    }
    return newArr
}


export const secondsToTime = (e) => {
    // var h = Math.floor(e / 3600).toString().padStart(2,'0'),
    let m = Math.floor(e % 3600 / 60).toString().padStart(2, '0'),
        s = Math.floor(e % 60).toString().padStart(2, '0');

    return m + ':' + s;
    //return `${h}:${m}:${s}`;
}

export const convertDate = (d1) => {
    let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const date = new Date(d1)
    function pad(s) { return (s < 10) ? '0' + s : s; }
    let d = new Date(date)
    return [pad(d.getDate()), pad(monthNames[d.getMonth()]), pad(d.getFullYear())].join('-')
}

export const formatAMPM = (d) => {
    let date = new Date(d)
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours || 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

export const getDateObj = (time) => {
    let startTime = new Date();
    let parts = time.match(/(\d+):(\d+) (AM|PM)/);
    if (parts) {
        let hours = parseInt(parts[1]),
            minutes = parseInt(parts[2]),
            tt = parts[3];
        if (tt === 'PM' && hours < 12) hours += 12;
        startTime.setHours(hours, minutes, 0, 0);
    }
    return startTime
}


export function convertToDate(str) {
    if (str === null) {
        return null
    } else {
        let date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [mnth, day, date.getFullYear()].join("/");
    }
}

export function convertToDate1(str) {
    let date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear()].join("/");
}

export const convertMinsToHrsMins = (mins, t) => {
    let h = Math.floor(mins / 60);
    let m = mins % 60;
    return `${h !== 0 ? (`${h > 9 ? '' : 0}${h}` + t('hr')) : ''} ${m === 0 ? '' : ''}${m === 0 ? '' : m} ${m === 0 ? '' : t('mins')}`;
}

export const convertMillisToHrsMins = (milliseconds, t) => {
    const mins = Math.floor(milliseconds / (1000 * 60));
    const h = Math.floor(mins / 60);
    const m = mins % 60;

    return `${h !== 0 ? (`${h > 9 ? '' : 0}${h}` + t('hr')) : ''} ${m === 0 ? '' : ''}${m === 0 ? '' : m} ${m === 0 ? '' : t('mins')}`;
}

export function convertTONumber(start, end) {
    let startDate = getDateObj(start);
    let endDate = getDateObj(end);
    let diff = endDate.getTime() - startDate.getTime();
    let hours = Math.floor(diff / 1000 / 60 / 60);
    diff += (hours * 1000 * 60 * 60) / 1000 / 60;
    let minutes = Math.floor(diff / 1000 / 60);
    return minutes
}

export function generate_year_range(start, end) {
    let years = [];
    for (let year = start; year <= end; year++) {
        years.push(year);
    }
    return years;
}


export const getSchedulesHours = (schedules) => {
    const data = schedules.map((el) => [{ total: convertTONumber(el.start, el.end, el.date) }][0]);
    const sum = data.reduce(
        (previousValue, currentValue) => previousValue + currentValue.total,
        0,
    );
    return sum
}

export const getYesterdayDate = () => {
    let now = new Date();
    let defaultDate = now - 1000 * 60 * 60 * 24 * 1;
    defaultDate = new Date(defaultDate);
    return convertDate(defaultDate)
}

export function padLeadingZeros(num, size) {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
}

export const validatePhoneNumber = (phoneNumber) => {
    // Define a regular expression for a valid phone number.
    let phonePattern = /^\d{10}$/; // This example assumes a 10-digit phone number.

    // Test the input phone number against the regular expression.
    return !!(phonePattern.test(phoneNumber));
}


export function replaceObjectInArray(array, _id, newObject) {
    const idMap = new Map(array.map(obj => [obj._id, obj]));
    if (idMap.has(_id)) {
        idMap.set(_id, { ...newObject, active: newObject.active });
        return Array.from(idMap.values());
    }
    return array;
}

export function convertToObjects(inputArray) {
    return inputArray.map((el, index) => {
        return { key: index, title: el.title.trim(), content: el.content };
    });
}

export function convertToObjects1(inputArray) {
    return inputArray.map((el, index) => {
        return { key: index, title: el };
    });
}

export function TimeDifferenceInMinutes(time1, time2) {
    // Parse the time strings
    const [hours1, minutes1] = time1 ? (time1 === '00:00-' ? '00:00'.split(':').map(Number) : time1.split(':').map(Number)) : '00:00';
    const [hours2, minutes2] = time2 ? time2.split(':').map(Number) : '00:00';

    // Convert hours to minutes and add to corresponding minutes
    const totalMinutes1 = hours1 * 60 + minutes1;
    const totalMinutes2 = hours2 * 60 + minutes2;

    // Calculate the time difference in minutes
    const differenceInMinutes = Math.abs(totalMinutes1 - totalMinutes2);

    return Math.ceil(differenceInMinutes / 60);
}

export function minutesToMilliseconds(minutes) {
    return minutes * 60 * 1000;
}
export function msTominutes(ms) {
    return ms / (60 * 1000);
}

export async function compressImage(file) {
    const reader = new FileReader();

    const result = await new Promise((resolve) => {
        reader.onload = (event) => {
            const img = new Image();
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                // Set the canvas dimensions to the compressed size you desire
                canvas.width = 400; // Adjust according to your needs
                canvas.height = (img.height / img.width) * canvas.width;

                // Draw the image onto the canvas
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                // Convert the canvas content to a Blob
                canvas.toBlob((blob) => {
                    // Create a new File object from the Blob
                    const compressedFile = new File([blob], file.name, { type: 'image/jpeg' });

                    resolve(compressedFile);
                }, 'image/jpeg', 0.7); // Adjust the quality as needed
            };

            img.src = event.target.result;
        };

        reader.readAsDataURL(file);
    });
    return result;
}

export function getCodes(skills) {
    // Step 1: Convert the object into an array of key-value pairs
    const skillsArray = Object.entries(skills);

    // Step 2: Sort the array based on values in descending order
    skillsArray.sort((a, b) => b[1] - a[1]);

    // Step 3: Take the top three elements
    const topThree = skillsArray.slice(0, 1);

    // Step 4: Extract and display the keys of the top three elements
    const topThreeKeys = topThree.map(entry => entry[0]);
    return topThreeKeys
}

export const goFullScreen = () => {
    const element = document.body;
    if (element.requestFullScreen) {
        element.requestFullScreen();
    } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
    }
};

export const exitFullscreen = () => {
    if (document.exitFullscreen) {
        document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
    }
};

export function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}


const interests = [
    {
        name: 'Realistic',
        interest: 'Individuals who are practical, physical, and hands-on. They prefer working with tools, machines, or animals. They are often described as "doers.',
        suggested: "Realistic individuals are practical, down-to-earth, and enjoy working with their hands. They prefer tasks that involve physical activity and tangible outcomes. They often have mechanical aptitude and enjoy outdoor activities.",
        jobrole: ['Carpenter', 'Electrician', 'Plumber', 'Mechanic', 'Farmer', 'Construction Worker', 'Firefighter', 'Welder', 'Landscaper', 'Forester', 'Surveyor', 'Athletic trainer', 'Automotive technician', 'Agricultural engineer']
    },
    {
        name: 'Investigative',
        interest: 'People who are analytical, curious, and enjoy solving problems through research and experimentation. They tend to prefer working with ideas and information.',
        suggested: "Investigative types are analytical, inquisitive, and enjoy exploring ideas and concepts. They are curious about how things work and enjoy solving complex problems through research and analysis. They typically have strong critical thinking and problem-solving skills.",
        jobrole: ['Scientist', 'Researcher', 'Mathematician', 'Engineer', 'Computer Programmer', 'Academic', 'Biologist', 'Data Analyst', 'Forensic scientist', 'Market researcher', 'Economist', 'Statistician', 'Environmental scientist', 'Pharmacist']
    },
    {
        name: 'Artistic',
        interest: 'Those who are creative, imaginative, and original. They enjoy expressing themselves through various forms of art, such as painting, writing, music, or design.',
        suggested: "Artistic individuals are creative, imaginative, and enjoy self-expression through various forms of art. They have a keen eye for aesthetics and often excel in activities that involve originality and innovation. They may also possess strong emotional intelligence.",
        jobrole: ['Graphic Designer', 'Writer', 'Musician', 'Actor', 'Photographer', 'Fashion Designer', 'Architect', 'Interior Designer', 'Filmmaker', 'Art therapist', 'Web designer', 'Music therapist', 'Creative director', 'Tattoo artist']
    },
    {
        name: 'Social',
        interest: 'Individuals who are helpful, empathetic, and enjoy working with others to solve problems or provide support. They often excel in roles that involve interpersonal interactions.',
        suggested: "Social types are empathetic, compassionate, and enjoy helping and working with others. They thrive in environments that involve interpersonal interactions and collaboration. They have excellent communication and listening skills.",
        jobrole: ['Counselor', 'Social Worker', 'Teacher', 'Nurse', 'Psychologist', 'Therapist', 'Human Resources Manager', 'School Counselor', 'Career counselor', 'Community organizer', 'Conflict mediator', 'Rehabilitation counselor', 'Special education teacher', 'Humanitarian aid worker']
    },
    {
        name: 'Enterprising',
        interest: 'People who are ambitious, persuasive, and enjoy taking risks. They are often drawn to leadership roles, entrepreneurship, and sales.',
        suggested: "Enterprising individuals are ambitious, outgoing, and enjoy taking charge and influencing others. They are often entrepreneurial and enjoy pursuing opportunities for growth and advancement. They possess strong leadership and persuasion skills.",
        jobrole: ['Salesperson', 'Manager', 'Business Owner', 'Marketing Executive', 'Politician', 'Public Relations Specialist', 'Real Estate Agent', 'Event Planner', 'Entrepreneur', 'Insurance sales agent', 'Financial advisor', 'Public relations manager', 'Business consultant', 'Talent agent']
    },
    {
        name: 'Conventional',
        interest: 'Those who are organized, detail-oriented, and prefer working in structured environments with clear rules and procedures. They excel in roles that require precision and adherence to established norms.',
        suggested: "Conventional types are organized, detail-oriented, and enjoy working with data and systems. They prefer structured environments with clear rules and procedures and excel in roles that require precision and attention to detail.",
        jobrole: ['Accountant', 'Banker', 'Administrative Assistant', 'Data Analyst', 'Office Manager', 'Librarian', 'Auditor', 'Legal Assitant', 'Tax preparer', 'Bank teller', 'Logistics coordinator', 'Quality control inspector', 'Compliance officer', 'Purchasing Agent']
    },

]

export function getInterest(name, key) {
    const text = interests?.filter(el => el.name === name)[0]
    return text[key]
}

export function getDatesInMonth(year, month) {
    const startDate = new Date(year, month - 1, 1); // Month is 0-based
    const endDate = new Date(year, month, 0); // Getting the last day of the month

    const datesArray = [];
    for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
        datesArray.push(new Date(date));
    }
    return datesArray.map(el => [{ label: el.getDate(), value: el.getDate() }][0]);
}

export function getValuesInRange(v1, v2) {
    const valuesArray = [];
    for (let i = v1; i <= v2; i++) {
        valuesArray.push(i);
    }
    return valuesArray;
}

export function updateActiveStatus(slots, sessionId, month, newactive) {
    // Loop through the slots
    const updatedSlots = slots.map(slot => {
        // Check if the slot's month matches the provided month
        if (slot.month === month) {
            // Loop through the sessions in this slot
            const updatedSessions = slot.slots.map(session => {
                // If the session _id matches the provided sessionId, update the active status
                if (session._id === sessionId) {
                    return { ...session, active: newactive ? false : true };
                }
                return session;
            });

            // Update the slot with the updated sessions
            return { ...slot, slots: updatedSessions };
        }
        return slot;
    });
    return updatedSlots;
}
