import { GET_MENUS } from "../../Types/types";


let intialState = {
    menuList: []
}


export default function sidebarReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_MENUS:
            stateCopy.menuList = action.payload;
            return stateCopy;
        default:
            return stateCopy;
    }
}