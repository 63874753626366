import React, { Component, useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import './reports.scss';



export default function TableComponent(props) {

    const [data, setData] = useState([]);
    const [asc, setAsc] = useState(false);

    useEffect(() => {
        setData(props.centerList);
    }, [])

    const openIndividualItem = async (type, details) => {
        window.scrollTo(0, 0);
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        };
        await props.openIndividualItem(type, details);
        if (type === 'student' || type === 'trainee') {
            await props.resetStudentDetails();
            await props.getStudentId(details._id)
            await props.getStudentReportDetail(details._id);
            await props.getStudentReportDetail1(details._id);
        }
        else if (type === 'section') {
            await props.setShow(false)
            await props.resetSectionDetails();
            await props.getSecStudent(details._id);
            await props.setShow(true);
        }
        else if (type === 'program') {
            await props.setShow(false)
            await props.resetProgramDetails();
            await props.getStudentSemester(details._id);
            await props.getTopEngagaedSemester(details._id);
            await props.getProgramSemesterData(details._id)
            await props.setShow(true)
        }
        else if (type === 'center') {
            await props.setShow(false);
            await props.resetCenterDetails();
            await props.getTopCenterPrograms(details._id);
            await props.getCenterProgramDetails(details._id);
            await props.setShow(true);
        }
        else if (type === 'subject') {
            await props.setShow(false);
            await props.resetSubjectDetails();
            await props.getTopChapterCompletion(details._id, details.program_id, details.semester);
            await props.getAllChapterCompletion(details._id, details.program_id, details.semester);
            await props.getTopSectionChapterCompletion(details._id, details.program_id, details.semester);
            await props.getAllSectionChapterCompletion(details._id, details.program_id, details.semester);
            await props.setShow(true);
        }
        else if (type === 'adminfaculty') {
            await props.setShow(false);
            await props.resetFacultyDetails();
            await props.getFacultyLogins(details._id)
            await props.getFacultyContentCount(details._id)
            await props.getFacultySubject(details._id)
            await props.getEngagementScores(details._id)
            await props.setShow(true);
        }
        else if (type === 'adminsubject') {
            await props.setShow(false);
            await props.resetFacultySubjectChapter();
            await props.getTopChapterCompletion(details._id, details.program_id, details.semester);
            await props.getAllChapterCompletion(details._id, details.program_id, details.semester);
            await props.getTopSectionChapterCompletion(details._id, details.program_id, details.semester);
            await props.getAllSectionChapterCompletion(details._id, details.program_id, details.semester);
            await props.setShow(true);
        }
        else if (type === 'facultySubject') {
            await props.setShow(false);
            await props.resetFacultySubjectChapter();
            await props.getTopChapterCompletion(details._id, details.program_id, details.semester);
            await props.getAllChapterCompletion(details._id, details.program_id, details.semester);
            await props.getTopSectionChapterCompletion(details._id, details.program_id, details.semester);
            await props.getAllSectionChapterCompletion(details._id, details.program_id, details.semester);
            await props.setShow(true);
        }
        else if (type === 'attendance') {
            await props.setShow(false, details._id);
            await props.refreshAttendanceData();
            await props.getAttendanceByClassWiseGraph(details._id);
            await props.getAttendanceByTopClassWiseGraph(details._id);
            await props.getAttendanceByClassData(details._id);

            await props.setShow(true, details._id);
        }
        else if (type === 'course') {
            await props.getCourseDetails(details?._id);
        }
        else if (type === 'exam') {
            await props.setShow(true, details._id);
            await props.getCourseDetails(details?._id);
        }

    }

    const onSort = async (sortKey, data1, type) => {
        await setAsc(!asc);
        switch (type) {
            case 'center':
                props.sortCenterList(sortKey, asc);
                break;
            case 'program1':
                props.sortCenterProgramList(sortKey, asc);
                break;
            case 'program':
                props.sortProgramList(sortKey, asc);
                break;
            case 'semester':
                props.sortProgramSemList(sortKey, asc);
                break;
            case 'section':
                props.sortSectionList(sortKey, asc);
                break;
            case 'subject':
                props.sortSubjectList(sortKey, asc);
                break;
            case 'adminfaculty':
                props.sortFacultyList(sortKey, asc);
                break;
            case 'adminsubject':
                props.sortFacultySubjectList(sortKey, asc);
                break;
            case 'facultySubject':
                props.sortFacultySubjectList(sortKey, asc);
                break;
            case 'attendance':
                props.sortAttendanceBatchList(sortKey, asc);
                break;
            case 'attendanceByClass':
                props.sortClassWiseAttendance(sortKey, asc);
                break;
            case 'attendanceByStudent':
                props.sortStudentWiseAttendance(sortKey, asc);
                break;
            case 'exam':
                props.sortExamByBatch(sortKey, asc);
                break;
            case 'exambytrainee':
                props.sortExamByTrainee(sortKey, asc);
                break;
            default:
                break;

        }
    }

    const { centerList, header, type } = props
    return (
        <div>
            <div class="table-admin">
                <Table responsive
                    data-sort-name="Total Engagement Score"
                    data-sort-order="desc"
                >
                    <thead>
                        <tr className="header-table">
                            {
                                header.map((el, i) => {
                                    return (
                                        <th style={{ textAlign: i === 0 ? 'left' : 'center' }} className={el.sort ? 'cursor-pointer' : ''} onClick={() => el.sort ? onSort(el.title, centerList, type) : ''}>{el.label}{el.sort ? <i class="fa fa-sort ml-2" style={{ color: 'grey' }} aria-hidden="true"></i> : ''}</th>
                                    )
                                })
                            }
                        </tr>
                    </thead>
                    <tbody className="table-body">
                        {
                            centerList !== "" && centerList !== undefined && centerList !== null && centerList.length > 0 ?
                                centerList.map((center, index) => {
                                    return (
                                        <tr key={index}>
                                            {
                                                // eslint-disable-next-line array-callback-return
                                                header.map((el, i) => {
                                                    if (el.title === Object.keys(center)[i]) {
                                                        return (
                                                            type === 'school' ?
                                                                < td style={{ textAlign: i === 0 ? '' : 'center', cursor: 'auto', color: '#696873' }}   >
                                                                    {center[Object.keys(center)[i]]}
                                                                </td> :
                                                                <td style={{ textAlign: i === 0 ? 'left' : 'center', width: type === 'section' && i === 2 ? '10%' : 'auto' }} title={(el.title === 'count' && type === 'course') ? `${center['specialization']}` : null} onClick={() => i === 0 ? openIndividualItem(type, center) : null}  >
                                                                    {el.title === 'semester' ? 'S' + center[Object.keys(center)[i]] : ((type === 'semester' || type === 'center' || type === 'program' || type === 'section') && el.title === 'avg_score') ? (center[Object.keys(center)[i]] && center[Object.keys(center)[i]]?.toFixed(1)) : (el.title === 'avg_score' && type === 'exam') ? `${center[Object.keys(center)[i]]}%` : center[Object.keys(center)[i]]}
                                                                </td>
                                                        )
                                                    }
                                                })
                                            }
                                        </tr>
                                    )
                                })
                                :
                                null
                        }
                    </tbody>
                </Table>
            </div>
        </div >
    )
}
