import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { rootReducer } from "./Reducers/index";


// const logger = store => next => action => {
//     let result = next(action)
//     console.log('next state', store.getState())
//     return result
// }

// const crashReporter = store => next => action => {
//     try {
//         return next(action)
//     } catch (err) {
//         console.error('Caught an exception!', err)
//         throw err
//     }
// }

const middleware = [
    ...getDefaultMiddleware({
        thunk: true
    }),
    // logger, crashReporter
];

const store = configureStore({
    reducer: rootReducer,
    middleware,
    devTools: process.env.NODE_ENV !== "production",
});




export default store;