import React, { Fragment, useEffect, useState } from "react";
import Snackbar from '@mui/material/Snackbar';
import { useSelector } from "react-redux";
import { Editor, OptionEditor, ExplanationEditor } from '../Manage-Add-Content/rte';
import JoditEditor from 'jodit-react'
import Select, { components } from 'react-select';


const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <img src='/images/select-indicator.svg' alt='' />
        </components.DropdownIndicator>
    );
};


export default function AddQuestion(props) {
    const [id, setId] = useState('');
    const [title, setTitle] = useState('');
    const [quesType, setQuesType] = useState('mcq');
    const [choices, setChoices] = useState('');
    const [right_choice, setRightChoice] = useState('');
    const [explanation, setExplanation] = useState('');
    const [answer, setAnswer] = useState('');
    const [hints, setHints] = useState('');
    const [subject, setSubject] = useState(null);
    const [subjectId, setSubjectId] = useState('');
    const [topic, setTopic] = useState(null);
    const [topicId, setTopicId] = useState('');
    const [rightAnswerId, setRightAnswerId] = useState('');
    const [questionTitleError, setQuestionTitleError] = useState('');
    const [rightAnswerError, setRightAnswerError] = useState('');
    const [answerError, setAnswerError] = useState('');
    const [hintError, setHintError] = useState('');
    const [desc_char_lefthint, setDescCharLeft] = useState('');
    const [option1Error, setOption1Error] = useState('');
    const [option2Error, setOption2Error] = useState('');
    const [option3Error, setOption3Error] = useState('');
    const [option4Error, setOption4Error] = useState('');
    const [subjectError, setSubjectError] = useState('');
    const [topicError, setTopicError] = useState('');

    const [successMsg, setSuccessMsg] = useState(false);
    const [successMessageText, setSuccessMessageText] = useState('');


    const { qTitle, choice1, choice2, choice3, choice4, qExplanation } = useSelector(state => state.manageContent);


    const max_desc_char = 1000
    const desc_char_leftAns = 1000
    const editorRef = React.useRef();

    useEffect(() => {
        const firstRender = async () => {
            await props.resetPercentage();
            const { questions, edit } = props;
            if (questions !== undefined && edit) {
                setId(questions._id);
                setQuesType(questions?.type);
                setTitle(questions?.description);
                setChoices(questions?.options);
                setRightChoice(questions.answer?._id);
                setExplanation(questions?.explanation);
                // setTags(!questions.tags ? [] : renameArrProperties(questions.tags));
                // setTagsTitle(!questions.tags ? [] : questions.tags);
                setTopic(!questions.chapter ? '' : { label: questions.chapter?.name, value: questions.chapter?._id });
                setTopicId(!questions.chapter ? '' : questions.chapter._id);
                setSubject(!questions.subject ? '' : { label: questions.subject?.name, value: questions.subject?._id });
                setSubjectId(!questions.subject ? '' : questions.subject?._id);
                setHints(questions?.hints);
                setAnswer(questions?.answer);
                if (questions?.subject?._id) {
                    props.getChapterOfSub(questions?.subject?._id);
                }
            }
        }
        firstRender()
    }, [])




    const selectRightChoice = (val) => {
        setRightChoice(val);
        setRightAnswerError('');
    };





    const setSubjectiveAnswer = (val) => {
        const charCount = val.length;
        const maxChar = max_desc_char;
        const charLength = maxChar - charCount;
        setAnswer(val);
        setAnswerError('');
        setDescCharLeft(charLength);
    };

    const setSubjectiveHints = (val) => {
        setHints(val);
        setHintError('');
    };

    const selectSubject = (e) => {
        setSubject(e);
        setSubjectId(e.value);
        setSubjectError('');
        setTopic(null);
        setTopicError('');
        props.getChapterOfSub(e.value)
    }

    const selectChapter = (e) => {
        setTopic(e);
        setTopicId(e?.value);
        setTopicError('');
    }

    const saveQuestion = async () => {
        if (quesType === "mcq") {
            if (qTitle.trim() === "" || null) {
                setQuestionTitleError(`Question title  can't empty`)
            }
            else if (choice1 === "") {
                setOption1Error(`option  can't empty`)
            } else if (choice2.trim() === "") {
                setOption2Error(`option  can't empty`)
            } else if (choice3.trim() === "") {
                setOption3Error(`option  can't empty`)
            } else if (choice4.trim() === "") {
                setOption4Error(`option  can't empty`);
            }
            else if (right_choice === "") {
                setRightAnswerError(`Answer can't empty`)
            }
            else if (subjectId === '') {
                setSubjectError('Please select subject');
            }
            else if (topicId === '') {
                setTopicError('Please select topic');
            }

            else {
                const obj = {
                    description: qTitle,
                    type: quesType,
                    options: [
                        choice1,
                        choice2,
                        choice3,
                        choice4
                    ],
                    right_option: right_choice,
                    explanation: qExplanation,
                    isAddQuiz: props.isAddfromQuiz,
                    subject_id: subjectId,
                    chapter_id: topicId,
                };
                const res = await props.savedQuestions(obj);
                if (res?.type === 'ADD_QUESTION') {
                    await props.backToMain();
                    await props.resetPercentage();
                    await props.getClubbedQuestionList();
                }
            }
        }
        else {
            if (qTitle.trim() === "" || null) {
                setQuestionTitleError(`Question title  can't empty`)
            }
            else if (hints.trim() === "" || null) {
                setHintError(`Hints  can't empty`);
            } else if (answer.trim() === "" || null) {
                setAnswerError(`Answer  can't empty`);
            } else {
                const obj = {
                    description: qTitle,
                    type: quesType,
                    answer: answer,
                    explanation: hints,
                    isAddQuiz: props.isAddfromQuiz,
                    subject_id: subjectId,
                    chapter_id: topicId,
                    // tags: tagsTitle
                };
                const res = await props.savedQuestions(obj);
                if (res?.type === 'ADD_QUESTION') {
                    await props.backToMain();
                    await props.resetPercentage();
                }
            }
        }

    };



    const onEditOption = (key, id, value) => {
        if (key === 0) {
            const answersns = choices?.map(answer => {
                if (answer._id === id) return { _id: answer._id, title: value }
                return answer
            })
            setChoices(answersns);
            setOption1Error('');
        }
        else if (key === 1) {
            const answersns = choices.map(answer => {
                if (answer._id === id) return { _id: answer._id, title: value }
                return answer
            })
            setChoices(answersns);
            setOption2Error('');
        }
        else if (key === 2) {
            const answersns = choices.map(answer => {
                if (answer._id === id) return { _id: answer._id, title: value }
                return answer
            })
            setChoices(answersns);
            setOption3Error('');
        }
        else if (key === 3) {
            const answersns = choices.map(answer => {
                if (answer._id === id) return { _id: answer._id, title: value }
                return answer
            })
            setChoices(answersns);
            setOption3Error('');
        }
    }

    const editRightChoice = (val) => {
        setRightChoice(val);
        setRightAnswerError('');
        setRightAnswerId(choices.findIndex(el => el._id === val) + 1);
    }

    const editQuestion = async () => {
        if (qTitle === "") {
            setQuestionTitleError(`Question Title can't empty`);
        }
        if (quesType === 'mcq') {
            if (choices === "") {
                setOption1Error(`option can't empty`);
            }
            else {
                const obj = {
                    question_id: id,
                    description: qTitle,
                    options: choices,
                    explanation: qExplanation,
                    isAddQuiz: props.isAddfromQuiz,
                    subject_id: subjectId,
                    right_option: right_choice,
                    chapter_id: topicId
                };
                await props.editedQuestion(obj);
            }
        }
        else {
            if (hints.trim() === "" || null) {
                setHintError(`Hints  can't empty`)
            }
            else if (answer.trim() === "" || null) {
                setAnswerError(`Answer  can't empty`);
            }
            else {

                const obj = {
                    _id: id,
                    description: qTitle,
                    answer: answer,
                    hints: hints,
                    isAddQuiz: props.isAddfromQuiz,
                    subject_id: subjectId,
                    chapter_id: topicId
                };
                await props.editedQuestion(obj);
            }
        }
        props.backToMain()

    }

    const snackClosed = () => {
        setSuccessMsg(false);
        setSuccessMessageText('');
    }


    const { edit, type } = props;

    const AuthStr = "Bearer " + localStorage.getItem("loginToken");
    const config = {
        toolbarInlineForSelection: true,
        toolbar: true,
        tabIndex: 1,
        placeholder: '',
        readonly: false,// all options from https://xdsoft.net/jodit/doc/
        statusbar: false,
        height: 50,
        minHeight: 100,
        showCharsCounter: false,
        showWordsCounter: false,
        uploader: {
            url: `${process.env.REACT_APP_API_KEY}admin/resource/question/image/upload`,
            headers: { Authorization: AuthStr },
            filesVariableName: function (e) {
                return "image";
            },
            insertImageAsBase64URI: false,
            prepareData: function (data) {
                return data;
            },
            isSuccess: function (resp) {
                return resp;
            },
            process: function (resp) {
                return {
                    files: resp.data.image_url,
                    path: resp.data.image_url,
                    baseurl: resp.data.image_url,
                    error: resp.error,
                    message: resp.message,
                };
            },

            defaultHandlerSuccess: function (data) {
                if (this.componentName === 'jodit-uploader') {
                    this.jodit.selection.insertImage(data.baseurl, null, 200);
                }
                else {
                    this.selection.insertImage(data.baseurl, null, 200);
                }
            }
        },
        autoFocus: true,
        removeButtons: ['eraser', 'fullsize'],
        askBeforePasteFromWord: false,
        askBeforePasteHTML: false,
        defaultActionOnPaste: 'insert_clear_html',
        defaultActionOnPasteFromWord: 'insert_as_html',
        enableDragAndDropFileToEditor: false,
        usePopupForSpecialCharacters: true,
        // cleanHTML: { removeEmptyElements: false, },
        sizeLG: 900,
        sizeMD: 700,
        sizeSM: 400,
        buttons: [
            'image',
        ],
        buttonsMD: [
            'image',
        ],
        buttonsSM: [
            'image',
        ],
        buttonsXS: [
            'image',
        ],
    }
    return (
        <Fragment>

            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
                open={successMsg}
                onClose={snackClosed}
                message={successMessageText}
                autoHideDuration={4000}
            />

            {
                <div div className={window.location.pathname.split('/')[2] === 'subtopic' || window.location.pathname.split('/')[1] === 'my-content' ? "add-subject add-qes-wrapper" : "add-subject"}>
                    <h2 onClick={() => {
                        props.backToMain()
                    }}>
                        <img src="/images/back.svg" width="20px" alt="" /> &nbsp;&nbsp;
                        {!edit ? '  Add Questions' : '  Edit Questions'}
                    </h2>
                    <p>Fields marked * are mandatory</p>
                    <div className="mt-3">
                        <label>Question Type*</label>
                        <div className="duration d-flex mt-2">
                            <select
                                style={{ width: "25%" }}
                                className="form-control"
                                value={quesType}
                                onChange={(e) => setQuesType(e.target.value)}
                                disabled={edit}
                            >
                                <option value="mcq">Multiple choice question</option>
                                <option value="essay">Long Answer </option>
                            </select>
                        </div>
                    </div>

                    <div className="form-group mt-3">
                        <label style={{ width: '65%', marginBottom: 8 }}>
                            Question Title*
                        </label>
                        <Editor content={title} edit={props.edit} type='title' />
                        <small>{!qTitle && questionTitleError}</small>
                    </div>

                    {edit && quesType === "mcq" ?
                        (
                            <>
                                {choices && choices?.map((el, index) => {
                                    return (
                                        <div key={el._id} className="form-group mt-2">
                                            <label style={{ width: '65%' }}>
                                                Option {index + 1}*
                                            </label>
                                            <div className='options-holder w-100 mt-2'>
                                                <JoditEditor
                                                    ref={editorRef}
                                                    value={el.title}
                                                    config={config}
                                                    tabIndex={1} // tabIndex of textarea
                                                    onBlur={newContent => onEditOption(index, el._id, newContent)} // preferred to use only this option to update the content for performance reasons
                                                    onChange={newContent => { }}
                                                />
                                            </div>
                                            <small>{option1Error}</small>
                                        </div>
                                    )

                                })
                                }
                                <div className="mt-3">
                                    <label style={{ width: '65%' }}>Right Choice*</label>
                                    <div className="duration d-flex mt-2">
                                        <select
                                            className="form-control select-choice"
                                            style={{ width: "65%" }}
                                            value={right_choice}
                                            onChange={(e) => editRightChoice(e.target.value)}
                                        >
                                            <option hidden>Select right Choice</option>
                                            {
                                                choices &&
                                                choices?.map((el, index) => {
                                                    return (
                                                        <option key={el._id} value={el._id}>Option {index + 1}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <small>{rightAnswerError}</small>
                                </div>
                                <div className="form-group mt-3">
                                    <label style={{ width: '65%', marginBottom: 8 }}>
                                        Explanation
                                    </label>
                                    <ExplanationEditor content={explanation} edit={props.edit} type='explanation' />
                                </div>
                            </>
                        )
                        :

                        (!edit && quesType === "mcq") ? (
                            <>

                                <div className="form-group mt-2">
                                    <label style={{ width: '65%' }}>
                                        Option 1*

                                    </label>
                                    <div className='options-holder w-100 mt-2'>
                                        <OptionEditor type='option1' />
                                    </div>

                                    <small>{!choice1 && option1Error}</small>
                                </div>
                                <div className="form-group mt-2">
                                    <label style={{ width: '65%' }}>
                                        Option 2*

                                    </label>
                                    <div className='options-holder w-100 mt-2'>
                                        <OptionEditor type='option2' />
                                    </div>
                                    <small>{!choice2 && option2Error}</small>
                                </div>
                                <div className="form-group mt-2">
                                    <label style={{ width: '65%' }}>
                                        Option 3*

                                    </label>
                                    <div className='options-holder w-100 mt-2'>
                                        <OptionEditor type='option3' />
                                    </div>
                                    <small>{!choice3 && option3Error}</small>
                                </div>
                                <div className="form-group mt-2">
                                    <label style={{ width: '65%' }}>
                                        Option 4*

                                    </label>
                                    <div className='options-holder w-100 mt-2'>
                                        <OptionEditor type='option4' />
                                    </div>
                                    <small>{!choice4 && option4Error}</small>
                                </div>
                                <div className="mt-3">
                                    <label style={{ width: '65%' }}>Right Choice*</label>
                                    <div className="duration d-flex mt-2">
                                        <select
                                            className="form-control select-choice"
                                            style={{ width: "65%" }}
                                            value={right_choice}
                                            onChange={(e) => selectRightChoice(e.target.value)}
                                        >
                                            <option hidden>Select right Choice</option>
                                            <option value="1">Option 1</option>
                                            <option value="2">Option 2</option>
                                            <option value="3">Option 3</option>
                                            <option value="4">Option 4</option>
                                        </select>
                                    </div>
                                    <small>{rightAnswerError}</small>
                                </div>
                                <div className="form-group mt-3">
                                    <label style={{ width: '65%', marginBottom: 8 }}>
                                        Explanation

                                    </label>
                                    <ExplanationEditor content={explanation} edit={props.edit} type='explanation' />
                                </div>
                            </>
                        ) : quesType === "essay" ?
                            (
                                <>
                                    <div className="form-group mt-3">
                                        <label style={{ width: '65%' }}>
                                            Answer*
                                            <aside className="float-right">
                                                {desc_char_leftAns}
                                            </aside>
                                        </label>
                                        <textarea
                                            style={{ width: '65%' }}
                                            className="form-control mt-2"
                                            maxLength={max_desc_char}
                                            value={answer}
                                            onChange={(e) => setSubjectiveAnswer(e.target.value)}
                                        />
                                        <small>{answerError}</small>
                                    </div>
                                    <div className="form-group mt-3">
                                        <label style={{ width: '65%' }}>
                                            Hints*
                                            <aside className="float-right">
                                                {desc_char_lefthint}
                                            </aside>
                                        </label>
                                        <textarea
                                            style={{ width: '65%' }}
                                            className="form-control mt-2"
                                            maxLength={max_desc_char}
                                            value={hints}
                                            onChange={(e) => setSubjectiveHints(e.target.value)}
                                        />
                                        <small>{hintError}</small>
                                    </div>
                                </>
                            ) : null
                    }
                    <div className="mt-2" style={{ width: '65%' }}>
                        <label>Subject*</label>
                        <div className="mt-2">
                            <Select
                                // isMulti
                                components={{ DropdownIndicator }}
                                placeholder='Select Subjects'
                                value={subject}
                                onChange={selectSubject}
                                options={props.selectFromSubject}
                            />
                        </div>
                        <small>{subjectError}</small>
                        <div className="mt-2 " >
                            <label>Unit*</label>
                            <div className="mt-2">
                                <Select
                                    components={{ DropdownIndicator }}
                                    placeholder='Select Chapter'
                                    value={topic}
                                    onChange={selectChapter}
                                    options={props.subjectChapters}
                                />
                            </div>
                            <small>{topicError}</small>
                        </div>
                    </div>
                    {/* <div className="mt-4" style={{ width: '65.5%' }}>
                        <label>Question Tags</label>
                        <p className='mt-1 mb-2'>Add search tags like unit,sub unit to help you easily find this question</p>
                        <CreatableSelect
                            components={{ DropdownIndicator }}
                            isSearchable
                            isMulti
                            placeholder='Select Tags'
                            value={tags}
                            onChange={selectTags}
                            options={props.subTopicsContent}
                        />
                    </div> */}

                    <hr />
                    <div className="save-btn mt-2">
                        {
                            !edit ?
                                <button onClick={() => saveQuestion()}>Save</button>
                                :
                                <button onClick={() => editQuestion()}>Save</button>
                        }
                        <button onClick={props.backToMain}>Cancel</button>
                    </div>
                </div >
            }
        </Fragment>
    )
}