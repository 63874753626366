import { replaceObjectInArray } from "../../../utils/miscellneous";
import {
    EDIT_ACCOUNT, GET_ACCOUNT_DETAILS, IS_ADD_SCHOOL, RESET_LIST, BACK_TO_ACCOUNT,
    GET_STATE_LIST, ADD_STATE, UPDATE_STATE_STATUS, UPDATE_STATE, GET_SCHOOL_LIST, ADD_SCHOOL, UPDATE_SCHOOL_STATUS, UPDATE_SCHOOL, DELETE_STATE_OR_SCHOOL,
    GET_FACULTY_LIST, ADD_USER,
    GET_COLORS, APPLY_THEME,
} from "../../Types/types";


let intialState = {
    accountDetails: {
        logo: "https://elite-logos.s3.ap-south-1.amazonaws.com/NTTF_1630820183829.png",
        name: "NTTF Schools"
    },
    centerList: [
        {
            "_id": "613355a6e11e2f5e9098bfc1",
            "active": true,
            "title": "Bangalore Electronic City (NEC)",
            "address": "NTTF Electronic city Centre,\n40/40A, Electronic City post,\nHosur Road,\nBangalore 560 100, Karnataka,\nPhone: 91-(0) 080-42623506\nFax: 91-(0) 80-28520562\nEmail: nec@nttf.co.in",
            "state": "Karnataka",
            "city": "Bangalore Electronic City",
            "pin": 560100
        },
        {
            "_id": "613355d5a4d07b5e5ace6034",
            "active": true,
            "title": "Bangalore Peenya (BTC)",
            "address": "Nettur Technical Training Foundation,\nPost Box No. 5857,#23/24,II Phase,\nPeenya Industrial Area,\nBangalore 560 058, Karnataka.\nPhone: 91-(0) 80 28397218 / 28371539",
            "state": "Karnataka",
            "city": "Bangalore Peenya",
            "pin": 560058
        },
        {
            "_id": "61335657e11e2f5e9098bfc2",
            "active": true,
            "title": "Jamshedpur (RNTC)",
            "address": "NTTF Technical Training Centre,\nRD TATA Technical Education Centre, Golmuri\nAgrico Post - 831009,\nJamshedpur, Jharkhand,\nPhone: +91 6203750361 / 6203750362\nFax: +91-(0) 657-2348749\nEmail: Rntc@Nttf.Co.In",
            "state": "Jharkhand",
            "city": "Jamshedpur",
            "pin": 831009
        },
        {
            "_id": "61335855acf0f95ea60e9267",
            "active": true,
            "title": "Vellore (VTC)",
            "address": "Nettur Technical Training Foundation,\nSurvey No. 195- 2D1 & 2B2, Vikkramasi Village, Katpadi to Gudiyatham main road,\nVaduganthangal Post,Katpadi Taluk,\nVellore District 632204\nPhone: 0416-2244017/ 7901386224\nEmail: Vtc@Nttf.Co.In",
            "state": "Tamilnadu",
            "city": "Vellore",
            "pin": 632204
        },
        {
            "_id": "615fa80c28469b774d8098a9",
            "active": true,
            "title": "Dharwad (DTC)",
            "address": "NTTF Technical Training Centre,\nPost Bag No. 29\nPune - Bangalore Road\nDharwad 580001, Karnataka\nPhone: 91-(0) 0836-2486548, 2747378\nFax: 91-(0) 0836-2748594\nEmail: Dtc@Nttf.Co.In",
            "state": "Karnataka",
            "city": "Dharwad",
            "pin": 580001
        },
        {
            "_id": "615fa84966db487781ab1b2e",
            "active": true,
            "title": "Burma Mines (BNTC)",
            "address": "NTTF Technical Training Centre at\nTATA steel technical institute,\nNamdih road, Near Durga Puja Ground,\nBurma mines\nJamshedpur, Jharkhand-831007\nPh: +91 9102924969 / 6203214501\nEmail : Bntc@Nttf.Co.In",
            "state": "Jharkhand",
            "city": "Jamshedpur",
            "pin": 831007
        },
        {
            "_id": "615fa88628469b774d8098aa",
            "active": true,
            "title": "Coimbatore (GNTC)",
            "address": "NTTF TECHNICAL TRAINING CENTRE,\nGKDITR CAMPUS, SANGOTHIPALAYAM, ARASUR P.O,\nCoimbatore 641407,Tamil Nadu\nPhone: +91 8870921555/ 9489086800\nEmail: gntc@nttf.co.in",
            "state": "Tamilnadu",
            "city": "Coimbatore",
            "pin": 641407
        },
        {
            "_id": "615fa8cd3518417769fd292f",
            "active": true,
            "title": "Gannavaram AP (GTC)",
            "address": "NTTF Technical Training Centre,\nDavajigudem\nGannavaram- 521101\nKrishna District,Andhra Pradesh\nPhone: +91-(0) 8676 252472\nFax: +91-(0) 8676-252982\nEmail: Gtc@Nttf.Co.In",
            "state": "Andhra Pradesh",
            "city": "Gannavaram AP",
            "pin": 521101
        },
        {
            "_id": "615fa9073518417769fd2930",
            "active": true,
            "title": "Malappuram (INTC)",
            "address": "NTTF Technical training centre\nInkel Educity Campus,\nKarathode, Panakkad,\nOorakam-Melmuri PO,\nMalappuram – 676 519\nPH: 0483-2836636 / 8606490101 / 8606390101\nEmail: Intc@Nttf.Co.In",
            "state": "Kerala",
            "city": "Malappuram",
            "pin": 676519
        },
        {
            "_id": "615fa94228469b774d8098ab",
            "active": true,
            "title": "Gopalpur Odisha (JNTC)",
            "address": "NTTF Technical Training Centre,\nat J.N. TATA Tech. Education Centre,\nGopalpur, Jagannathpur, P.O\nNarendrapur, Ganjam Dist-760007, Odisha\nPhone: 8118057147 / 8118057148\nEmail: jntc@nttf.co.in",
            "state": "Odisha",
            "city": "Gopalpur",
            "pin": 760007
        },

    ],
    states: [],
    theme: (localStorage.getItem('theme')) ? (JSON.parse(localStorage.getItem('theme')) || {}) : { sidebarBgColor: '#333269', btnColor: '#5855d6', textColor: '#fff' },
    isListLoading: false,
    facultyList: [
        {
            "_id": "65a645fa4db0a89ec6e805fd",
            email: 'f1@email.com',
            "profile_image": null,
            "color": "#6457e8",
            "initial": "FO",
            "name": "Faculty One",
            "subjects": [
                {
                    "_id": "659f92937122112afd449e56",
                    "name": "Mathematics_Part 1(10th_Karnataka Board)"
                },
                {
                    "_id": "659f92b47122112afd449e6e",
                    "name": "Mathematics_Part 2(10th_Karnataka Board)"
                }
            ]
        },
        {
            "_id": "65a646124db0a89ec6e80607",
            email: 'f2@email.com',
            "profile_image": null,
            "color": "#6457e8",
            "initial": "FT",
            "name": "Faculty Two",
            "subjects": [
                {
                    "_id": "659f92de7122112afd449e81",
                    "name": "Science (10th_Karnataka Board)"
                },
                {
                    "_id": "659f93017122112afd449eb4",
                    "name": "English Part 1(10th Karnataka Board)"
                }
            ]
        },
        {
            "_id": "65a646204db0a89ec6e80611",
            email: 'f3@email.com',
            "profile_image": null,
            "color": "#6457e8",
            "initial": "FT",
            "name": "Faculty Three",
            "subjects": [
                {
                    "_id": "659f93017122112afd449eb4",
                    "name": "English Part 1(10th Karnataka Board)"
                }
            ]
        }
    ]
}


export default function accountReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_ACCOUNT_DETAILS:
            stateCopy.accountDetails = {
                logo: "https://elite-logos.s3.ap-south-1.amazonaws.com/NTTF_1630820183829.png",
                name: "NTTF Schools"
            };
            return stateCopy;
        case GET_STATE_LIST:
            stateCopy.states = action.payload;
            stateCopy.isListLoading = false;
            return stateCopy;
        case ADD_STATE:
            stateCopy.states = [...stateCopy.states, action.payload];
            return stateCopy;
        case UPDATE_STATE_STATUS:
            stateCopy.states.forEach(el => {
                if (el._id === action.payload.obj.state_id) {
                    el.active = action.payload.checked
                }
            })
            return stateCopy;
        case UPDATE_STATE:
            stateCopy.states = replaceObjectInArray(stateCopy.states, action.payload._id, action.payload);
            return stateCopy;
        case GET_SCHOOL_LIST:
            stateCopy.schools = action.payload;
            stateCopy.isListLoading = false;
            return stateCopy;
        case ADD_SCHOOL:
            stateCopy.schools = [...stateCopy.schools, action.payload];
            return stateCopy;
        case UPDATE_SCHOOL_STATUS:
            stateCopy.schools.forEach(el => {
                if (el._id === action.payload.obj.school_id) {
                    el.active = action.payload.checked
                }
            })
            return stateCopy;
        case UPDATE_SCHOOL:
            stateCopy.schools = replaceObjectInArray(stateCopy.schools, action.payload._id, action.payload);
            return stateCopy;
        case DELETE_STATE_OR_SCHOOL:
            if (action.payload.type === 'state') {
                stateCopy.states = stateCopy.states.filter(el => el._id !== action.payload.id);
            }
            else {
                stateCopy.schools = stateCopy.schools.filter(el => el._id !== action.payload.id);
            }
            return stateCopy;
        case IS_ADD_SCHOOL:
            stateCopy.isAddSchool = true;
            return stateCopy;
        case BACK_TO_ACCOUNT:
            stateCopy.isAddSchool = false;
            return stateCopy;
        case GET_FACULTY_LIST:
            stateCopy.facultyList = action.payload;
            stateCopy.isListLoading = false;
            return stateCopy;
        case ADD_USER:
            // stateCopy.facultyList = [...stateCopy.facultyList, action.payload];
            return stateCopy;
        case EDIT_ACCOUNT:
            stateCopy.successMessage = true;
            stateCopy.successMessageText = 'Campus details updated';
            return stateCopy;
        case RESET_LIST:
            stateCopy.isListLoading = true;
            return stateCopy;
        case GET_COLORS:
            stateCopy.theme = action.payload;
            localStorage.setItem('theme', JSON.stringify(action.payload));
            const root = document.documentElement;
            root.style.setProperty('--button-color', JSON.parse(localStorage.getItem('theme'))?.btnColor);
            root.style.setProperty('--sidebar-color', JSON.parse(localStorage.getItem('theme'))?.sidebarBgColor);
            return stateCopy;
        case APPLY_THEME:
            stateCopy.theme = action.payload;
            localStorage.setItem('theme', JSON.stringify(action.payload));
            return stateCopy;
        default:
            return stateCopy;
    }
}

