import React, { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { clearErrors } from '../../../Redux/ActionCreators/Common/errorAction';
import { useSelector } from 'react-redux';
import { getAffliations } from '../../../Redux/ActionCreators/Admin/manageContentAction';
import { fileToBase64URL } from '../../../utils/miscellneous';
import { addSchool, updateSchool, addState, updateState, addFaculty } from '../../../Redux/ActionCreators/Admin/accountAction';
import Select, { components } from 'react-select';
import { CompactPicker } from 'react-color';

const languages = [{ value: 'English', label: 'English' }, { value: 'Tamil', label: 'Tamil' }, { value: 'Kannada', label: 'Kannada' }]
const schools = [];
const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <img src='/images/select-indicator.svg' alt='' />
        </components.DropdownIndicator>
    );
};

const AddSchool = (props) => {
    const [inputFields, setInputFields] = useState({
        name: '', contact_name: '', contact_email: '', contact_phone: '', domain: '',
        affiliation_id: '', state_id: '', address: '', city: '', pincode: '', logo: '', logoImage: '/images/empty-image.svg'
    });
    const [inputError, setInputError] = useState({
        name: '', contact_name: '', contact_email: '', contact_phone: '', domain: '', affiliation_id: '', state_id: '',
        address: '', city: '', pincode: '', logo: ''
    })
    const [stateFields, setStateFields] = useState({
        name: '', contact_name: '', contact_email: '', contact_phone: '', domain: '', logo: '', logoImage: '/images/empty-image.svg', language: [], colors: { sidebarBgColor: '', btnColor: '' }
    });
    const [stateErrors, setStateErrors] = useState({
        name: '', contact_name: '', contact_phone: '', contact_email: '', domain: '', logo: ''
    })
    const [facultyFields, setFacultyFields] = useState({
        firstname: '', lastname: '', email: '', phone: '', role: '', class: ''
    });
    const [facultyErrors, setFacultyErrors] = useState({
        firstname: '', lastname: '', email: '', phone: '', role: '', class: ''
    })
    const [lang, setLang] = useState('');
    const [langError, setLangError] = useState('');
    const [images, setImages] = useState([{ quote: '', image: '', imageError: '', imageUrl: '/images/empty-image.svg', imageName: '' }, { quote: '', image: '', imageError: '', imageUrl: '/images/empty-image.svg', imageName: '' }, { quote: '', image: '', imageUrl: '/images/empty-image.svg', imageError: '', imageName: '' }]);
    const [imagesError, setImagesError] = useState([{ quote: '', image: '' }, { quote: '', image: '' }, { quote: '', image: '' }]);
    const [id, setId] = useState('');
    const [successMessageText, setsuccessMessageText] = useState('');
    const [successMsg, setSuccessMsg] = useState(false);
    const dispatch = useDispatch();
    const { affliations, selectFromSubject } = useSelector(state => state.manageContent);
    const { states } = useSelector(state => state.account);

    useEffect(() => {
        dispatch(getAffliations());
        const { details, edit } = props
        if (edit && details) {
            if (type === 'state') {
                setId(details._id);
                setStateFields({ ...details, logoImage: details.logo });
                setLang(details?.language?.map(el => [{ label: el, value: el }][0]));
                setImages(images.map((quote, index) => ({
                    quote: details.quotes[index],
                    image: details?.images[index] || '',
                    imageUrl: details?.images[index] ? details?.images[index] : '/images/empty-image.svg',
                    imageName: '',
                    imageError: ''
                })));

            }
            else if (type === 'school') {
                setId(details._id);
                setInputFields({ ...details, affiliation_id: details?.affiliation?._id, state_id: details?.state?._id, logoImage: details.logo });
                // const arr1 = details?.quotes?.map((quote, index) => ({
                //     quote,
                //     image: details?.images[index] || '',
                //     imageUrl: details?.images[index] ? details?.images[index] : '/images/empty-image.svg',
                //     imageName: '',
                //     imageError: ''
                // }));
                setImages(images.map((quote, index) => ({
                    quote: details.quotes[index],
                    image: details?.images[index] || '',
                    imageUrl: details?.images[index] ? details?.images[index] : '/images/empty-image.svg',
                    imageName: '',
                    imageError: ''
                })));

            }
        }
    }, [])



    const handleInputChange = (e) => {
        const { name, value, type } = e.target;
        // Update input values
        if (type === 'file') {
            if (e.target.files[0]?.size > 2097152) {
                setSuccessMsg(true);
                setsuccessMessageText("The file size cannot exceed 2MB")
            }
            else {
                const file = e.target.files[0]
                if (file) {
                    fileToBase64URL(file, (base64URL) => {
                        setInputFields((prevValues) => ({
                            ...prevValues,
                            [name]: file,
                            logoImage: base64URL
                        }));
                    });

                }
            }

        } else {
            setInputFields((prevValues) => ({
                ...prevValues,
                [name]: value,
            }));

        }

        setInputError((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    }

    const onChangeQuotes = (e, index) => {
        e.preventDefault();
        const { name, value, type } = e.target;
        if (type === 'file') {
            if (e.target.files[0]?.size > 2097152) {
                setSuccessMsg(true);
                setsuccessMessageText("The file size cannot exceed 2MB")
            }
            else {
                const file = e.target.files[0]
                if (file) {
                    fileToBase64URL(file, (base64URL) => {
                        setImages((prevData) => {
                            const newData = [...prevData];
                            newData[index] = {
                                ...newData[index], [name]: e.target.files[0], imageUrl: base64URL,
                                imageName: file.name
                            };
                            return newData;
                        });
                    });

                }
            }

        } else {
            setImages((prevData) => {
                const newData = [...prevData];
                newData[index] = { ...newData[index], [name]: value };
                return newData;
            });
        }

        setImagesError((prevData) => {
            const newData = [...prevData];
            newData[index] = { ...newData[index], [name]: '' };
            return newData;
        });
    }

    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
        else if (object.target.value.length < object.target.maxLength) {
            setInputError((prevErrors) => ({
                ...prevErrors,
                [object.target.name]: 'Please enter correct number',
            }));
            setFacultyErrors((prevErrors) => ({
                ...prevErrors,
                [object.target.name]: 'Please enter correct number',
            }));
        }
        else if (object.target.value.length === object.target.maxLength) {
            setInputError((prevErrors) => ({
                ...prevErrors,
                pincode: '',
            }));
            setFacultyErrors((prevErrors) => ({
                ...prevErrors,
                [object.target.name]: 'Please enter correct number',
            }));
        }
    }

    const saveSchool = async (e, edit) => {
        e.preventDefault();

        // Validation
        const newErrors = {};
        Object.keys(inputFields).forEach((key) => {
            if (key === 'logo') {
                if (!inputFields[key]) {
                    newErrors[key] = 'Logo is required.';
                }
            } else if (inputFields[key] === '') {
                newErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} is required.`;
            }
        });

        // const newImagesError = images.map((image, index) => {
        //     const imageError = {};
        //     if (image.quote.trim() === '') {
        //         imageError.quote = 'Quote is required.';
        //     } else {
        //         imageError.quote = '';
        //     }
        //     if (image?.image === '') {
        //         imageError.image = 'Image is required.';
        //     }
        //     else {
        //         imageError.image = '';
        //     }
        //     return imageError;
        // });
        // && !newImagesError.some((error) => error.quote !== '' || error.image !== '')


        setInputError(newErrors);
        // setImagesError(newImagesError);
        if ((Object.keys(newErrors).length === 0)) {
            const formData = new FormData();
            const data = { ...inputFields, school_id: edit ? id : '', quotes: images.map(el => el.quote), images: images.map(el => el.image) };

            for (const key in data) {

                if ((key !== 'logoImage') && (key !== '_id')) {
                    if (Array.isArray(data[key])) {
                        if (key === 'images') {
                            data['images'].forEach((file, index) => {
                                formData.append(`images`, file);
                            });
                        } else {
                            formData.append(key, JSON.stringify(data[key]));
                        }
                    } else {
                        // Handle other properties
                        formData.append(key, data[key]);
                    }
                }
            }
            if (!edit) {
                const res = await dispatch(addSchool(formData));
                if (res?.type === 'ADD_SCHOOL') {
                    await props.getSchools();
                    await props.backToMain();
                }
            } else {
                const res = await dispatch(updateSchool(formData));
                if (res?.type === 'UPDATE_SCHOOL') {
                    await props.getSchools();
                    await props.backToMain();
                }
            }

        }
    }



    // states

    const selectLanguages = (e) => {
        setLang(e);
        setLangError('');
    }
    const handleChangeColor = (val, type) => {
        if (type === 'header') {
            setStateFields((prevState) => ({
                ...prevState,
                colors: {
                    ...prevState.colors,
                    sidebarBgColor: val,
                }
            }));
        } else {
            setStateFields((prevState) => ({
                ...prevState,
                colors: {
                    ...prevState.colors,
                    btnColor: val,
                }
            }));
        }
    }

    const handleStateChange = (e) => {
        const { name, value, type } = e.target;
        // Update input values
        if (type === 'file') {
            if (e.target.files[0]?.size > 2097152) {
                setSuccessMsg(true);
                setsuccessMessageText("The file size cannot exceed 2MB")
            }
            else {
                const file = e.target.files[0]
                if (file) {
                    fileToBase64URL(file, (base64URL) => {
                        setStateFields((prevValues) => ({
                            ...prevValues,
                            [name]: file,
                            logoImage: base64URL
                        }));
                    });

                }
            }

        } else {
            setStateFields((prevValues) => ({
                ...prevValues,
                [name]: value,
            }));

        }

        setStateErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    }

    const saveState = async (e, edit) => {
        e.preventDefault();
        const newErrors = {};
        Object.keys(stateFields).forEach((key) => {
            if (key === 'logo') {
                if (!stateFields[key]) {
                    newErrors[key] = 'Logo is required.';
                }
            } else if (stateFields[key] === '') {
                newErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} is required.`;
            }
        });

        // const newImagesError = images.map((image, index) => {
        //     const imageError = {};
        //     if (image.quote.trim() === '') {
        //         imageError.quote = 'Quote is required.';
        //     } else {
        //         imageError.quote = '';
        //     }
        //     if (image?.image === '') {
        //         imageError.image = 'Image is required.';
        //     }
        //     else {
        //         imageError.image = '';
        //     }
        //     return imageError;
        // });
        // !newImagesError.some((error) => error.quote !== '' || error.image !== '')

        setStateErrors(newErrors);
        // setImagesError(newImagesError);
        if (lang === '') {
            setLangError('Please select Language')
        } else {
            if ((Object.keys(newErrors).length === 0)) {

                const data = { ...stateFields, state_id: edit ? id : '', language: lang?.map(el => el.value), quotes: images.map(el => el.quote), images: images.map(el => el.image) }

                const formData = new FormData();
                for (const key in data) {
                    if (key !== 'logoImage') {
                        if (Array.isArray(data[key])) {
                            if (key === 'images') {
                                data['images'].forEach((file, index) => {
                                    formData.append(`images`, file);
                                });
                            } else {
                                formData.append(key, JSON.stringify(data[key]));
                            }
                        } else if (key === 'colors') {
                            formData.append(key, JSON.stringify(data['colors']));
                        }
                        else {
                            formData.append(key, data[key]);
                        }
                    }
                }
                if (!edit) {
                    const res = await dispatch(addState(formData));
                    if (res?.type === 'ADD_STATE') {
                        await props.getStates();
                        await props.backToMain();
                    }
                } else {
                    const res = await dispatch(updateState(formData));
                    if (res?.type === 'UPDATE_STATE') {
                        await props.getStates();
                        await props.backToMain();
                    }
                }
            }
        }
    }

    //faculty
    const handleFacultyChange = (e) => {
        const { name, value, type } = e.target;
        // Update input values

        setFacultyFields((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));


        setFacultyErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    }

    const saveFaculty = async (e, edit) => {
        e.preventDefault();
        const newErrors = {};
        Object.keys(facultyFields).forEach((key) => {
            if (facultyFields[key] === '') {
                newErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} is required.`;
            }
        });

        setFacultyErrors(newErrors);
        // setImagesError(newImagesError);
        if ((Object.keys(newErrors).length === 0)) {

            const data = { ...facultyFields, faculty_id: edit ? id : '' }

            if (!edit) {
                const res = await dispatch(addFaculty(data));
                if (res?.type === 'ADD_USER') {
                    await props.backToMain();
                }
            } else {
                // const res = await dispatch(updateState(formData));
                // if (res?.type === 'UPDATE_STATE') {
                //     await props.getStates();
                //     await props.backToMain();
                // }
            }
        }
    }


    const snackClosed = () => {
        dispatch(clearErrors());
    }


    const { edit, type } = props
    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={successMsg}
                onClose={snackClosed}
                autoHideDuration={4000}
            >
                <Alert onClose={snackClosed} severity="success" sx={{ width: '100%' }}>
                    {successMessageText}
                </Alert>
            </Snackbar>
            {type === 'school' ?
                <div className='add-center'>
                    <div className='d-flex justify-content-between align-items-center' style={{ width: '60%' }}>
                        <h2 onClick={props.backToMain}><img src='/images/back.svg' width='20px' alt='' /> </h2>
                        <h2>{edit ? 'Edit School' : 'New School'}</h2>
                    </div>
                    <p className='mt-4'>Fields marked * are mandatory</p>
                    <div className='form-group mt-2'>
                        <label>School Name*
                        </label>
                        <input className='form-control mt-2' style={{ width: window.innerWidth > 767 ? '86%' : '100%' }} name='name' value={inputFields.name} onChange={(e) => handleInputChange(e)} />
                        <small>{inputError.name}</small>
                    </div>
                    <div className='row' style={{ width: window.innerWidth > 767 ? '89%' : '100%', }}>
                        <div className='col-md-4'>
                            <div className='form-group mt-2'>
                                <label>Contact name*
                                </label>
                                <input className='form-control mt-2 ' name='contact_name' value={inputFields.contact_name} onChange={(e) => handleInputChange(e)} />
                                <small>{inputError.contact_name}</small>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='form-group mt-2'>
                                <label>Contact number*
                                </label>
                                <input type='number' className='form-control mt-2 no-spin ' maxLength='10' onInput={maxLengthCheck} value={inputFields.contact_phone} name='contact_phone' onChange={(e) => handleInputChange(e)} />
                                <small>{inputError.contact_phone}</small>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='form-group mt-2'>
                                <label>Contact email*
                                </label>
                                <input className='form-control mt-2 ' value={inputFields.contact_email} name='contact_email' onChange={(e) => handleInputChange(e)} />
                                <small>{inputError.contact_email}</small>
                            </div>
                        </div>
                    </div>
                    <div className='row' style={{ width: window.innerWidth > 767 ? '89%' : '100%', }}>
                        <div className='col-md-4'>
                            <div className='form-group mt-2'>
                                <label>Domain*
                                </label>
                                <input className='form-control mt-2 ' value={inputFields.domain} name='domain' onChange={(e) => handleInputChange(e)} />
                                <small>{inputError.domain}</small>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='form-group mt-2'>
                                <label>Affiliation*
                                </label>
                                <select className='form-control mt-2 ' value={inputFields.affiliation_id} name='affiliation_id' onChange={(e) => handleInputChange(e)} >
                                    <option hidden>Select Affiliation</option>
                                    {
                                        affliations?.map((el) => {
                                            return (
                                                <option value={el._id}>{el.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                <small>{inputError.affiliation_id}</small>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='form-group mt-2'>
                                <label>State*
                                </label>
                                <select className='form-control mt-2 ' value={inputFields.state_id} name='state_id' onChange={(e) => handleInputChange(e)} >
                                    <option hidden>Select State</option>
                                    {
                                        states?.map((el) => {
                                            return (
                                                <option value={el._id}>{el.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                <small>{inputError.state_id}</small>
                            </div>
                        </div>
                    </div>
                    <div className='row' style={{ width: window.innerWidth > 767 ? '89%' : '100%', }}>
                        <div className='col-md-4'>
                            <div className='form-group mt-2'>
                                <label>City*
                                </label>
                                <input className='form-control mt-2 ' value={inputFields.city} name='city' onChange={(e) => handleInputChange(e)} />
                                <small>{inputError.city}</small>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='form-group mt-2'>
                                <label>pinCode*
                                </label>
                                <input type='number' maxLength='6' onInput={maxLengthCheck} className='form-control mt-2 no-spin' name='pincode' value={inputFields.pincode} onChange={(e) => handleInputChange(e)} />
                                <small>{inputError.pincode}</small>
                            </div>
                        </div>
                    </div>
                    <div className='image-upload mt-3'>
                        <label>School Logo*</label>
                        <div className='d-flex align-items-center mb-1'>
                            {
                                inputFields?.logoImage ?
                                    <div className='program-image'>
                                        <img src={inputFields.logoImage} alt='' width='139px' height='78px' />
                                    </div> :
                                    <div className='program-image'>
                                        <img src={inputFields.logoImage} alt='' />
                                    </div>
                            }

                            <div className='d-flex flex-column mt-3 position-relative'>
                                {/* <span><img src='/images/gallery-icon.svg' width='24px' alt='' /> &nbsp;Select From Gallery</span> */}
                                <span> <img src='/images/upload-icon.svg' alt='' /> &nbsp;Upload Logo &nbsp; <small></small>
                                    <input type='file' className='file-upload' name='logo'
                                        accept="image/png, image/jpeg" onChange={handleInputChange} />
                                </span>
                            </div>
                        </div>
                        <small>{inputError.logo}</small>
                    </div>
                    <div className='form-group mt-3'>
                        <label>Address
                        </label>
                        <textarea className='form-control mt-2' value={inputFields.address} name='address' onChange={(e) => handleInputChange(e)} />
                        <small>{inputError.address}</small>
                    </div>

                    {
                        images?.map((el, i) => {
                            return (
                                <div className='row mt-2'>
                                    <div className='col-md-6'>
                                        <div className='form-group mt-2'>
                                            <label>Quote {i + 1}*
                                            </label>
                                            <textarea className='form-control mt-2' name='quote' rows={3} value={el.quote} onChange={(e) => onChangeQuotes(e, i)} />

                                            <small>{imagesError[i].quote}</small>                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='image-upload mt-3'>
                                            <label>Login Image {i + 1}*</label>
                                            <div className='d-flex align-items-center '>

                                                <div className='d-flex flex-column mt-3 position-relative'>
                                                    {/* <span><img src='/images/gallery-icon.svg' width='24px' alt='' /> &nbsp;Select From Gallery</span> */}
                                                    <span> <img src='/images/upload-icon.svg' alt='' /> &nbsp;Upload Image &nbsp; <small></small>
                                                        <input type='file' className='file-upload' name='image'
                                                            accept="image/png, image/jpeg" onChange={(e) => onChangeQuotes(e, i)} />
                                                    </span>
                                                </div>
                                                {
                                                    el.image ?
                                                        <div className='program-image'>
                                                            <img src={el.imageUrl} alt='' width='139px' height='78px' />
                                                        </div> :
                                                        <div className='program-image'>
                                                            <img src={el.imageUrl} alt='' />
                                                        </div>
                                                }

                                            </div>
                                            <small>{imagesError[i].image}</small>
                                        </div>
                                    </div>


                                </div>
                            )
                        })
                    }
                    {
                        localStorage.getItem('role').includes('super-admin') && edit ?
                            <div className='row' style={{ width: '89.5%', marginBottom: 50 }}>
                                <div className='col-md-4'>
                                    <div className='form-group mt-2'>
                                        <label>School Id*
                                        </label>
                                        <input className='form-control mt-2' value={id} disabled />
                                    </div>
                                </div>
                            </div> : null
                    }
                    <hr />
                    <div className='save-btn mt-2'>
                        {
                            edit ?
                                <button onClick={(e) => saveSchool(e, edit)} >Save</button>
                                :
                                <button onClick={(e) => saveSchool(e, edit)}>
                                    Save</button>

                        }
                        <button onClick={props.backToMain}>Cancel</button>
                    </div>
                </div> :
                type === 'faculty' ?
                    <div className='add-center'>
                        <div className='d-flex justify-content-between align-items-center' style={{ width: '60%' }}>
                            <h2 onClick={props.backToMain}><img src='/images/back.svg' width='20px' alt='' /> </h2>
                            <h2>{edit ? 'Edit Faculty' : 'New Faculty'}</h2>
                        </div>
                        <p className='mt-4'>Fields marked * are mandatory</p>
                        <div className='row' style={{ width: window.innerWidth > 767 ? '89%' : '100%', }}>
                            <div className='col-md-6'>
                                <div className='form-group mt-2'>
                                    <label>Faculty First Name*
                                    </label>
                                    <input className='form-control mt-2' name='firstname' value={facultyFields.firstname} onChange={(e) => handleFacultyChange(e)} />
                                    <small>{facultyErrors.firstname}</small>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group mt-2'>
                                    <label>Faculty Last Name*
                                    </label>
                                    <input className='form-control mt-2' name='lastname' value={facultyFields.lastname} onChange={(e) => handleFacultyChange(e)} />
                                    <small>{facultyErrors.lastname}</small>
                                </div>
                            </div>
                        </div>

                        <div className='row' style={{ width: window.innerWidth > 767 ? '89%' : '100%', }}>
                            <div className='col-md-6'>
                                <div className='form-group mt-2'>
                                    <label>Email*
                                    </label>
                                    <input className='form-control mt-2 ' name='email' value={facultyFields.email} onChange={(e) => handleFacultyChange(e)} />
                                    <small>{facultyErrors.email}</small>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group mt-2'>
                                    <label>Phone*
                                    </label>
                                    <input type='number' className='form-control mt-2 no-spin ' maxLength='10' onInput={maxLengthCheck} value={facultyFields.phone} name='phone' onChange={(e) => handleFacultyChange(e)} />
                                    <small>{facultyErrors.phone}</small>
                                </div>
                            </div>
                        </div>
                        <div className='row' style={{ width: window.innerWidth > 767 ? '89%' : '100%', }}>
                            <div className='col-md-6'>
                                <div className='form-group mt-2'>
                                    <label>Role*
                                    </label>
                                    <select className='form-control mt-2 ' value={facultyFields.role} name='role' onChange={(e) => handleFacultyChange(e)} >
                                        <option hidden>Select Role</option>
                                        <option value={'admin'}>Admin</option>
                                        <option value={'state admin'}>State Admin</option>
                                        <option value={'faculty'}>Faculty</option>
                                    </select>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group mt-2'>
                                    <label>Class*
                                    </label>
                                    <select className='form-control mt-2 ' value={facultyFields.class} name='class' onChange={(e) => handleFacultyChange(e)} >
                                        <option hidden>Select Class</option>
                                        <option value='9'>Class 9</option>
                                        <option value='10'>Class 10</option>
                                    </select>
                                    <small>{facultyErrors.class}</small>
                                </div>
                            </div>
                        </div>
                        {/* <div className='col-md-4'>
                                <div className='form-group mt-2'>
                                    <label>State*
                                    </label>
                                    <select className='form-control mt-2 ' value={facultyFields.state_id} name='state_id' onChange={(e) => handleInputChange(e)} >
                                        <option hidden>Select State</option>
                                        {
                                            states?.map((el) => {
                                                return (
                                                    <option value={el._id}>{el.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <small>{facultyErrors.state_id}</small>
                                </div>
                            </div> */}
                        {/* <div className='row' style={{ width: window.innerWidth > 767 ? '89%' : '100%', }}>

                            <div className='col-md-4'>
                                <div className='form-group mt-2'>
                                    <label>School*
                                    </label>
                                    <select className='form-control mt-2 ' value={facultyFields.school_id} name='state_id' onChange={(e) => handleInputChange(e)} >
                                        <option hidden>Select School</option>
                                        {
                                            schools?.map((el) => {
                                                return (
                                                    <option value={el._id}>{el.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <small>{facultyErrors.school_id}</small>
                                </div>
                            </div>
                            <div className='col-md-8'>
                                <div className='form-group mt-2'>
                                    <label>Subject*</label>
                                    <div className="mt-2">
                                        <Select
                                            name={facultyFields?.subject_ids}
                                            isMulti
                                            components={{ DropdownIndicator }}
                                            placeholder='Select Subjects'
                                            // value={subject}
                                            // onChange={selectSubject}
                                            options={selectFromSubject}
                                        />
                                    </div>
                                </div>
                                <small>{facultyErrors?.subject_ids}</small>
                            </div>
                        </div> */}

                        {
                            localStorage.getItem('role').includes('super-admin') && edit ?
                                <div className='row' style={{ width: '89.5%', marginBottom: 50 }}>
                                    <div className='col-md-4'>
                                        <div className='form-group mt-2'>
                                            <label>Faculty Id*
                                            </label>
                                            <input className='form-control mt-2' value={id} disabled />
                                        </div>
                                    </div>
                                </div> : null
                        }
                        <hr />
                        <div className='save-btn mt-2'>
                            {
                                edit ?
                                    <button onClick={(e) => saveFaculty(e, edit)} >Save</button>
                                    :
                                    <button onClick={(e) => saveFaculty(e, edit)}>
                                        Save</button>

                            }
                            <button onClick={props.backToMain}>Cancel</button>
                        </div>
                    </div> :
                    <div className='add-center'>
                        <div className='d-flex justify-content-between align-items-center' style={{ width: '60%' }}>
                            <h2 onClick={props.backToMain}><img src='/images/back.svg' width='20px' alt='' /> </h2>
                            <h2>{edit ? 'Edit State' : 'New State'}</h2>
                        </div>
                        <p className='mt-4'>Fields marked * are mandatory</p>
                        <div className='form-group mt-2'>
                            <label>State Name*
                            </label>
                            <input className='form-control mt-2' style={{ width: window.innerWidth > 767 ? '86%' : '100%' }} name='name' value={stateFields.name} onChange={(e) => handleStateChange(e)} />
                            <small>{stateErrors.name}</small>
                        </div>
                        <div className='row' style={{ width: window.innerWidth > 767 ? '89%' : '100%', }}>
                            <div className='col-md-4'>
                                <div className='form-group mt-2'>
                                    <label>Language*
                                    </label>
                                    <Select
                                        className='mt-2'
                                        isMulti
                                        components={{ DropdownIndicator }}
                                        options={languages}
                                        onChange={selectLanguages}
                                        placeholder='Select Language'
                                        value={lang}
                                    />
                                </div>
                                <small>{langError}</small>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group mt-2'>
                                    <label>Contact name*
                                    </label>
                                    <input className='form-control mt-2 ' name='contact_name' value={stateFields.contact_name} onChange={(e) => handleStateChange(e)} />
                                    <small>{stateErrors.contact_name}</small>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group mt-2'>
                                    <label>Contact email*
                                    </label>
                                    <input className='form-control mt-2 ' value={stateFields.contact_email} name='contact_email' onChange={(e) => handleStateChange(e)} />
                                    <small>{stateErrors.contact_email}</small>
                                </div>
                            </div>


                        </div>
                        <div className='row' style={{ width: window.innerWidth > 767 ? '89%' : '100%', }}>
                            <div className='col-md-4'>
                                <div className='form-group mt-2'>
                                    <label>Contact number*
                                    </label>
                                    <input type='number' className='form-control mt-2 no-spin ' maxLength='10' onInput={maxLengthCheck} value={stateFields.contact_phone} name='contact_phone' onChange={(e) => handleStateChange(e)} />
                                    <small>{stateErrors.contact_phone}</small>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group mt-2'>
                                    <label>Domain*
                                    </label>
                                    <input className='form-control mt-2 ' value={stateFields.domain} name='domain' onChange={(e) => handleStateChange(e)} />
                                    <small>{stateErrors.domain}</small>
                                </div>
                            </div>
                        </div>
                        <div className='image-upload mt-3 '>
                            <label>State Logo*</label>
                            <div className='d-flex align-items-center mb-1 '>
                                {
                                    inputFields?.logoImage ?
                                        <div className='program-image'>
                                            <img src={stateFields.logoImage} alt='' width='139px' height='78px' />
                                        </div> :
                                        <div className='program-image'>
                                            <img src={stateFields.logoImage} alt='' />
                                        </div>
                                }

                                <div className='d-flex flex-column mt-3 position-relative'>
                                    {/* <span><img src='/images/gallery-icon.svg' width='24px' alt='' /> &nbsp;Select From Gallery</span> */}
                                    <span> <img src='/images/upload-icon.svg' alt='' /> &nbsp;Upload Logo &nbsp; <small></small>
                                        <input type='file' className='file-upload' name='logo'
                                            accept="image/png, image/jpeg" onChange={handleStateChange} />
                                    </span>
                                </div>
                            </div>
                            <small>{stateErrors.logo}</small>
                        </div>
                        {
                            images?.map((el, i) => {
                                return (
                                    <div className='row mt-2'>
                                        <div className='col-md-6'>
                                            <div className='form-group mt-2'>
                                                <label>Quote {i + 1}*
                                                </label>
                                                <textarea className='form-control mt-2' name='quote' rows={3} value={el.quote} onChange={(e) => onChangeQuotes(e, i)} />

                                                <small>{imagesError[i].quote}</small>                                        </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='image-upload mt-3'>
                                                <label>Login Image {i + 1}*</label>
                                                <div className='d-flex align-items-center '>

                                                    <div className='d-flex flex-column mt-3 position-relative'>
                                                        {/* <span><img src='/images/gallery-icon.svg' width='24px' alt='' /> &nbsp;Select From Gallery</span> */}
                                                        <span> <img src='/images/upload-icon.svg' alt='' /> &nbsp;Upload Image &nbsp; <small></small>
                                                            <input type='file' className='file-upload' name='image'
                                                                accept="image/png, image/jpeg" onChange={(e) => onChangeQuotes(e, i)} />
                                                        </span>
                                                    </div>
                                                    {
                                                        el.image ?
                                                            <div className='program-image'>
                                                                <img src={el.imageUrl} alt='' width='139px' height='78px' />
                                                            </div> :
                                                            <div className='program-image'>
                                                                <img src={el.imageUrl} alt='' />
                                                            </div>
                                                    }

                                                </div>
                                                <small>{imagesError[i].image}</small>
                                            </div>
                                        </div>


                                    </div>
                                )
                            })
                        }
                        <div className='d-flex flex-wrap'>
                            <div className='mt-3'>
                                <label>For Header</label>
                                <div className='mt-2'>
                                    <CompactPicker
                                        name='colors'
                                        color={stateFields?.colors?.sidebarBgColor}
                                        onChangeComplete={(e) => handleChangeColor(e.hex, 'header')}
                                        colors={['#4D4D4D', '#999999', '#F44E3B', '#FE9200', '#FCDC00', '#DBDF00', '#A4DD00', '#68CCCA', '#73D8FF', '#AEA1FF', '#FDA1FF', '#333333', '#808080', '#cccccc', '#D33115',
                                            '#E27300', '#FCC400', '#B0BC00', '#68BC00', '#16A5A5', '#009CE0', '#7B64FF', '#FA28FF', '#000000', '#666666',
                                            '#B3B3B3', '#9F0500', '#C45100', '#FB9E00', '#808900', '#194D33', '#0C797D', '#0062B1', '#653294', '#AB149E']}
                                    />
                                </div>
                            </div>&nbsp;&nbsp;&nbsp;
                            <div className='mt-3'>
                                <label>For Button</label>
                                <div className='mt-2'>
                                    <CompactPicker
                                        name='colors'
                                        color={stateFields?.colors?.btnColor}
                                        onChangeComplete={(e) => handleChangeColor(e.hex, 'btn')}
                                        colors={['#4D4D4D', '#999999', '#F44E3B', '#FE9200', '#FCDC00', '#DBDF00', '#A4DD00', '#68CCCA', '#73D8FF', '#AEA1FF', '#FDA1FF',
                                            '#333333', '#808080', '#cccccc', '#D33115', '#E27300', '#FCC400', '#B0BC00', '#68BC00', '#16A5A5', '#009CE0', '#7B64FF',
                                            '#FA28FF', '#000000', '#666666', '#B3B3B3', '#9F0500', '#C45100', '#FB9E00', '#808900', '#194D33', '#0C797D', '#0062B1',
                                            '#653294', '#AB149E']}
                                    />
                                </div>
                            </div>
                        </div>
                        {
                            localStorage.getItem('role').includes('super-admin') && edit ?
                                <div className='row' style={{ width: '89.5%', marginBottom: 50 }}>
                                    <div className='col-md-4'>
                                        <div className='form-group mt-2'>
                                            <label>State Id*
                                            </label>
                                            <input className='form-control mt-2' value={id} disabled />
                                        </div>
                                    </div>
                                </div> : null
                        }
                        <hr />
                        <div className='save-btn mt-2'>
                            {
                                edit ?
                                    <button onClick={(e) => saveState(e, edit)} >Save</button>
                                    :
                                    <button onClick={(e) => saveState(e, edit)}>
                                        Save</button>

                            }
                            <button onClick={props.backToMain}>Cancel</button>
                        </div>
                    </div>
            }
        </div>
    )
}

export default AddSchool